import React, { useEffect, useState } from "react";

import { Button, Card, Checkbox, Popconfirm, Space, Tooltip, Typography } from 'antd';

import {
  PlusOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'

import SelectOffer from './SelectOffer';

import {
  Container,
  KanbanColumn,
  KanbanColumnContent,
  KanbanColumnHeader,
  KanbanContainer,
} from './styles';
import { deepCopyFunction, formatPrice, hexToRGB } from "../../../../../utils/functions";

const CombosNewOffers: React.FC<{
  products: any[],
  offers: { [x: string]: any[] },
  onNext: (values: any) => void;
  onPrevius: (values: any) => void;
  onRemoved: (prdt: any) => void;
  onInserted: (offers: any[]) => void;
  onChanged: (prdts: { [x: string]: any[] }) => void;
  onProductsChanged: (prdts: any[]) => void;
}> = ({
  products: productsInitial,
  offers: offersInitial,
  onNext,
  onPrevius,
  onInserted,
  onRemoved,
  onChanged,
  onProductsChanged,
}) => {
    const [products, setProducts] = useState<any[]>(productsInitial || []);
    const [offers, setOffers] = useState<{ [x: string]: any[] }>(offersInitial || {});
    const [currentProductSelecting, setCurrentProductSelecting] = useState<any>(null);
    const [selecting, setSelecting] = useState(false);

    useEffect(() => {
      let didCancel = false;
      !didCancel && setProducts(productsInitial || []);

      return () => {
        didCancel = true;
      };
    }, [productsInitial]);

    useEffect(() => {
      let didCancel = false;
      !didCancel && setOffers(offersInitial || {});

      return () => {
        didCancel = true;
      };
    }, [offersInitial]);

    const handleInsert = (newOffers: any[]) => {
      setSelecting(false);
      onInserted(newOffers);

      const clonedOffers = deepCopyFunction(offers);

      if (!(currentProductSelecting.id in clonedOffers)) {
        clonedOffers[currentProductSelecting.id] = [];
      }

      clonedOffers[currentProductSelecting.id] = [
        ...clonedOffers[currentProductSelecting.id],
        ...deepCopyFunction((Array.isArray(newOffers) ? newOffers : [newOffers])),
      ];

      setOffers({
        ...clonedOffers,
      });
      onChanged(clonedOffers);

      setSelecting(false);
      setCurrentProductSelecting(null);
    };

    const switchUserFill = (checked: boolean, prdt: any) => {
      prdt.allowFranchiseSelect = checked;
      if (checked) {
        const clonedOffers = deepCopyFunction(offers);
        delete clonedOffers[prdt.id];
        setOffers({
          ...clonedOffers,
        });
        onChanged(clonedOffers);
      } else {
        const clonedOffers = deepCopyFunction(offers);
        clonedOffers[prdt.id] = [];
        setOffers({
          ...clonedOffers,
        });
        onChanged(clonedOffers);
      }
      onProductsChanged(products);
      setProducts(() => products);
    };

    const handleDelete = (prdtId: number, index: number) => {
      if (index in (offers[prdtId] || [])) {
        const ofrs = deepCopyFunction(offers);
        const removed = ofrs[prdtId].splice(index, 1);
        onRemoved(removed);
        onChanged(ofrs);
      }
    };

    return (
      <Container>
        <SelectOffer
          isVisible={selecting}
          offers={offers[currentProductSelecting?.id] || []}
          currentProduct={currentProductSelecting}
          onCancel={() => {
            setSelecting(false);
            setCurrentProductSelecting(null);
          }}
          onSelect={(offers) => handleInsert(offers)}
        />

        <Space direction="vertical" style={{ width: `100%` }}>
          <KanbanContainer>
            <Space align="start">
              {products.map((prdt) => {

                return (
                  <KanbanColumn key={prdt.id} baseColor={hexToRGB(prdt.base_color || `#FFF`, .05)}>
                    <KanbanColumnHeader>
                      <div style={{ marginBottom: 15 }}>
                        <Checkbox defaultChecked={prdt.allowFranchiseSelect} onChange={(e) => switchUserFill(e.target.checked, prdt)}>
                          Preenchimento por franqueado

                          <Tooltip title="Permite que o franqueado escolha quais serão as ofertas para este produto!">
                            <Typography.Text style={{ marginLeft: 5 }} type={'secondary'}>
                              <InfoCircleOutlined />
                            </Typography.Text>
                          </Tooltip>
                        </Checkbox>
                      </div>
                      <Typography.Title level={5} style={{ marginBottom: 0 }}>
                        #{prdt.id} - {prdt.name}
                      </Typography.Title>
                      <Typography.Text type={'secondary'}>
                        {!prdt.rule.min ? 'Opcional' : 'Obrigatório'}
                      </Typography.Text> <br />
                      <Typography.Text type={'secondary'}>
                        Ofertas mínimas: {prdt.rule.min || 0}
                      </Typography.Text> <br />
                      <Typography.Text type={'secondary'}>
                        Ofertas máximas: {prdt.rule.max || 0}
                      </Typography.Text> <br />
                    </KanbanColumnHeader>
                    <KanbanColumnContent>
                      <Space direction="vertical" style={{ width: `100%` }}>

                        {(offers[prdt.id] || []).map((offer: any, idx: number) => {
                          return (
                            <Card key={offer.id}>
                              <div style={{ display: `flex`, justifyContent: `space-between`, alignItems: `center` }}>
                                <div style={{ width: `100%` }}>
                                  <Typography.Text>
                                    #{offer.id} - {offer.title}
                                  </Typography.Text> <br />
                                  {offer.price && <Typography.Text type="secondary">
                                    Preço: {formatPrice(offer.price)}
                                  </Typography.Text>}
                                </div>
                                <div style={{ alignSelf: `flex-start` }}>
                                  <Popconfirm
                                    title="Tem certeza que deseja remover esta oferta?!"
                                    onConfirm={() => handleDelete(prdt.id, idx)}
                                    okText="Sim"
                                    cancelText="Não"
                                  >
                                    <Button shape="circle" icon={<DeleteOutlined />} />
                                  </Popconfirm>
                                </div>
                              </div>
                            </Card>
                          );
                        })}
                        {prdt.allowFranchiseSelect ? (
                          <Card>
                            As ofertas para este produto serão selecionadas pelo o franqueado!
                          </Card>
                        ) : null}
                        {((offers[prdt.id] || []).length < prdt.rule.max) && !prdt.allowFranchiseSelect ? (
                          <Card onClick={() => {
                            setCurrentProductSelecting(prdt);
                            setTimeout(setSelecting, 500, true);
                          }}>
                            <Typography.Link>
                              <PlusOutlined /> Adicionar oferta
                            </Typography.Link>
                          </Card>
                        ) : null}
                      </Space>
                    </KanbanColumnContent>
                  </KanbanColumn>
                );
              })}
            </Space>
          </KanbanContainer>

          <div style={{ display: `flex`, justifyContent: `flex-end` }}>
            <Space>
              <Button onClick={() => onPrevius(products)}>
                Voltar
              </Button>
              <Button type="primary" onClick={() => onNext(products)}>
                Próximo
              </Button>
            </Space>
          </div>
        </Space>
      </Container>
    );
  };

export default CombosNewOffers;
