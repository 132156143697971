import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Table,
  TableColumnType,
  Tag,
  Typography
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../../../../api";
import ZoneNew from "./ZoneNew";
import ZoneDetails from "./ZoneDetails";
import { Container } from "./styles";
import { getStatusAprovedColor, getStatusAprovedText } from "../../../../../utils/functions";
import { FranchisorLeadStatus } from "../../../../../utils/enums";

const { Text } = Typography;


const defaultPageSize = 10;

const Zone: React.FC<{ franchisorLeadId?: string; franchisorLeadStatus?: FranchisorLeadStatus; }> = ({
  franchisorLeadId: franchisorLeadIdStatic,
  franchisorLeadStatus
}) => {
  const { franchisorLeadId: franchisorLeadIdDynamic } =
    useParams<{ franchisorLeadId: string }>();

  const [franchisorLeadId] = useState(
    franchisorLeadIdStatic || franchisorLeadIdDynamic
  );

  const [shouldReloadTable, setShouldReloadTable] = useState(false);
  const [inserting, setInserting] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: defaultPageSize,
    showSizeChanger: true,
  });

  const [detailsOpened, setDetailsOpened] = useState<any>(null);

  const loadData = useCallback(
    async (params: any) => {
      setTableLoading(true);

      const { current, pageSize, sortField, sortOrder, filters } = params;

      try {
        const { data } = await api.get(`/franchisor-lead/${franchisorLeadId}/zone`, {
          params: {
            page: current,
            pageSize: pageSize,
            offset: (current - 1) * pageSize,
            ...(filters ? { filters } : {}),
            ...(sortField ? { order_by: sortField } : {}),
            ...(sortOrder ? { sort_by: sortOrder } : {}),
          },
        });

        return data;
      } catch (error) {
        throw new Error("Erro ao carregar dados! " + error);
      } finally {
        setTableLoading(false);
      }
    },
    [franchisorLeadId]
  );

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        await api.delete(`/franchisor-lead/${franchisorLeadId}/zone/${id}/`);
        notification.success({
          message: "Zona deletada com sucesso",
        });

        setShouldReloadTable((oldState) => !oldState);
      } catch (error) {
        notification.error({
          message:
            "Ocorreu algum erro ao deletar a zona de interesse. Tente novamente., " + error,
        });
      }
    },
    [franchisorLeadId]
  );

  const handleAccept = useCallback(async (id: any) => {
    try {
      await api.patch(`/franchisor-lead/${franchisorLeadId}/zone/${id}/`, {
        approved: true,
      });
      notification.success({
        message: "Status da zona de interesse atualizada com sucesso",
      });

      setShouldReloadTable((oldState) => !oldState);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar o status da zona de interesse. Tente novamente., " +
          error,
      });
    }
  }, [franchisorLeadId]);

  const handleReject = useCallback(async (id: any) => {
    try {
      await api.patch(`/franchisor-lead/${franchisorLeadId}/zone/${id}/`, {
        approved: false,
      });
      notification.success({
        message: "Status da zona de interesse atualizada com sucesso",
      });

      setShouldReloadTable((oldState) => !oldState);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar o status da zona de interesse. Tente novamente., " +
          error,
      });
    }
  }, [franchisorLeadId]);

  const onHandleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (!pagination) return;

    loadData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...pagination,
      ...filters,
    })
      .then((response) => {
        setTablePagination((old) => ({
          ...old,
          ...pagination,
          total: response.total,
        }));
        setData(response.data);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));
  };

  const onHandleOpenMoreDetails = async (row: Partial<any>) => {
    setDetailsOpened(row);
  };

  const onHandleReloadData = () => setShouldReloadTable(!shouldReloadTable);

  const tableCols: TableColumnType<any>[] = [
    {
      key: "id",
      title: "#",
      dataIndex: "id",
      width: "70px",
      sorter: true,
    },
    {
      key: "city",
      title: "Cidade",
      dataIndex: "city",
      sorter: false,
      render: (_, row) => row.city.name ? `${row.city.name} / ${row.city.uf}` : "-",
    },
    {
      key: "district",
      title: "Bairro",
      dataIndex: "district",
      sorter: true,
    },
    {
      key: "approved",
      title: "Status",
      dataIndex: "approved",
      sorter: true,
      width: "150px",
      align: "center",
      render: (value) => (
        <Tag color={getStatusAprovedColor(value)}>
          {getStatusAprovedText(value)}
        </Tag>
      ),
    },
    {
      key: "view",
      title: "Visualizar",
      width: "100px",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            key="bt-view"
            size="small"
            onClick={() => onHandleOpenMoreDetails(record)}
          >
            Detalhes
          </Button>
        );
      },
    },
  ];

  const extraButtons = [
    <Button
      key="bt-ds-reload"
      icon={<ReloadOutlined />}
      onClick={onHandleReloadData}
    >
      Recarregar dados
    </Button>,
  ];


  if (franchisorLeadStatus === FranchisorLeadStatus.NOT_ANALYZED) {
    tableCols.push({
      key: "actions",
      title: "Ações",
      width: "100px",
      align: "center",
      render: (_, record) => {
        return (
          <Space>
            {!record.approved && typeof record.approved !== 'boolean' ? <>
              <Popconfirm
                title={[
                  <span key="text-pop-1"><Text>Atualize o status da zona de interesse. A zona de interesse foi aprovada ou desaprovada?</Text></span>,
                  <br key="text-br-1"/>,
                  <br key="text-br-2"/>,
                  <span key="text-pop-2"><Text strong> *Atenção*:</Text> <Text type="secondary">após atualizar o status da zona, ela não poderá ser editada nem mesmo deletada!</Text></span>
                ]}
                okType="default"
                onConfirm={async () => await handleAccept(record.id)}
                onCancel={async () => await handleReject(record.id)}
                okText="Zona aprovada"
                cancelText="Zona desaprovada"
              >
                <Button
                  key="bt-update-status"
                  size="small"
                  color="primary"
                  type="primary"
                >
                  Atualizar status
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Tem certeza, que deseja deletar a zona de interesse?!"
                okType="default"
                onConfirm={async () => await handleDelete(record.id)}
                okText="Deletar"
                cancelText="Cancelar"
              >
                <Button
                  key="bt-prod-delete"
                  size="small"
                  color="danger"
                  danger
                  type="primary"
                >
                  Deletar
                </Button>
              </Popconfirm>
            </> : <Text italic type="secondary">Sem ações!</Text> }
          </Space>
        );
      },
    });

    extraButtons.push(<Button
      key="bt-ds-new"
      type="primary"
      icon={<PlusOutlined />}
      onClick={() => setInserting(true)}
    >
      Novo
    </Button>);
  }

  useEffect(() => {
    let didCancel = false;

    loadData({
      current: 1,
      pageSize: defaultPageSize,
    })
      .then((response) => {
        !didCancel && setData(response.data);
        setTablePagination((old) => ({ ...old, total: response.total }));
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [loadData, shouldReloadTable]);

  return (
    <Container>
      {inserting && franchisorLeadStatus === FranchisorLeadStatus.NOT_ANALYZED ? (
        <ZoneNew
          isVisible={inserting}
          onCancel={() => {
            setInserting(false);
          }}
          onCreate={() => {
            setInserting(false);
            onHandleReloadData();
          }}
        />
      ) : null}

      <PageHeader
        title={franchisorLeadIdStatic ? null : "Zonas de interesse"}
        subTitle=""
        extra={extraButtons}
      >
        <Row style={{ marginTop: 12 }}>
          <Col md={24}>
            <Table
              size="middle"
              rowKey={(record: any) => record.id}
              dataSource={data}
              columns={tableCols}
              loading={tableLoading}
              pagination={tablePagination}
              onChange={onHandleTableChange}
            />
          </Col>
        </Row>
      </PageHeader>

      {detailsOpened ?
        <ZoneDetails
          details={detailsOpened}
          franchisorLeadStatus={franchisorLeadStatus}

          onCancel={() => setDetailsOpened(null)}
          onDataLoading={(v) => setTableLoading(v)}
          onDelete={() => { setDetailsOpened(null); onHandleReloadData() }}
          onDeleteLoading={(v) => setTableLoading(v)}
          onEdit={() => { onHandleReloadData(); }}
          onEditLoading={(v) => setTableLoading(v)}
        /> : null
      }
    </Container>
  );
};

export default Zone;
