import { Card } from "antd";
import styled from "styled-components";

export const Container = styled.div``;

export const KanbanContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  padding-bottom: 10px;
`;

export const KanbanColumn = styled.div<{ baseColor?: string }>`
  width: 300px;
  min-width: 300px;
  min-height: 500px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: ${(p) => p.baseColor ?? `rgb(202 216 224 / 4%)`};
`;

export const KanbanColumnHeader = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 15px;
`;

export const KanbanColumnContent = styled.div`
  padding: 15px;

  .ant-card {
    border-radius: 8px;
    cursor: pointer;
    transition: background 300ms ease-in-out, box-shadow 300ms ease-in-out;

    .ant-card-body {
      padding: 15px;
    }

    &:hover {
      background: rgb(221 221 221 / 26%);
      box-shadow: 0px 0px 5px 0px rgb(158 158 158 / 7%);
    }
  }
`;

export const CardOffer = styled(Card)<{ editiable?: boolean }>`
  overflow-y: auto;
  min-height: ${(props) => (props.editiable ? `5rem` : " auto")};
  max-height: ${(props) => (props.editiable ? ` 20rem; ` : " auto")};

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e3e3e3;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  color: #d9d9d9;
  font-size: 12px;
  min-height: 30px;
  padding: 4px 5px;
  font-weight: 600;
  border-radius: 2px;
  align-items: center;
  word-wrap: break-word;
  border: 1px solid #d9d9d9;
  justify-content: space-between;
`;
