import React, { useEffect, useState } from 'react';
import { Button, Divider, Space, Tag, Typography } from 'antd';

import { formatPrice, hash } from '../../../../../../../../../utils/functions';
import {
  Container,
  ProductsAndOffersList,
  ProductsAndOffersListOItem,
  ProductsAndOffersListPItem,
} from './styles';
import { ProductTypesId } from '../../../../../../../../../utils/enums';

const Resume: React.FC<{
  formData: any;
  products: any[];
  loading?: boolean;
  onNext: () => void;
  onCancel: () => void;
  onPrevius: () => void;
  offers: { [x: string]: any[] };
  infoOffers: { id_offer: string; info: string[] }[] | null;
  speedPlus: { id_offer: string; plus_download: number }[] | null;
}> = ({
  onNext,
  loading,
  onCancel,
  onPrevius,
  speedPlus,
  infoOffers,
  offers: offersInitial,
  products: productsInitial,
  formData: formDataInitial,
}) => {
  const [formData, setFormData] = useState<any>(formDataInitial || {});
  const [products, setProducts] = useState<any[]>(productsInitial || []);
  const [offers, setOffers] = useState<{ [x: string]: any[] }>(
    offersInitial || {},
  );
  const [treeNodes, setTreeNodes] = useState<any[]>([]);
  const [totalSumPrice, setTotalSumPrice] = useState(0);

  const buildTreeNodes = () => {
    let price = 0;

    const treeNodes = products.map(p => {
      const children: any[] = [];

      const po = offers[p.id] || [];
      if (!po.length) {
        children.push({
          key: `${hash()}`,
          expanded: true,
          title: `Não há ofertas selecionadas para este produto!`,
        });
      } else {
        po.forEach(o => {
          price += +`${
            (typeof o.custom_offers_price === 'number' ||
            /^(\d+(\.\d+)?)$/.test(`${o.custom_offers_price}`)
              ? o.custom_offers_price
              : o.price) || 0
          }`;

          children.push({
            id: o.id,
            expanded: true,
            key: `${hash()}`,
            productID: o.product.id,
            price: formatPrice(o.price),
            title: `#${o.id} - ${o.title}`,
            ...(typeof o.custom_offers_price === 'number'
              ? { custom_offers_price: formatPrice(o.custom_offers_price) }
              : {}),
            ...{
              ...(+o.product.id === ProductTypesId.Internet && {
                original_speed: `${o.metadata.download} ${o.metadata.download_suffix}`,
              }),
            },
          });
        });
      }

      return {
        children,
        expanded: true,
        key: `${hash()}`,
        title: `#${p.id} - ${p.name}`,
      };
    });

    setTotalSumPrice(price);
    setTreeNodes(treeNodes);
  };

  useEffect(() => {
    let didCancel = false;
    !didCancel && setFormData(formDataInitial || {});

    return () => {
      didCancel = true;
    };
  }, [formDataInitial]);

  useEffect(() => {
    let didCancel = false;
    !didCancel && setProducts(productsInitial || []);
    !didCancel && setOffers(offersInitial || {});
    !didCancel && buildTreeNodes();

    return () => {
      didCancel = true;
    };
  }, [productsInitial, offersInitial]);

  return (
    <Container>
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <div>
            <Typography.Title level={3}>Dados gerais</Typography.Title>

            <Space direction="vertical">
              <div>
                <Space>
                  <Typography.Text>Nome:</Typography.Text>
                  <Typography.Text>{formData.name}</Typography.Text>
                </Space>
              </div>

              <div>
                <Space>
                  <Typography.Text>Publicado?:</Typography.Text>
                  <Tag color={formData.published ? 'green' : 'volcano'}>
                    {formData.published ? 'Sim' : 'Não'}
                  </Tag>
                </Space>
              </div>

              <div>
                <Space direction="vertical">
                  <Typography.Text>Informações adicionais:</Typography.Text>

                  <ul>
                    {!formData?.additional_info?.length ? (
                      <li>
                        <Typography.Text type="secondary">
                          Sem informações adicionais!
                        </Typography.Text>
                      </li>
                    ) : null}

                    {formData?.additional_info?.length
                      ? (formData?.additional_info || []).map(
                          (i: string, idx: number) => (
                            <li key={idx}>
                              <Typography.Text>{i}</Typography.Text>
                            </li>
                          ),
                        )
                      : null}
                  </ul>
                </Space>
              </div>
            </Space>
          </div>

          <Divider />

          <div>
            <Typography.Title level={3}>Produtos e ofertas</Typography.Title>

            <ProductsAndOffersList>
              {treeNodes &&
                treeNodes.map(p => {
                  return (
                    <ProductsAndOffersListPItem key={p.key}>
                      <div>{p.title}</div>

                      {((p.children || []) as any[]).map(o => {
                        return (
                          <ProductsAndOffersListOItem key={o.key}>
                            <div>
                              <Typography.Text>{o.title}</Typography.Text>

                              {+o.productID === ProductTypesId.Internet && (
                                <>
                                  <br />
                                  <Typography.Text delete>
                                    Velocidade original: {o.original_speed}
                                  </Typography.Text>
                                </>
                              )}

                              {speedPlus &&
                                speedPlus.length > 0 &&
                                +o.productID === ProductTypesId.Internet && (
                                  <>
                                    <br />
                                    <Typography.Text>
                                      Nova velocidade:{' '}
                                      {`${speedPlus[0].plus_download} MB`}
                                    </Typography.Text>
                                  </>
                                )}
                              <br />
                              {o.price ? (
                                <Typography.Text
                                  type="secondary"
                                  delete={!!o.custom_offers_price}
                                >
                                  Preço
                                  {!o.custom_offers_price ? ' original' : ''}:
                                  {o.price}
                                </Typography.Text>
                              ) : null}
                              {o.custom_offers_price ? (
                                <>
                                  <br />
                                  <Typography.Text type="secondary">
                                    Novo preço: {o.custom_offers_price}
                                  </Typography.Text>
                                </>
                              ) : null}
                              {infoOffers &&
                                infoOffers.length > 0 &&
                                +o.productID === ProductTypesId.Internet && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Divider />

                                    <div>Informações do combo</div>
                                    {infoOffers[0].info.map(
                                      (val: any, idx: any) => (
                                        <Typography.Text
                                          type="secondary"
                                          key={idx}
                                        >
                                          {val + '.'}
                                        </Typography.Text>
                                      ),
                                    )}
                                    <Divider />
                                  </div>
                                )}
                            </div>
                          </ProductsAndOffersListOItem>
                        );
                      })}
                    </ProductsAndOffersListPItem>
                  );
                })}
            </ProductsAndOffersList>

            <Divider />

            <div>
              <Typography.Title level={4}>
                Soma total dos valores das ofertas:
              </Typography.Title>
              <Typography.Text type="secondary" strong style={{ fontSize: 18 }}>
                {formatPrice(totalSumPrice || 0)}
              </Typography.Text>
            </div>
          </div>
        </div>

        <div style={{ display: `flex`, justifyContent: `flex-end` }}>
          <Space>
            <Button onClick={() => onCancel()}>Cancelar</Button>
            <Button disabled={loading} onClick={() => onPrevius()}>
              Voltar
            </Button>
            <Button loading={loading} type="primary" onClick={() => onNext()}>
              Salvar dados
            </Button>
          </Space>
        </div>
      </Space>
    </Container>
  );
};

export default Resume;
