import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";

import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import api from "../../../../../../api";
import { hash } from "../../../../../../utils/functions";

interface Config {
  datasource?: string;
  key: string;
  label?: string;
  prefixType?: string;
  prefixFieldKey?: string;
  prefix?: string;
  sufixType?: string;
  sufixFieldKey?: string;
  sufix?: string;
  options?: {
    label: string;
    value: string;
  }[];
  placeholder?: string;
  selectionType?: string;
  type?: string;
  validators?: {
    validator: string;
    value: any;
  }[];
  children?: Omit<Config, "children">[];
}

const FieldsOfferRegister: React.FC<{
  config: Config[];
  isDisabled?: boolean;
}> = ({ config, isDisabled }) => {
  const [_config, _setConfig] = useState(config);
  const [checkChange, setCheckChange] = useState(false);
  const [datasources, setDatasources] = useState<{ [key: string]: any[] }>({});
  const [datasourcesLoading, setDatasourcesLoading] = useState<{
    [key: string]: boolean;
  }>({});

  const loadDatasource = useCallback(async (path: string, key: string) => {
    setDatasourcesLoading({
      ...datasourcesLoading,
      [key]: true,
    });

    try {
      const { data } = await api.get(`${path}`, {
        params: {},
      });

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setDatasourcesLoading({
        ...datasourcesLoading,
        [key]: false,
      });
    }
  }, []);

  const transformValidatorsToObject = (
    validators: Config["validators"]
  ): any => {
    const unique = (validators || [])?.slice().reduce((item: any, val) => {
      if (val.validator === "required") {
        item[val.validator] = true;
      } else {
        item[val.validator] = val.value;
      }

      return item;
    }, {});
    return unique;
  };

  const transformValidators = (
    type: string,
    validators: Config["validators"]
  ): any => {
    const unique = transformValidatorsToObject(validators);

    if (type === "number" || type === "money") {
      delete unique.min;
      delete unique.max;
    }

    return Object.keys(unique).length ? unique : { required: false };
  };

  const text = (field: Config, basePath?: any[]) => (
    <Form.Item
      name={basePath ? [...basePath, field.key] : ["metadata", field.key]}
      label={field.label || ""}
      rules={[transformValidators(field.type as string, field.validators)]}
    >
      <Input placeholder={field.placeholder || ""} disabled={!!isDisabled} />
    </Form.Item>
  );

  const number = (field: Config, basePath?: any[]) => {
    const validators = transformValidatorsToObject(field.validators);
    const _prefixSme = field.prefixType === "simple";
    const _sufixSme = field.sufixType === "simple";
    let _falseFacadeToPrefix!: Config;
    let _falseFacadeToSufix!: Config;
    const _datasources: Record<string, { id: string; name: string }[]> = {};

    if (!_prefixSme) {
      _falseFacadeToPrefix = {
        type: "select",
        key: `${field.prefixFieldKey}`,
        datasource: "custom",
        placeholder: "Selecione um prefixo",
        options: `${field.prefix}`
          .split("|")
          .map((v) => ({ value: v, label: v })),
        validators: [
          {
            validator: "required",
            value: true,
          },
        ],
      };
      _datasources[`${field.prefixFieldKey}`] = (
        _falseFacadeToPrefix.options || []
      ).map((item) => ({ id: item.value, name: item.label }));
    }
    if (!_sufixSme) {
      _falseFacadeToSufix = {
        type: "select",
        key: `${field.sufixFieldKey}`,
        datasource: "custom",
        placeholder: "Selecione um sufixo",
        options: `${field.sufix}`
          .split("|")
          .map((v) => ({ value: v, label: v })),
        validators: [
          {
            validator: "required",
            value: true,
          },
        ],
      };
      _datasources[`${field.sufixFieldKey}`] = (
        _falseFacadeToSufix.options || []
      ).map((item) => ({ id: item.value, name: item.label }));
    }

    const formatter = (value?: string): any => {
      if (_prefixSme || _sufixSme) {
        const su = _sufixSme ? field.sufix : "";
        const pre = _prefixSme ? field.prefix : "";
        const _value =
          su || pre
            ? `${pre ? `${pre} ` : ""}${value}${su ? ` ${su}` : ""}`
            : value;
        return _value;
      }
      return value;
    };

    const parser = (value?: string): any => {
      const transformed = `${
        value === null || value === undefined ? "" : value
      }`;
      if (_prefixSme || _sufixSme) {
        const su = _sufixSme ? field.sufix : "";
        const pre = _prefixSme ? field.prefix : "";
        const _value =
          su || pre
            ? `${transformed}`.replace(`${pre} `, "").replace(` ${su}`, "")
            : transformed;
        return _value;
      }
      return transformed;
    };

    return (
      <Form.Item
        name={basePath ? [...basePath, field.key] : ["metadata", field.key]}
        label={field.label || ""}
        rules={[transformValidators(field.type as string, field.validators)]}
      >
        <InputNumber
          style={{ display: "block", width: "100%" }}
          placeholder={field.placeholder || ""}
          formatter={formatter}
          parser={parser}
          {...(_falseFacadeToPrefix
            ? {
                addonAfter: select(
                  _falseFacadeToPrefix,
                  _datasources[`${field.prefixFieldKey}`] || [],
                  true,
                  basePath
                ),
              }
            : {})}
          {...(_falseFacadeToSufix
            ? {
                addonAfter: select(
                  _falseFacadeToSufix,
                  _datasources[`${field.sufixFieldKey}`] || [],
                  true,
                  basePath
                ),
              }
            : {})}
          min={validators.min || undefined}
          max={validators.max || undefined}
          disabled={!!isDisabled}
        />
      </Form.Item>
    );
  };

  const money = (field: Config, basePath?: any[]) => {
    const validators = transformValidatorsToObject(field.validators);

    return (
      <Form.Item
        name={basePath ? [...basePath, field.key] : ["metadata", field.key]}
        label={field.label || ""}
        rules={[transformValidators(field.type as string, field.validators)]}
      >
        <InputNumber
          style={{ display: "block", width: "100%" }}
          formatter={(value) =>
            `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value?.replace(/R\$\s?|(,*)/g, "") as any}
          min={validators.min || undefined}
          max={validators.max || undefined}
          disabled={!!isDisabled}
        />
      </Form.Item>
    );
  };

  const checkbox = (field: Config, basePath?: any[]) => (
    <Form.Item
      name={basePath ? [...basePath, field.key] : ["metadata", field.key]}
      valuePropName="checked"
      wrapperCol={{ offset: 0, span: 24 }}
    >
      <Checkbox
        checked
        disabled={!!isDisabled}
        onChange={() => {
          setCheckChange(!checkChange);
        }}
      >
        {field.label}
      </Checkbox>
    </Form.Item>
  );

  const select = (
    field: Config,
    options?: { id: string; name: string }[],
    useNoStyle = false,
    basePath?: any[]
  ) => {
    return (
      <Form.Item
        noStyle={useNoStyle}
        name={basePath ? [...basePath, field.key] : ["metadata", field.key]}
        label={field.label || ""}
        rules={[transformValidators(field.type as string, field.validators)]}
      >
        <Select
          placeholder={field.placeholder || ""}
          {...(field.selectionType === "multiple" ? { mode: "multiple" } : {})}
          disabled={!!isDisabled}
        >
          {(options || (datasources || {})[field.key] || []).map((item) => (
            <Select.Option value={item.id} key={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  const list = (field: Config) => {
    const basePathAccess = ["metadata", field.key];

    return (
      <Col md={24}>
        <Form.List name={basePathAccess}>
          {(fields, { add, remove }) => {
            return (
              <Card bodyStyle={{ paddingTop: 18 }}>
                <Space
                  key={hash()}
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  <Typography.Title level={4}>{field.label}</Typography.Title>

                  {fields.map((_field, _index) => {
                    const itemPathAccess = [field.key, _field.key];
                    itemPathAccess.shift();

                    return (
                      <Card key={hash()}>
                        <Space>
                          <Typography.Title level={4}>
                            Item #{_index + 1}
                          </Typography.Title>

                          {!isDisabled ? (
                            <Tooltip title="Remover item">
                              <Button
                                type="dashed"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                onClick={() => remove(_field.key)}
                                style={{ marginTop: -10 }}
                              />
                            </Tooltip>
                          ) : null}
                        </Space>

                        <Row gutter={15}>
                          {renderFields(field.children, itemPathAccess)}
                        </Row>
                      </Card>
                    );
                  })}

                  {!isDisabled ? (
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Adicionar
                      </Button>
                    </Form.Item>
                  ) : null}
                </Space>
              </Card>
            );
          }}
        </Form.List>
      </Col>
    );
  };

  const DEFAULT = (_: Config) => null;

  const renderFields = (fields = config, basePath?: any[]) => {
    return (fields || []).map((metadata: any) => (
      <Col md={metadata.type === "list" ? 24 : 12} key={hash()}>
        <Form.Item noStyle={true} shouldUpdate={() => false}>
          {(form) => {
            const formItem = form.getFieldsValue(metadata.disable_when);
            if (
              metadata.disable_when &&
              formItem.metadata &&
              !formItem.metadata.with_watchTV
            ) {
              return DEFAULT(metadata);
            }
            if (metadata.type === "text") {
              return text(metadata, basePath);
            } else if (metadata.type === "number") {
              return number(metadata, basePath);
            } else if (metadata.type === "money") {
              return money(metadata, basePath);
            } else if (metadata.type === "checkbox") {
              return checkbox(metadata, basePath);
            } else if (metadata.type === "select") {
              return select(metadata, void 0, false, basePath);
            } else if (metadata.type === "list") {
              return list(metadata);
            } else {
              return DEFAULT(metadata);
            }
          }}
        </Form.Item>
      </Col>
    ));
  };

  useEffect(() => {
    let didCancel = false;

    function recursion<T = any>(
      items: T[],
      callback: (item: T) => Promise<any> | void
    ): Promise<void> {
      return new Promise((resolve) => {
        let index = 0;
        function rec(i: number) {
          if (i <= items.length - 1) {
            const result = callback(items[i]);

            if (result && typeof result.then === "function") {
              result.finally(() => {
                rec(i + 1);
              });
            } else {
              rec(i + 1);
            }
          } else {
            resolve();
          }
        }
        rec(index);
      });
    }

    const _configDelta = [...config];
    config.forEach((item) => {
      if (item.type === "list") {
        _configDelta.push(...(item.children || []));
      }
    });

    if (!didCancel) {
      if (config) {
        const datasources_storage: any = {};
        recursion(_configDelta, (field) => {
          if (field.type === "select") {
            if (field.datasource === "custom") {
              datasources_storage[field.key] = (field.options || []).map(
                (item) => ({ id: item.value, name: item.label })
              );
            } else {
              return new Promise<void>((resolve) => {
                loadDatasource(field.datasource as string, field.key)
                  .then((data) => {
                    datasources_storage[field.key] =
                      (Array.isArray(data) ? data : data.data) || [];
                  })
                  .finally(() => resolve());
              });
            }
          }
        }).then(() => {
          setDatasources(datasources_storage);
        });

        _setConfig(config);
      } else {
        _setConfig([]);
      }
    }

    return () => {
      didCancel = true;
    };
  }, [config]);

  return <Row gutter={15}>{renderFields(_config)}</Row>;
};

export default FieldsOfferRegister;
