import styled from "styled-components";

export const Container = styled.div`
  background-color: white;

  .ant-form-item-labe--upload {
    margin-bottom: 10px;
  }
`;

export const BoxFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 90px;
`;

export const ImageAvatarDetails = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
`;

export const ButtonsFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;

  button:not(:last-child) {
    margin-right: 10px;
  }
`;

export const TagColor = styled.div<{color?: string;}>`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;

  background: ${p => p.color ?? '#000'};
`;
