import { Col, Form, Input, Modal, notification, Row, Select } from "antd";
import React, { useState } from "react";
import ReactInputMask from "react-input-mask";
import { useParams } from "react-router-dom";
import api from "../../../../../../../api";
import { useAuth } from "../../../../../../../providers/Auth";
import {
  phoneNumber,
  phoneNumber0800,
  phoneResidential,
} from "../../../../../../../utils/validators";

interface ContactNewProps {
  isVisible?: boolean;
  zoneId?: string;
  franchiseId?: string;
  onCreate?: () => void;
  onCancel?: () => void;
}

const ContactNew: React.FC<ContactNewProps> = ({
  isVisible,
  onCreate,
  onCancel,
  zoneId: staticZoneId,
  franchiseId: staticFranchiseId,
}) => {
  const { franchisee_id } = useAuth();

  const { zoneId: paramRouteZoneId, franchiseId: paramRouteFranchiseId } =
    useParams<{ zoneId: string; franchiseId: string }>();

  const [zoneId] = useState(staticZoneId || paramRouteZoneId);
  const [franchiseId] = useState(
    staticFranchiseId || paramRouteFranchiseId || franchisee_id
  );

  const [phoneType, setPhoneType] = useState("0800");
  const [phoneOptions] = useState([
    {
      value: `0800`,
      label: `0800`,
    },
    {
      value: `cell`,
      label: `Celular`,
    },
    {
      value: `residential`,
      label: `Fixo`,
    },
  ]);
  const [phoneTypeOptions] = useState<any>({
    mask: {
      cell: "(99) \\9 9999-9999",
      residential: "(99) 9999-9999",
      "0800": "\\08\\0\\0 999-9999",
    },
    validator: {
      cell: phoneNumber,
      residential: phoneResidential,
      "0800": phoneNumber0800,
    },
    placeholder: {
      cell: "(00) 0 0000-0000",
      residential: "(00) 0000-0000",
      "0800": "0800 000-0000",
    },
  });

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await api.post(`/franchisees/${franchiseId}/zone/${zoneId}/contact`, {
        ...(values.email && { email: values.email }),
        ...(values.phone_number && { phone_number: values.phone_number }),
        ...(values.twitter_link && { twitter_link: values.twitter_link }),
        ...(values.facebook_link && { facebook_link: values.facebook_link }),
        ...(values.linkedin_link && { linkedin_link: values.linkedin_link }),
        ...(values.instagram_link && { instagram_link: values.instagram_link }),
        ...(values.whatsapp_number && {
          whatsapp_number: values.whatsapp_number,
        }),
      });

      notification.success({
        message: "Contato inserido com sucesso",
      });

      onReset();
      onCreate && onCreate();

      setLoading(false);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao inserir o contato. Tente novamente., " + error,
      });
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={isVisible}
      title="Adicionar contato"
      closable={false}
      maskClosable={false}
      okText="Adicionar"
      okButtonProps={{
        htmlType: "submit",
        disabled: loading,
        loading: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch((info) => {
            console.log("Validate Failed: ", info);
          });
      }}
      onCancel={() => {
        onReset();
        onCancel && onCancel();
      }}
    >
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Form.Item label="Número">
          <Row gutter={5}>
            <Col md={4}>
              <Form.Item noStyle>
                <Select
                  defaultValue={phoneType}
                  onChange={(v) => {
                    if (v !== phoneType) {
                      setPhoneType(v);
                      form.setFieldsValue({
                        ...form.getFieldsValue(),
                        phone_number: "",
                      });
                    }
                  }}
                >
                  {phoneOptions.map((item, idx) => (
                    <Select.Option key={idx} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={20}>
              <Form.Item
                name="phone_number"
                initialValue={""}
                rules={[
                  { message: `Por favor, insira Número` },
                  { validator: phoneTypeOptions.validator[phoneType] },
                ]}
                noStyle
              >
                <ReactInputMask mask={phoneTypeOptions.mask[phoneType]}>
                  {(props: Partial<any>) => (
                    <Input
                      {...(props || {})}
                      placeholder={phoneTypeOptions.placeholder[phoneType]}
                    />
                  )}
                </ReactInputMask>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          name="email"
          label="E-mail"
          rules={[{ max: 512, min: 2, type: "email" }]}
        >
          <Input placeholder="Digite o email" type="email" />
        </Form.Item>

        <Form.Item
          name="whatsapp_number"
          label="WhatsApp"
          initialValue={""}
          rules={[{ validator: phoneNumber }]}
        >
          <ReactInputMask mask="(99) \9 9999-9999">
            {(props: Partial<any>) => (
              <Input {...props} placeholder="(00) 9 0000-0000" />
            )}
          </ReactInputMask>
        </Form.Item>
        <Form.Item
          name="instagram_link"
          label="Instagram"
          rules={[{ max: 512, min: 2 }]}
        >
          <Input placeholder="Digite o link do Instagram" />
        </Form.Item>
        <Form.Item
          name="facebook_link"
          label="Facebook"
          rules={[{ max: 512, min: 2 }]}
        >
          <Input placeholder="Digite o link do Facebook" />
        </Form.Item>
        <Form.Item
          name="linkedin_link"
          label="Linkedin"
          rules={[{ max: 512, min: 2 }]}
        >
          <Input placeholder="Digite o link do Linkedin" />
        </Form.Item>
        <Form.Item
          name="twitter_link"
          label="Twitter"
          rules={[{ max: 512, min: 2 }]}
        >
          <Input placeholder="Digite o link do Twitter" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ContactNew;
