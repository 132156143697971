const FIELDS = [
  {
    label: "Texto",
    value: "text",
    validators: [
      {
        label: "Obrigatório",
        value: "required",
      },
      {
        label: "Mínimo",
        value: "min",
      },
      {
        label: "Máximo",
        value: "max",
      },
    ],
  },
  {
    label: "Número",
    value: "number",
    validators: [
      {
        label: "Obrigatório",
        value: "required",
      },
      {
        label: "Mínimo",
        value: "min",
      },
      {
        label: "Máximo",
        value: "max",
      },
    ],
    configs: [
      {
        key: "prefixType",
        type: "select",
        label: "Tipo de prefixo",
        placeholder: "Selecione o tipo",
        rules: [{ required: true, }],
        options: [
          {
            label: 'Prefixo simples',
            value: "simple",
          },
          {
            label: "Prefixo de escolha separada",
            value: "separated"
          }
        ],
        size: 6,
      },
      {
        key: "prefix",
        type: "text",
        label: "Prefixo",
        placeholder: "Ex.: +-",
        rules: [{ required: false, }],
        options: [],
        size: 6,
      },
      {
        key: "sufixType",
        type: "select",
        label: "Tipo de sufixo",
        placeholder: "Selecione o tipo",
        rules: [{ required: true, }],
        options: [
          {
            label: 'Sufixo simples',
            value: "simple",
          },
          {
            label: "Sufixo de escolha separada",
            value: "separated"
          }
        ],
        size: 6,
      },
      {
        key: "sufix",
        type: "text",
        label: "Sufixo",
        placeholder: "Ex.: %",
        rules: [{ required: false, }],
        options: [],
        size: 6,
      },

      {
        key: "prefixFieldKey",
        type: "text",
        label: "Chave de valor do campo de prefixo",
        placeholder: "Ex.: valor_do_campo",
        rules: [{ required: true, }],
        options: [],
        size: 12,
        show: "values.prefixType === 'separated'"
      },
      {
        key: "sufixFieldKey",
        type: "text",
        label: "Chave de valor do campo de sufixo",
        placeholder: "Ex.: valor_do_campo",
        rules: [{ required: true, }],
        options: [],
        size: 12,
        show: "values.sufixType === 'separated'"
      },
    ],
  },
  {
    label: "Dinheiro",
    value: "money",
    validators: [
      {
        label: "Obrigatório",
        value: "required",
      },
      {
        label: "Mínimo",
        value: "min",
      },
      {
        label: "Máximo",
        value: "max",
      },
    ],
  },
  {
    label: "Caixa de seleção",
    value: "checkbox",
    checkbox: [],
  },
  {
    label: "Combobox",
    value: "select",
    validators: [
      {
        label: "Obrigatório",
        value: "required",
      },
    ],
    configs: [
      {
        key: "selectionType",
        type: "select",
        label: "Tipo de seleção",
        placeholder: "Selecione o tipo",
        rules: [{ required: true, }],
        options: [
          {
            label: 'Escolhas simples',
            value: "simple",
          },
          {
            label: "Escolhas multiplas",
            value: "multiple"
          }
        ],
        size: 12,
      },
      {
        key: "datasource",
        type: "select",
        label: "Carregar de",
        placeholder: "Selecione a base de dados",
        rules: [{ required: true, }],
        options: [
          {
            label: "Serviços digitais",
            value: "/digital-service"
          },
          {
            label: "Produtos",
            value: "/product"
          },
          {
            label: "Cidades",
            value: "/cities"
          },
          {
            label: "Costumizadas",
            value: "custom"
          }
        ],
        size: 12,
      },
      {
        key: "options",
        type: "list",
        label: "Opções",
        placeholder: "",
        rules: [],
        options: [
          {
            key: "value",
            type: "text",
            label: "Valor",
            placeholder: "Ex.: valor_do_campo",
            rules: [{ required: true, }],
            options: [],
            size: 12,
          },
          {
            key: "label",
            type: "text",
            label: "Nome",
            placeholder: "Ex.: Valor do campo",
            rules: [{ required: true, }],
            options: [],
            size: 12,
          },
        ],
        size: 24,
        show: "values.datasource === 'custom'"
      },
    ]
  },
  {
    label: "Lista",
    value: "list",
    configs: [
    ]
  },
];

export default FIELDS;
