import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tabs,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useHistory, useParams } from "react-router-dom";

import api from "../../../../api";
import { useAuth } from "../../../../providers/Auth";
import { UserType } from "../../../../utils/enums";
import {
  documentCPF,
  phoneNumber,
  validateAndInsertCNPJ,
} from "../../../../utils/validators";
import ClerkPage from "./../Clerk";
import LeadsPage from "./../Leads";
import ReportLeads from "./../ReportLeads";
import ReportReturnSchedulePage from "./../ReportReturnSchedule";
import ReturnSchedulePage from "./../ReturnSchedule";
import ZonePage from "./../Zone";
import { Container, ContainerLoading } from "./styles";

const FranchisesAbout: React.FC<{ franchiseId?: string }> = ({
  franchiseId: franchiseIdStatic,
}) => {
  const { franchisee_id: franchiseIdLogin, userTypeIs } = useAuth();
  const { franchiseId: franchiseIdDynamic } = useParams<{
    franchiseId: string;
  }>();

  const [franchiseId] = useState(
    franchiseIdStatic || franchiseIdDynamic || franchiseIdLogin
  );
  const history = useHistory();

  const [userTypeIsFranchisee] = useState(userTypeIs(UserType.Franchisee));

  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLoading, setIsEditingLoading] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadInfo = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/franchisees/${franchiseId}`);

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setLoading(false);
    }
  }, [franchiseId]);

  const handleDelete = useCallback(async () => {
    setIsDeletingLoading(true);
    try {
      await api.delete(`/franchisees/${franchiseId}/`);
      notification.success({
        message: "Franquia deletada com sucesso",
      });

      setData(null);

      history.goBack();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao deletar a franquia. Tente novamente., " +
          error,
      });
    } finally {
      setIsDeletingLoading(false);
    }
  }, [franchiseId, history]);

  const onFinish = async (values: any) => {
    setIsEditingLoading(true);

    try {
      await api.patch(`/franchisees/${franchiseId}`, {
        ...values,
        ...(values.cnpj && { cnpj: values.cnpj }),
      });

      notification.success({
        message: "Franquia atualizada com sucesso",
      });

      const dataMerged = {
        ...data,
        ...values,
      };

      setIsEditing(false);

      setTimeout(setData, 10, dataMerged);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar a franquia. Tente novamente., " +
          error,
      });
    } finally {
      setIsEditingLoading(false);
    }
  };

  useEffect(() => {
    let didCancel = false;

    loadInfo()
      .then((response) => {
        !didCancel && setData(response);
        !didCancel && form.setFieldsValue(response);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [form, loadInfo]); // No 'details' dep. is needed

  const loadingTemplate = (
    <ContainerLoading>
      <Spin></Spin>
    </ContainerLoading>
  );

  const tabsTemplate = (
    <>
      <Divider />

      <Tabs defaultActiveKey="zones">
        <Tabs.TabPane tab="Zonas de atuação" key="zones">
          {data && franchiseId ? <ZonePage franchiseId={franchiseId} /> : null}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Atendentes" key="clerks">
          {data && franchiseId ? <ClerkPage franchiseId={franchiseId} /> : null}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Leads" key="leads">
          {data && franchiseId ? <LeadsPage franchiseId={franchiseId} /> : null}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Relatório de leads" key="report-leads">
          {data && franchiseId ? (
            <ReportLeads franchiseId={franchiseId} />
          ) : null}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Agendamento de Retorno" key="return-schedule">
          {data && franchiseId ? (
            <ReturnSchedulePage franchiseId={franchiseId} />
          ) : null}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Relatório dos agendamentos de retorno"
          key="report-return-schedule"
        >
          {data && franchiseId ? (
            <ReportReturnSchedulePage franchiseId={franchiseId} />
          ) : null}
        </Tabs.TabPane>
      </Tabs>
    </>
  );

  const loadedTemplate = (
    <PageHeader
      title="Dados da franquia"
      onBack={() => history.goBack()}
      backIcon={!userTypeIsFranchisee}
    >
      <Tabs defaultActiveKey="about">
        <Tabs.TabPane tab="Dados gerais" key="about">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col md={8}>
                <Form.Item
                  name="name"
                  label="Nome"
                  rules={[{ required: true, max: 512, min: 2 }]}
                >
                  <Input
                    placeholder="Digite o nome da franquia"
                    disabled={!isEditing}
                  />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="email"
                  label="E-mail"
                  rules={[{ required: true, type: "email" }]}
                >
                  <Input
                    type={"email"}
                    placeholder="Digite o e-mail da franquia"
                    disabled={!isEditing}
                  />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="cnpj"
                  label="CNPJ"
                  rules={[{ validator: validateAndInsertCNPJ }]}
                >
                  <InputMask mask="99.999.999/9999-99" disabled={!isEditing}>
                    {(props: Partial<any>) => (
                      <Input
                        {...props}
                        disabled={!isEditing}
                        placeholder="99.999.999/9999-99"
                      />
                    )}
                  </InputMask>
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="document"
                  label="Documento"
                  rules={[{ required: true }, { validator: documentCPF }]}
                >
                  <InputMask mask="999.999.999-99" disabled={!isEditing}>
                    {(props: Partial<any>) => (
                      <Input
                        {...props}
                        placeholder="000.000.000-00"
                        disabled={!isEditing}
                      />
                    )}
                  </InputMask>
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="phone_number"
                  label="Número"
                  rules={[{ required: true }, { validator: phoneNumber }]}
                >
                  <InputMask mask="(99) 9 9999-9999" disabled={!isEditing}>
                    {(props: Partial<any>) => (
                      <Input
                        {...props}
                        placeholder="(00) 9 0000-0000"
                        disabled={!isEditing}
                      />
                    )}
                  </InputMask>
                </Form.Item>
              </Col>
              <Col md={16} />
              <Col md={8}>
                <Form.Item name="status" valuePropName="checked">
                  <Checkbox disabled={!isEditing}>Ativa?</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end">
              <Col>
                {isEditing ? (
                  <Space>
                    <Button
                      key={"cancel-edit-franchisees"}
                      onClick={() => {
                        setIsEditing(false);
                        form.setFieldsValue(data);
                      }}
                      disabled={isEditingLoading}
                      htmlType="button"
                    >
                      Cancelar
                    </Button>
                    <Button
                      key={"save-franchisees"}
                      disabled={isEditingLoading}
                      loading={isEditingLoading}
                      type="primary"
                      htmlType="submit"
                    >
                      Salvar dados
                    </Button>
                  </Space>
                ) : null}
              </Col>
            </Row>
          </Form>
          <Row justify="end">
            {!isEditing ? (
              <Space>
                {!userTypeIsFranchisee ? (
                  <>
                    <Popconfirm
                      title="Tem certeza, que deseja deletar a franquia?!"
                      okType="default"
                      onConfirm={async () => await handleDelete()}
                      okText="Deletar"
                      cancelText="Cancelar"
                    >
                      <Button
                        key="bt-prod-delete"
                        color="danger"
                        danger
                        type="primary"
                      >
                        Deletar
                      </Button>
                    </Popconfirm>
                    <Button
                      key={"edit-franchisees"}
                      disabled={isDeletingLoading}
                      onClick={() => setIsEditing(true)}
                      type="primary"
                      htmlType="button"
                    >
                      Editar
                    </Button>
                  </>
                ) : null}
              </Space>
            ) : null}
          </Row>
        </Tabs.TabPane>
      </Tabs>

      {!userTypeIsFranchisee ? tabsTemplate : null}
    </PageHeader>
  );

  return <Container>{loading ? loadingTemplate : loadedTemplate}</Container>;
};

export default FranchisesAbout;
