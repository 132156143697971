import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Space,
  Row,
  Spin,
  Tabs,
  Tag,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../../api";
import { phoneNumber } from "../../../../utils/validators";
import ZonePage from "./Zone";
import { Container, ContainerLoading } from "./styles";
import {
  getStatusFranchisorLeadColor,
  getStatusFranchisorLeadText,
} from "../../../../utils/functions";
import { FranchisorLeadStatus } from "../../../../utils/enums";

const ProductsAbout: React.FC<{}> = () => {
  const { franchisorLeadId } = useParams<{ franchisorLeadId: string }>();
  const history = useHistory();

  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [editingLoading, setEditingLoading] = useState(false);

  const loadInfo = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/franchisor-lead/${franchisorLeadId}/`);

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setLoading(false);
    }
  }, [franchisorLeadId]);

  const handleAccept = useCallback(async () => {
    setEditingLoading(true);
    try {
      await api.patch(`/franchisor-lead/${franchisorLeadId}`, {
        status: FranchisorLeadStatus.TREATED,
      });
      notification.success({
        message: "Status do lead atualizado com sucesso",
      });
      setData({
        ...data,
        status: FranchisorLeadStatus.TREATED,
      });
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar o status do lead. Tente novamente., " +
          error,
      });
    } finally {
      setEditingLoading(false);
    }
  }, [franchisorLeadId, data]);

  const handleReject = useCallback(async () => {
    setEditingLoading(true);
    try {
      await api.patch(`/franchisor-lead/${franchisorLeadId}`, {
        status: FranchisorLeadStatus.REJECTED,
      });
      notification.success({
        message: "Status do lead atualizado com sucesso",
      });
      setData({
        ...data,
        status: FranchisorLeadStatus.REJECTED,
      });
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar o status do lead. Tente novamente., " +
          error,
      });
    } finally {
      setEditingLoading(false);
    }
  }, [franchisorLeadId, data]);

  useEffect(() => {
    let didCancel = false;

    loadInfo()
      .then(({ city, ...response }) => {
        !didCancel &&
          setData({ ...response, city: `${city.name} - ${city.uf}` });
        !didCancel &&
          form.setFieldsValue({
            ...response,
            city: `${city.name} - ${city.uf}`,
          });
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [form, loadInfo]); // No 'details' dep. is needed

  const loadingTemplate = (
    <ContainerLoading>
      <Spin></Spin>
    </ContainerLoading>
  );

  const loadedTemplate = (
    <PageHeader
      title="Dados do lead (quero ser franqueado)"
      onBack={() => history.goBack()}
    >
      <Tabs defaultActiveKey="about">
        <Tabs.TabPane tab="Dados gerais" key="about">
          <Form form={form} layout="vertical" autoComplete="off">
            <Row gutter={16}>
              <Col md={8}>
                <Form.Item
                  name="name"
                  label="Nome"
                  rules={[{ required: true, max: 512, min: 2 }]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="email"
                  label="E-mail"
                  rules={[{ required: true, type: "email" }]}
                >
                  <Input type={"email"} disabled />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="city"
                  label="Cidade"
                  rules={[{ required: true }]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="district"
                  label="Bairro"
                  rules={[{ required: true }]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="phone_number"
                  label="Número"
                  rules={[{ required: true }, { validator: phoneNumber }]}
                >
                  <InputMask mask="(99) 9 9999-9999" disabled>
                    {(props: Partial<any>) => (
                      <Input
                        {...props}
                        placeholder="(00) 9 0000-0000"
                        disabled
                      />
                    )}
                  </InputMask>
                </Form.Item>
              </Col>
              <Col md={8} />
              <Col md={8}>
                <Form.Item
                  name="message"
                  label="Mensagem"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea disabled rows={3}></Input.TextArea>
                </Form.Item>
              </Col>
              <Col md={16} />
              <Col md={8}>
                <Form.Item name="status" label="Status">
                  <Tag color={getStatusFranchisorLeadColor(data?.status)}>
                    {getStatusFranchisorLeadText(data?.status)}
                  </Tag>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end">
              <Col>
                <Space>
                  <Space>
                    <Popconfirm
                      title="Atualize o status do seu lead. O lead foi tratado ou rejeitado?"
                      okType="default"
                      onConfirm={async () => await handleAccept()}
                      onCancel={async () => await handleReject()}
                      okText="Lead tratado"
                      cancelText="Lead rejeitado"
                      disabled={
                        editingLoading ||
                        data?.status !== FranchisorLeadStatus.NOT_ANALYZED
                      }
                    >
                      <Button
                        key="bt-update-status"
                        color="primary"
                        type="primary"
                        disabled={
                          editingLoading ||
                          data?.status !== FranchisorLeadStatus.NOT_ANALYZED
                        }
                        loading={editingLoading}
                      >
                        Finalizar
                      </Button>
                    </Popconfirm>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Form>
        </Tabs.TabPane>
      </Tabs>

      <Divider />

      <Tabs defaultActiveKey="zones">
        <Tabs.TabPane tab="Zonas de interesse" key="zones">
          {data && franchisorLeadId ? (
            <ZonePage
              franchisorLeadId={franchisorLeadId}
              franchisorLeadStatus={data?.status}
            />
          ) : null}
        </Tabs.TabPane>
      </Tabs>
    </PageHeader>
  );

  return <Container>{loading ? loadingTemplate : loadedTemplate}</Container>;
};

export default ProductsAbout;
