import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  notification,
  Row,
  Space,
  Spin,
  Steps,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import api from "../../../../../../../../api";
import DebounceSelect from "../../../../../../../../components/DebounceSelect";
import { useAuth } from "../../../../../../../../providers/Auth";
import { deepCopyFunction } from "../../../../../../../../utils/functions";
import parse from "../../../../../../../../utils/parse";
import validator from "../../../../../../../../utils/validator";
import CombosNewOffers from "./CombosNewOffers";
import Resume from "./Resume";
import { Container } from "./styles";

export interface CombosEditProps {
  zoneId?: string;
  franchiseId?: string;
  comboId?: string;

  formData: any;
  products: any;
  offers: any;
  onCancel: () => void;
  onFinished: (newValues: any) => void;
}

const CombosEdit: React.FC<CombosEditProps> = ({
  onCancel,
  onFinished,
  zoneId: staticZoneId,
  offers: offersInitial,
  comboId: staticComboId,
  formData: formDataInitial,
  products: productsInitial,
  franchiseId: staticFranchiseId,
}) => {
  const { franchisee_id } = useAuth();
  const {
    zoneId: paramRouteZoneId,
    comboId: paramRouteComboId,
    franchiseId: paramRouteFranchiseId,
  } = useParams<{ zoneId: string; franchiseId: string; comboId: string }>();

  const [internetPlus, setInternetPlus] = useState<
    {
      id_offer: string;
      plus_download: number;
    }[]
  >(formDataInitial.offers_speed_plus);
  const [additionalInfoCombo, setAdditionalInfoCombo] = useState<
    {
      id_offer: string;
      info: string[];
    }[]
  >(formDataInitial.additional_info_offers || []);
  const [form] = Form.useForm();
  const [stepIndex, setStepIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const [zoneId] = useState(staticZoneId || paramRouteZoneId);
  const [comboId] = useState(paramRouteComboId || staticComboId);
  const [formData, setFormData] = useState<any>(formDataInitial);
  const [products, setProducts] = useState<any[]>(productsInitial || []);
  const [offers, setOffers] = useState<{ [x: string]: any[] }>(
    offersInitial || {}
  );
  const [templateOptions, setTemplateOptions] = useState<any[]>([
    formData.template,
  ]);
  const [franchiseId] = useState(
    staticFranchiseId || paramRouteFranchiseId || franchisee_id
  );

  async function fetchComboTemplateList(comboName: string): Promise<any[]> {
    try {
      const { data } = await api.get(`/combo`, {
        params: {
          pageSize: 999,
          offset: 0,
          ...(!comboName ? {} : { filters: { name: comboName } }),
        },
      });

      return data.data.slice(0);
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    }
  }

  const onReset = () => {
    setFormData({});
    form.resetFields();
    setProducts([]);
    setOffers({});
    setStepIndex(0);
  };

  const cancel = () => {
    onCancel && onCancel();
  };

  const onFinish = async () => {
    const handlers: { [s: number]: () => any } = {
      0: () => {
        form
          .validateFields()
          .then(() => {
            setFormData(form.getFieldsValue());
            setStepIndex(stepIndex + 1);
          })
          .catch((info) => {
            console.log("Validate Failed: ", info);
          });
      },
      1: () => {
        const err = validator(products, offers);
        if (!err) {
          setStepIndex(stepIndex + 1);
        } else {
          notification.warn({
            message: err,
          });
        }
      },
      2: async () => {
        const cloneForm = deepCopyFunction(formData);
        const cloneOffers: { [x: string]: any[] } = deepCopyFunction(offers);

        const custom_offers_price: { [x: string]: number } = {};

        const final = {
          ...cloneForm,
          offers: [
            ...Object.keys(cloneOffers)
              .reduce(
                (a: any[], v) => [
                  ...a,
                  ...(Array.isArray(cloneOffers[v])
                    ? cloneOffers[v]
                    : [cloneOffers[v]]),
                ],
                []
              )
              .map((v) => {
                if (
                  typeof v.custom_offers_price === "number" ||
                  /^(\d+(\.\d+)?)$/.test(`${v.custom_offers_price}`)
                ) {
                  const parsedCustom = +v.custom_offers_price;
                  const parsedOriginal = +v.price;
                  if (parsedCustom !== parsedOriginal) {
                    custom_offers_price[v.id] = parsedCustom;
                  }
                }

                return v;
              })
              .filter((v) =>
                products.some(
                  (p) => !!p.allowFranchiseSelect && v.product.id === p.id
                )
              )
              .map((v) => [+v.product.id, +v.id]),
          ],
          custom_offers_price: {},
          additional_info_offers: additionalInfoCombo,
          ...{ ...(internetPlus && { speed_plus: [internetPlus[0]] }) },
        };

        final.custom_offers_price = custom_offers_price;
        final.published = !!final.published;

        delete final.template_id;

        setLoading(true);

        try {
          await api.patch(
            `/franchisees/${franchiseId}/zone/${zoneId}/combo/${comboId}`,
            final
          );

          notification.success({
            message: "Combo atualizado com sucesso",
          });

          onReset();

          setLoading(false);

          onFinished && onFinished(final);
        } catch (error) {
          notification.error({
            message:
              "Ocorreu algum erro ao atualizar o combo. Tente novamente., " +
              error,
          });
          setLoading(false);
        }
      },
    };

    return handlers[stepIndex]();
  };

  const onChangedComboTemplate = async (value: any) => {
    if (value) {
      setTimeout(async () => {
        try {
          const { data } = await api.get(`/combo/${value}`);
          parse(
            data,
            (v: any) => {
              const {
                products: parsedProducts = [],
                offers: parsedOffers = {},
              } = v;
              setProducts(parsedProducts);
              setOffers(parsedOffers);
            },
            true
          );
          setFormData({
            ...formData,
            template_id: value,
          });
          form.setFieldsValue({
            ...form.getFieldsValue(),
            template_id: value,
          });
        } catch (error) {
          throw new Error("Erro ao carregar dados! " + error);
        }
      });
    } else {
      setProducts([]);
      setOffers({});
    }
  };

  useEffect(() => {
    let didCancel = false;

    !didCancel && form.setFieldsValue(formData);
    return () => {
      didCancel = true;
    };
  });

  return (
    <Container>
      <Spin spinning={loading}>
        <Space direction="vertical" style={{ width: `100%` }}>
          <Steps current={stepIndex}>
            <Steps.Step title="Dados gerais" />
            <Steps.Step title="Ofertas" />
            <Steps.Step title="Resumo" />
          </Steps>

          <div style={{ height: 15 }}></div>

          {stepIndex === 0 && (
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <Row gutter={15}>
                <Col md={6}>
                  <Form.Item
                    name="name"
                    label="Nome"
                    rules={[{ required: true, max: 512, min: 2 }]}
                  >
                    <Input placeholder="Digite o nome do combo" />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <Form.Item
                    name="template_id"
                    label="Template do combo"
                    rules={[{ required: true }]}
                  >
                    <DebounceSelect
                      placeholder="Selecione o template de combo"
                      showSearch
                      fetchOptions={fetchComboTemplateList}
                      afterFetchOptions={(o) => setTemplateOptions(o || [])}
                      style={{ width: "100%" }}
                      labelProp="name"
                      valueProp={"id"}
                      initialOptions={
                        !templateOptions.length && formData.template
                          ? [formData.template]
                          : templateOptions.length
                          ? templateOptions
                          : []
                      }
                      onChange={onChangedComboTemplate}
                      disabled={true}
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} />

                <Col md={6}>
                  <Form.Item name="published" valuePropName="checked">
                    <Checkbox>Publicado?</Checkbox>
                  </Form.Item>
                </Col>
                <Col md={18} />

                <Col md={8}>
                  <Typography.Title level={5}>
                    Informações adicionais
                  </Typography.Title>
                  <Form.List name="additional_info">
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item
                            label={`Informação ${index + 1}`}
                            required={true}
                            key={field.key}
                          >
                            <Form.Item
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message:
                                    "Preencha este campo, ou remova ele.",
                                },
                              ]}
                              noStyle
                              style={{ display: "flex" }}
                            >
                              <Input
                                placeholder="Digite a informação..."
                                style={{ width: "calc(100% - 30px)" }}
                              />
                            </Form.Item>
                            <MinusOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(field.name)}
                            />
                          </Form.Item>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: "100%" }}
                            icon={<PlusOutlined />}
                          >
                            Adicionar informação
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>

              <div style={{ display: `flex`, justifyContent: `flex-end` }}>
                <Space>
                  <Button onClick={() => cancel()}>Cancelar</Button>

                  <Button type="primary" htmlType="submit">
                    Próximo
                  </Button>
                </Space>
              </div>
            </Form>
          )}

          {stepIndex === 1 && (
            <CombosNewOffers
              offers={offers}
              zoneId={zoneId}
              onNext={onFinish}
              products={products}
              onRemoved={() => {}}
              onInserted={() => {}}
              onCancel={() => cancel()}
              franchiseId={franchiseId}
              internetPlus={internetPlus!}
              onChanged={(values) => {
                setOffers(values);
              }}
              handlerInternetPlus={(v) => {
                setInternetPlus(v);
              }}
              onChangedInfoCombo={(infoCombo) => {
                setAdditionalInfoCombo(infoCombo);
                console.log(infoCombo);
              }}
              additional_info_offers={additionalInfoCombo}
              onPrevius={() => setStepIndex(stepIndex - 1)}
            />
          )}

          {stepIndex === 2 && (
            <Resume
              offers={offers}
              onNext={onFinish}
              loading={loading}
              products={products}
              formData={formData}
              speedPlus={internetPlus}
              onCancel={() => cancel()}
              infoOffers={additionalInfoCombo}
              onPrevius={() => setStepIndex(stepIndex - 1)}
            />
          )}
        </Space>
      </Spin>
    </Container>
  );
};

export default CombosEdit;
