import { FormInstance } from "antd";

export function invalidContainNumbers(
  _: any,
  value: { number: number }
): Promise<void> {
  if (!/\d/gm.test(`${value}`)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Números não permitidos!"));
}

export function invalidContainSpace(
  _: any,
  value: { number: number }
): Promise<void> {
  if (!/\s/gm.test(`${value}`)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Espaços não permitidos!"));
}

function isValidCPF(cpf: string) {
  if (typeof cpf !== "string") return false;
  cpf = cpf.replace(/[\s.-]*/gim, "");
  if (
    !cpf ||
    cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999"
  ) {
    return false;
  }
  var soma = 0;
  var resto;
  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(10, 11))) return false;
  return true;
}

function isCNPJFormatValid(cnpj: string): boolean {
  const cnpjRegex = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/;
  return cnpjRegex.test(cnpj);
}

function isCNPJValid(cnpj: string): boolean {
  // Remove any non-numeric characters from the CNPJ
  cnpj = cnpj.replace(/[^\d]+/g, "");

  // Check if the CNPJ is the right length
  if (cnpj.length !== 14) {
    return false;
  }

  // Calculate the first verification digit
  let sum = 0;
  let factor = 5;
  for (let i = 0; i < 12; i++) {
    sum += parseInt(cnpj.charAt(i)) * factor;
    factor = factor === 2 ? 9 : factor - 1;
  }
  let firstDigit = 11 - (sum % 11);
  if (firstDigit > 9) {
    firstDigit = 0;
  }

  // Calculate the second verification digit
  sum = 0;
  factor = 6;
  for (let i = 0; i < 13; i++) {
    sum += parseInt(cnpj.charAt(i)) * factor;
    factor = factor === 2 ? 9 : factor - 1;
  }
  let secondDigit = 11 - (sum % 11);
  if (secondDigit > 9) {
    secondDigit = 0;
  }

  // Check if the verification digits match the CNPJ
  return (
    parseInt(cnpj.charAt(12)) === firstDigit &&
    parseInt(cnpj.charAt(13)) === secondDigit
  );
}

export function validateAndInsertCNPJ(_: any, cnpj: string): Promise<void> {
  if (!cnpj) {
    return Promise.resolve();
  }

  if (!isCNPJFormatValid(cnpj)) {
    throw new Error("Formato do CNPJ ínvalido. Tente ex.: 99.999.999/9999-99.");
  }

  if (!isCNPJValid(cnpj)) {
    throw new Error("CNPJ ínvalido");
  }

  return Promise.resolve();
}

export function documentCPF(_: any, value: string): Promise<void> {
  if (
    !value ||
    (/\d{3}\.\d{3}\.\d{3}-\d{2}/gm.test(`${value}`) && isValidCPF(value))
  ) {
    return Promise.resolve();
  }
  return Promise.reject(
    new Error("Formato do documento ínvalido. Tente ex.: 999.999.999-99")
  );
}

export function phoneNumber(_: any, value: { number: number }): Promise<void> {
  if (!value || /\(\d{2}\)\s?\9?\s?\d{4}-\d{4}/gm.test(`${value}`)) {
    return Promise.resolve();
  }
  return Promise.reject(
    new Error("Formato do telefone ínvalido. Tente ex.: (99) 9 9999-9999")
  );
}

export function phoneResidential(
  _: any,
  value: { number: number }
): Promise<void> {
  if (!value || /\(\d{2}\)\s?\d{4}-\d{4}/gm.test(`${value}`)) {
    return Promise.resolve();
  }
  return Promise.reject(
    new Error("Formato do telefone ínvalido. Tente ex.: (99) 9999-9999")
  );
}

export function phoneNumber0800(
  _: any,
  value: { number: number }
): Promise<void> {
  if (!value || /\d{3}\s?\d{3}-\d{4}/gm.test(`${value}`)) {
    return Promise.resolve();
  }
  return Promise.reject(
    new Error("Formato do telefone ínvalido. Tente ex.: 0800 999-9999")
  );
}

export function mustBeEqualTo(
  key: string,
  form: FormInstance
): (field: any, value: any) => Promise<void> {
  return (_, value) => {
    const second_value = form.getFieldValue(key);

    if (!value || second_value === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Campos não coincidem!"));
  };
}
