import {
  ArrowUpOutlined,
  ClearOutlined,
  ClockCircleOutlined,
  DislikeOutlined,
  LikeOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  notification,
  PageHeader,
  Row,
  Select,
  Statistic,
  Table,
  TableColumnType,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";

import api from "../../../api";
import DebounceSelect from "../../../components/DebounceSelect";
import {
  FranchisorLeadStatus,
  FranchisorLeadStatusName,
} from "../../../utils/enums";
import provices from "../../../utils/provices";
import { Container } from "./styles";

const defaultPageSize = 10;

const ReportFranchisee: React.FC = () => {
  const [shouldReloadTable, setShouldReloadTable] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState<Partial<any>>({});
  const [meta, setMeta] = useState<Partial<any>>({
    total: null,
    approved: null,
    rejected: null,
    not_analyzed: null,
  });
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: defaultPageSize,
    showSizeChanger: true,
  });

  const loadData = useCallback(async (params: any) => {
    setTableLoading(true);

    const { current, pageSize, sortField, sortOrder, filters } = params;

    try {
      const { data } = await api.get(`/franchisees/report/lead`, {
        params: {
          page: current,
          pageSize: pageSize,
          offset: (current - 1) * pageSize,
          ...(filters ? { filters } : {}),
          ...(sortField ? { order_by: sortField } : {}),
          ...(sortOrder ? { sort_by: sortOrder } : {}),
        },
      });

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setTableLoading(false);
    }
  }, []);

  const onHandleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (!pagination) return;

    loadData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...pagination,
      ...filters,
    })
      .then((response) => {
        setTablePagination((old) => ({
          ...old,
          ...pagination,
          total: response.total,
        }));
        setData(response.data);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));
  };

  const onChangeFilters = (
    field_name: string,
    parser?: (value: any, key: string) => any
  ) => {
    return (value: any) => {
      if (parser) {
        const parsedValue = parser(value, field_name);
        const _filters = {
          ...filters,
          ...(parsedValue ?? {}),
        };
        setFilters(_filters);
      } else {
        const _filters = {
          ...filters,
          [field_name]: value,
        };
        setFilters(_filters);
      }
    };
  };

  const onHandleReloadData = () => setShouldReloadTable(!shouldReloadTable);

  async function fetchFranchiseeList(franchiseename: string): Promise<any[]> {
    try {
      const { data } = await api.get("/franchisees", {
        params: {
          pageSize: 999,
          offset: 0,
          ...(!franchiseename ? {} : { filters: { name: franchiseename } }),
        },
      });

      return data.data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    }
  }

  const tableCols: TableColumnType<any>[] = [
    {
      key: "name",
      title: "Nome da franquia",
      dataIndex: "name",
      sorter: true,
    },
    {
      key: "total",
      title: "Total",
      dataIndex: "total",
      sorter: true,
      render: (total: any) => (
        <span style={{ color: "#00bcd4" }}>{total ?? "---"}</span>
      ),
    },
    {
      key: "treated",
      title: "Tratado",
      dataIndex: "treated",
      sorter: true,
      render: (treated: any) => (
        <span style={{ color: "#3f8600" }}>{treated ?? "---"}</span>
      ),
    },
    {
      key: "rejected",
      title: "Rejeitado",
      dataIndex: "rejected",
      sorter: true,
      render: (rejected: any) => (
        <span style={{ color: "#cf1322" }}>{rejected ?? "---"}</span>
      ),
    },
    {
      key: "not_analyzed",
      title: "Aberto",
      dataIndex: "not_analyzed",
      sorter: true,
      render: (not_analyzed: any) => (
        <span style={{ color: "#9e9e9e" }}>{not_analyzed ?? "---"}</span>
      ),
    },
  ];

  useEffect(() => {
    let didCancel = false;

    const _filters = Object.keys(filters).reduce((o: Partial<any>, k) => {
      if (Array.isArray(filters[k]) ? filters[k].length : filters[k]) {
        o[k] = filters[k];
      }
      return o;
    }, {});

    loadData({
      current: 1,
      pageSize: defaultPageSize,
      ...(Object.keys(filters).length ? { filters: _filters } : {}),
    })
      .then((response) => {
        !didCancel && setMeta(response.meta);
        !didCancel && setData(response.data);
        setTablePagination((old) => ({ ...old, total: response.total }));
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [loadData, shouldReloadTable, filters]);

  return (
    <Container>
      <PageHeader
        title="Relatório das franquias"
        subTitle=""
        extra={[
          <Select
            style={{ width: 120 }}
            onChange={onChangeFilters("status")}
            value={(filters as any)?.["status"]}
            defaultValue={(filters as any)?.["status"]}
            placeholder="Status"
            allowClear
          >
            <Select.Option value={FranchisorLeadStatus.TREATED}>
              {FranchisorLeadStatusName.TREATED}
            </Select.Option>
            <Select.Option value={FranchisorLeadStatus.REJECTED}>
              {FranchisorLeadStatusName.REJECTED}
            </Select.Option>
            <Select.Option value={FranchisorLeadStatus.NOT_ANALYZED}>
              {FranchisorLeadStatusName.NOT_ANALYZED}
            </Select.Option>
          </Select>,
          <DebounceSelect
            key="bt-ds-franchisee"
            placeholder="Pesquisar franquia..."
            showSearch
            fetchOptions={fetchFranchiseeList}
            style={{ minWidth: "200px", marginLeft: 12 }}
            labelProp="name"
            valueProp="id"
            onChange={onChangeFilters("franchisee")}
            value={(filters as any)?.["franchisee"]}
            allowClear
          />,
          <Select
            key="bt-ds-city-search"
            style={{ minWidth: "200px", marginLeft: 12 }}
            onChange={onChangeFilters("uf")}
            value={(filters as any)?.["uf"]}
            defaultValue={(filters as any)?.["uf"]}
            placeholder="Selecionar estado..."
            allowClear
          >
            {provices.map((item) => (
              <Select.Option key={`${item.uf}-${item.name}`} value={item.uf}>
                {item.name}
              </Select.Option>
            ))}
          </Select>,
          <DatePicker.RangePicker
            key="bt-ds-period-search"
            ranges={{
              Hoje: [moment(), moment()],
              "Este mês": [moment().startOf("month"), moment().endOf("month")],
            }}
            onChange={onChangeFilters("period", (values) => ({
              created_at:
                values && values.length
                  ? values.map((v: any) => v.format("YYYY-MM-DD"))
                  : null,
            }))}
            value={
              filters?.created_at?.length
                ? [
                    moment(filters?.created_at[0]),
                    moment(filters?.created_at[1]),
                  ]
                : ([] as any)
            }
          />,
          filters?.created_at?.length || filters.city || filters.status ? (
            <Button
              title="Limpar filtros"
              key="bt-ds-clear-search"
              icon={<ClearOutlined />}
              onClick={() => setFilters({})}
            />
          ) : null,
          <Button
            key="bt-ds-reload"
            icon={<ReloadOutlined />}
            onClick={onHandleReloadData}
          >
            Recarregar dados
          </Button>,
        ]}
      >
        <Row style={{ marginTop: 12 }}>
          <Col span={24} style={{ marginBottom: 20 }}>
            <Row gutter={15}>
              <Col span={6}>
                <Card>
                  <Statistic
                    loading={tableLoading}
                    title="Total de Leads"
                    value={meta?.total ?? "---"}
                    valueStyle={{ color: "#00bcd4" }}
                    prefix={<ArrowUpOutlined />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    loading={tableLoading}
                    title="Leads Tratados"
                    value={meta?.treated ?? "---"}
                    valueStyle={{ color: "#3f8600" }}
                    prefix={<LikeOutlined />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    loading={tableLoading}
                    title="Leads Rejeitados"
                    value={meta?.rejected ?? "---"}
                    valueStyle={{ color: "#cf1322" }}
                    prefix={<DislikeOutlined />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    loading={tableLoading}
                    title="Leads em Aberto"
                    value={meta?.not_analyzed ?? "---"}
                    valueStyle={{ color: "#9e9e9e" }}
                    prefix={<ClockCircleOutlined />}
                  />
                </Card>
              </Col>
            </Row>
          </Col>

          <Col md={24}>
            <Table
              size="middle"
              rowKey={(record: any) => record.id}
              dataSource={data}
              columns={tableCols}
              loading={tableLoading}
              pagination={tablePagination}
              onChange={onHandleTableChange}
            />
          </Col>
        </Row>
      </PageHeader>
    </Container>
  );
};

export default ReportFranchisee;
