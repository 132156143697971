import {
  Form,
  Input,
  Spin,
  notification,
  PageHeader,
  Tabs,
  Row,
  Col,
  Button,
  Space,
  Popconfirm,
  Divider,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import api from "../../../../api";
import { transformToLowerCaseFromInput } from "../../../../utils/functions";
import { invalidContainSpace } from "../../../../utils/validators";

import Sliders from "./Sliders";

import { Container, ContainerLoading } from "./styles";

const PagesAbout: React.FC<{}> = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const history = useHistory();

  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLoading, setIsEditingLoading] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadInfo = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/page/${pageId}/`);

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setLoading(false);
    }
  }, [pageId]);

  const handleDelete = useCallback(async () => {
    setIsDeletingLoading(true);
    try {
      await api.delete(`/page/${pageId}/`);
      notification.success({
        message: "Página deletada com sucesso",
      });

      setData(null);

      history.goBack();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao deletar a página. Tente novamente., " + error,
      });
    } finally {
      setIsDeletingLoading(false);
    }
  }, [pageId, history]);

  const onFinish = async (values: any) => {
    setIsEditingLoading(true);

    try {
      await api.patch(`/page/${pageId}`, values);

      notification.success({
        message: "Página atualizada com sucesso",
      });

      const dataMerged = {
        ...data,
        ...values,
      };

      setIsEditing(false);

      setTimeout(setData, 10, dataMerged);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar a página. Tente novamente., " +
          error,
      });
    } finally {
      setIsEditingLoading(false);
    }
  };

  useEffect(() => {
    let didCancel = false;

    loadInfo()
      .then((response) => {
        !didCancel && setData(response);
        !didCancel && form.setFieldsValue(response);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [form, loadInfo]); // No 'details' dep. is needed

  const loadingTemplate = (
    <ContainerLoading>
      <Spin></Spin>
    </ContainerLoading>
  );

  const loadedTemplate = (
    <PageHeader title="Dados da página" onBack={() => history.goBack()}>
      <Tabs defaultActiveKey="about">
        <Tabs.TabPane tab="Dados gerais" key="about">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col md={8}>
                <Form.Item
                  name="name"
                  label="Nome"
                  rules={[
                    { required: true, max: 512, min: 2 },
                    { validator: invalidContainSpace },
                  ]}
                >
                  <Input
                    placeholder="Digite o nome da página"
                    disabled={!isEditing}
                    onInput={transformToLowerCaseFromInput}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end">
              <Col>
                {isEditing ? (
                  <Space>
                    <Button
                      key={"cancel-edit-page"}
                      onClick={() => {
                        setIsEditing(false);
                        form.setFieldsValue(data);
                      }}
                      disabled={isEditingLoading}
                      htmlType="button"
                    >
                      Cancelar
                    </Button>
                    <Button
                      key={"save-page"}
                      disabled={isEditingLoading}
                      loading={isEditingLoading}
                      type="primary"
                      htmlType="submit"
                    >
                      Salvar dados
                    </Button>
                  </Space>
                ) : null}
              </Col>
            </Row>
          </Form>
          <Row justify="end">
            {!isEditing ? (
              <Space>
                <Popconfirm
                  title="Tem certeza, que deseja deletar a página?!"
                  okType="default"
                  onConfirm={async () => await handleDelete()}
                  okText="Deletar"
                  cancelText="Cancelar"
                >
                  <Button
                    key="bt-page-delete"
                    color="danger"
                    danger
                    type="primary"
                  >
                    Deletar
                  </Button>
                </Popconfirm>

                <Button
                  key={"edit-page"}
                  disabled={isDeletingLoading}
                  onClick={() => setIsEditing(true)}
                  type="primary"
                  htmlType="button"
                >
                  Editar
                </Button>
              </Space>
            ) : null}
          </Row>
        </Tabs.TabPane>
      </Tabs>

      <Divider />

      <Tabs defaultActiveKey="sliders">
        <Tabs.TabPane tab="Slides" key="sliders">
          {data && pageId ? <Sliders pageId={pageId} /> : null}
        </Tabs.TabPane>
      </Tabs>
    </PageHeader>
  );

  return <Container>{loading ? loadingTemplate : loadedTemplate}</Container>;
};

export default PagesAbout;
