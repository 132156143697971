import {
  Button,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tabs,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import api from "../../../../api";
import FieldsProductRegister from "../FieldsProductRegister";
import ComboRules from "./ComboRules";
import Offers from "./Offers";
import RegulationsPage from "./Regulations";
import { Container, ContainerLoading } from "./styles";

const ProductsAbout: React.FC<{}> = () => {
  const { productId } = useParams<{ productId: string }>();
  const history = useHistory();

  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLoading, setIsEditingLoading] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const [regulations, setRegulations] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const loadInfo = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/product/${productId}/`);

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setLoading(false);
    }
  }, [productId]);

  const handleDelete = useCallback(async () => {
    setIsDeletingLoading(true);
    try {
      await api.delete(`/product/${productId}/`);
      notification.success({
        message: "Produto deletado com sucesso",
      });

      setData(null);

      history.goBack();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao deletar um produto. Tente novamente., " +
          error,
      });
    } finally {
      setIsDeletingLoading(false);
    }
  }, [productId, history]);

  const onFinish = async (values: any) => {
    setIsEditingLoading(true);

    try {
      await api.patch(`/product/${productId}`, values);

      notification.success({
        message: "Produto atualizado com sucesso",
      });

      const dataMerged = {
        ...data,
        ...values,
      };

      setIsEditing(false);

      setTimeout(setData, 10, dataMerged);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar um produto. Tente novamente., " +
          error,
      });
    } finally {
      setIsEditingLoading(false);
    }
  };

  useEffect(() => {
    let didCancel = false;

    loadInfo()
      .then((response) => {
        !didCancel && setData(response);
        !didCancel && setRegulations(response.regulations);
        !didCancel && form.setFieldsValue(response);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [form, loadInfo]); // No 'details' dep. is needed

  const loadingTemplate = (
    <ContainerLoading>
      <Spin></Spin>
    </ContainerLoading>
  );

  const loadedTemplate = (
    <PageHeader title="Dados do produto" onBack={() => history.goBack()}>
      <Tabs defaultActiveKey="about">
        <Tabs.TabPane tab="Dados gerais" key="about">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col md={8}>
                <Form.Item
                  name="name"
                  label="Nome"
                  rules={[{ required: true, max: 512, min: 2 }]}
                >
                  <Input
                    placeholder="Digite o nome do produto"
                    disabled={!isEditing}
                  />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="base_color"
                  label="Cor base"
                  rules={[{ required: true }]}
                >
                  <Input type="color" disabled={!isEditing} />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end">
              <Col>
                {isEditing ? (
                  <Space>
                    <Button
                      key={"cancel-edit-product"}
                      onClick={() => {
                        setIsEditing(false);
                        form.setFieldsValue(data);
                      }}
                      disabled={isEditingLoading}
                      htmlType="button"
                    >
                      Cancelar
                    </Button>
                    <Button
                      key={"save-product"}
                      disabled={isEditingLoading}
                      loading={isEditingLoading}
                      type="primary"
                      htmlType="submit"
                    >
                      Salvar dados
                    </Button>
                  </Space>
                ) : null}
              </Col>
            </Row>
          </Form>
          <Row justify="end">
            {!isEditing ? (
              <Space>
                <Popconfirm
                  title="Tem certeza, que deseja deletar o produto?!"
                  okType="default"
                  onConfirm={async () => await handleDelete()}
                  okText="Deletar"
                  cancelText="Cancelar"
                >
                  <Button
                    key="bt-prod-delete"
                    color="danger"
                    danger
                    type="primary"
                  >
                    Deletar
                  </Button>
                </Popconfirm>

                <Button
                  key={"edit-product"}
                  disabled={isDeletingLoading}
                  onClick={() => setIsEditing(true)}
                  type="primary"
                  htmlType="button"
                >
                  Editar
                </Button>
              </Space>
            ) : null}
          </Row>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Metadados" key="metadata">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
          >
            {(data && data.config && data.config?.length) || isEditing ? (
              <FieldsProductRegister isReadonly={!isEditing} />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Sem metadados"
              />
            )}

            <Row
              justify="end"
              style={{
                marginTop: 15,
              }}
            >
              <Col>
                {isEditing ? (
                  <Space>
                    <Button
                      key={"cancel-edit-product"}
                      onClick={() => {
                        setIsEditing(false);
                        form.setFieldsValue(data);
                      }}
                      disabled={isEditingLoading}
                      htmlType="button"
                    >
                      Cancelar
                    </Button>
                    <Button
                      key={"save-product"}
                      disabled={isEditingLoading}
                      loading={isEditingLoading}
                      type="primary"
                      htmlType="submit"
                    >
                      Salvar dados
                    </Button>
                  </Space>
                ) : null}
              </Col>
            </Row>
          </Form>
          <Row justify="end">
            {!isEditing ? (
              <Space>
                <Popconfirm
                  title="Tem certeza, que deseja deletar o produto?!"
                  okType="default"
                  onConfirm={async () => await handleDelete()}
                  okText="Deletar"
                  cancelText="Cancelar"
                >
                  <Button
                    key="bt-prod-delete"
                    color="danger"
                    danger
                    type="primary"
                  >
                    Deletar
                  </Button>
                </Popconfirm>

                <Button
                  key={"edit-product"}
                  disabled={isDeletingLoading}
                  onClick={() => setIsEditing(true)}
                  type="primary"
                  htmlType="button"
                >
                  Editar
                </Button>
              </Space>
            ) : null}
          </Row>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Regras de combos" key="combo_rules">
          {data && (
            <ComboRules
              product={data}
              updateProduct={() => console.log("Update product")}
            />
          )}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Regulamentos" key="regulations">
          {data && productId ? (
            <RegulationsPage
              productId={productId}
              regulations={regulations}
              onRemoved={(id) => {
                const selfData = data;
                setRegulations([...regulations].filter((v) => v.id !== id));
                selfData["regulations"] = regulations;
                setData({
                  ...selfData,
                });
              }}
              onAdded={(value) => {
                const selfData = data;
                setRegulations([value, ...regulations]);
                selfData["regulations"] = regulations;
                setData({
                  ...selfData,
                });
              }}
            />
          ) : null}
        </Tabs.TabPane>
      </Tabs>

      <Divider />

      <Tabs defaultActiveKey="offers">
        <Tabs.TabPane tab="Ofertas" key="offers">
          {data && productId ? <Offers productId={productId} /> : null}
        </Tabs.TabPane>
      </Tabs>
    </PageHeader>
  );

  return <Container>{loading ? loadingTemplate : loadedTemplate}</Container>;
};

export default ProductsAbout;
