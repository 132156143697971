import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, InputNumber, notification, PageHeader, Popconfirm, Row, Space, Spin, Tabs, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import api from "../../../../../../api";
import FieldsOfferRegister from '../FieldsOfferRegister';
import { Container, ContainerLoading } from './styles';

const { Title } = Typography;


const ProductsOfferAbout: React.FC<{}> = () => {
  const { productId, offerId } = useParams<{ productId: string; offerId: string }>();
  const history = useHistory();

  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLoading, setIsEditingLoading] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadInfo = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/product/${productId}/offer/${offerId}`);

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setLoading(false);
    }
  }, [productId, offerId]);

  const handleDelete = useCallback(async () => {
    setIsDeletingLoading(true);
    try {
      await api.delete(`/product/${productId}/offer/${offerId}`);
      notification.success({
        message: "Oferta deletada com sucesso",
      });

      setData(null);

      history.goBack();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao deletar a oferta. Tente novamente., " +
          error,
      });
    } finally {
      setIsDeletingLoading(false);
    }
  }, [productId, history, offerId]);

  const onFinish = async (values: any) => {
    setIsEditingLoading(true);

    if ('price' in values && typeof values.price === 'string') {
      try {
        values.price = +values.price;
      } catch (_) { }
    }

    try {
      await api.patch(`/product/${productId}/offer/${offerId}`, values);

      notification.success({
        message: "Oferta atualizada com sucesso",
      });

      const dataMerged = {
        ...data,
        ...values,
      };

      setIsEditing(false);

      setTimeout(setData, 10, dataMerged);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar a oferta. Tente novamente., " +
          error,
      });

    } finally {
      setIsEditingLoading(false);
    }
  };

  useEffect(() => {
    let didCancel = false;

    loadInfo()
      .then((response) => {
        !didCancel && setData(response);
        !didCancel && form.setFieldsValue(response);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [form, loadInfo]); // No 'details' dep. is needed

  const loadingTemplate = (
    <ContainerLoading>
      <Spin></Spin>
    </ContainerLoading>
  );

  const loadedTemplate = (
    <Spin spinning={isDeletingLoading || isEditingLoading}>
      <PageHeader title="Dados da oferta" onBack={() => history.goBack()}>
        <Tabs defaultActiveKey="about">
          <Tabs.TabPane tab="Dados gerais" key="about">
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              autoComplete="off"
            >
              <Row gutter={16}>
                <Col md={8}>
                  <Form.Item name="title" label="Título" rules={[{ required: true, max: 512, min: 2 }]}>
                    <Input placeholder='Digite o título da oferta' disabled={!isEditing} />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item name="price" label="Preço" rules={[{ required: true }]}>
                    <InputNumber
                      style={{ display: 'block', width: '100%' }}
                      formatter={value => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value?.replace(/R\$\s?|(,*)/g, '') as any}

                      disabled={!isEditing}
                    />
                  </Form.Item>
                </Col>
                <Col md={8}></Col>
                <Col md={4}>
                  <Form.Item name="status" valuePropName="checked" wrapperCol={{ offset: 0, span: 24 }}>
                    <Checkbox checked disabled={!isEditing}>Ativo?</Checkbox>
                  </Form.Item>
                </Col>
                <Col md={4}>
                  <Form.Item name={['metadata', 'best_offer']} valuePropName="checked" wrapperCol={{ offset: 0, span: 24 }}>
                    <Checkbox checked disabled={!isEditing}>Melhor oferta?</Checkbox>
                  </Form.Item>
                </Col>
                <Col md={16}></Col>
                <Col md={8}>
                  <Title level={5}>
                    Informações adicionais
                  </Title>
                  <Form.List name="additional_info">
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item label={`Informação ${index + 1}`} required={true} key={field.key}>
                            <Form.Item
                              {...field}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Preencha este campo, ou remova ele.",
                                },
                              ]}
                              noStyle
                              style={{ display: 'flex' }}
                            >
                              <Input placeholder="passenger name" style={{ width: `calc(100% - ${isEditing ? 30 : 0}px)` }} disabled={!isEditing} />
                            </Form.Item>
                            {isEditing ? (
                              <MinusOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </Form.Item>
                        ))}

                        {isEditing ? (
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              style={{ width: '100%' }}
                              icon={<PlusOutlined />}
                              disabled={!isEditing}
                            >
                              Adicionar informação
                            </Button>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        ) : null}
                      </>
                    )}
                  </Form.List>
                </Col>
                <Col md={8}></Col>

                {data?.product && data?.product?.config ? (
                  <Col md={16}>
                    <Title level={5}>
                      Informações sobre a oferta
                    </Title>

                    <Row gutter={15}>
                      <Col md={24}>
                        <FieldsOfferRegister config={(data?.product as any)?.config as any || []} isDisabled={!isEditing} />
                      </Col>
                    </Row>
                  </Col>
                ) : null}

              </Row>

              <Row justify="end">
                <Col>
                  {isEditing ? (
                    <Space>
                      <Button
                        key={'cancel-edit-product'}
                        onClick={() => { setIsEditing(false); form.setFieldsValue(data); }}
                        disabled={isEditingLoading}
                        htmlType="button"
                      >
                        Cancelar
                      </Button>
                      <Button
                        key={'save-product'}
                        disabled={isEditingLoading}
                        loading={isEditingLoading}
                        type="primary"
                        htmlType="submit"
                      >
                        Salvar dados
                      </Button>
                    </Space>
                  ) : null}
                </Col>
              </Row>
            </Form>
            <Row justify="end">
              {!isEditing ? (
                <Space>
                  <Popconfirm
                    title="Tem certeza, que deseja deletar o produto?!"
                    okType="default"
                    onConfirm={async () => await handleDelete()}
                    okText="Deletar"
                    cancelText="Cancelar"
                  >
                    <Button
                      key="bt-prod-delete"
                      color="danger"
                      danger
                      type="primary"
                    >
                      Deletar
                    </Button>
                  </Popconfirm>

                  <Button
                    key={'edit-product'}
                    disabled={isDeletingLoading}
                    onClick={() => setIsEditing(true)}
                    type="primary"
                    htmlType="button"
                  >
                    Editar
                  </Button>
                </Space>
              ) : null}
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </PageHeader>
    </Spin>
  );

  return (
    <Container>
      {loading ? loadingTemplate : loadedTemplate}
    </Container>
  );
}

export default ProductsOfferAbout;
