import { DownOutlined } from '@ant-design/icons';
import { Form, Modal, notification, Tree } from 'antd';
import React from 'react';
import { useState } from 'react';

import DebounceSelect from '../../../../../../components/DebounceSelect';
import { deepCopyFunction, hash } from '../../../../../../utils/functions';
import api from './../../../../../../api';

const SelectProduct: React.FC<{
  isVisible?: boolean;
  products: any[];
  onSelect?: (product: any) => void;
  onCancel?: () => void;
}> = ({ isVisible, onSelect, onCancel, products }) => {
  const [form] = Form.useForm();
  const [treeNodes, setTreeNodes] = useState<any>([]);

  const onFinish = async (values: any) => {
    if (values.products) {
      const copy = deepCopyFunction(values);
      if (copy.products) {
        const values: any = [];
        for (const product of copy.products) {
          values.push(JSON.parse(product.value));
        }
        if (values && values.length) {
          onSelect && onSelect(values);
          onReset();
        }
      }
    }
  };

  const onReset = () => {
    form.resetFields();
    setTreeNodes([]);
  };

  async function fetchProductsList(productname: string): Promise<any[]> {
    try {
      const { data } = await api.get('/product', {
        params: {
          pageSize: 999,
          offset: 0,
          ...(!productname ? {} : { filters: { name: productname } }),
        },
      });

      return data.data.slice(0).filter((item: any) => {
        return (
          !products.some(pdct => pdct.id === item.id) &&
          (item.combo_rules || []).length
        );
      });
    } catch (error) {
      throw new Error('Erro ao carregar dados! ' + error);
    }
  }

  function removeCyclicOptions() {
    const pdrts: any[] = deepCopyFunction(form.getFieldValue('products')) || [];
    const allProductsIdFromRules: any[] = [];
    const parsedValues: any = [];
    for (let i = 0, l = pdrts.length; i < l; i++) {
      const pdrt = pdrts[i];
      const valueStr = `${pdrt.value}`;
      const valueParsed = JSON.parse(valueStr);
      if (valueParsed.combo_rules && valueParsed.combo_rules.length) {
        valueParsed.combo_rules.forEach((cr: any) =>
          allProductsIdFromRules.push(cr.product.id),
        );
      }
      parsedValues.push(valueParsed);
    }
    const removedPrdts: any[] = [];
    const newPdrts = pdrts.filter((_: any, idx) => {
      const prdt = parsedValues[idx];

      if (prdt) {
        const remove = !allProductsIdFromRules.includes(prdt.id);
        if (!remove) {
          removedPrdts.push(prdt);
        }
        return remove;
      }
      return false;
    });
    if (newPdrts.length !== pdrts.length) {
      form.setFieldsValue({
        products: newPdrts,
      });

      removedPrdts.forEach(prdt => {
        notification.warn({
          message:
            `O produto "#${prdt.id} - ${prdt.name}" foi removido da lista de seleção ` +
            `pois existe uma opção cíclica!`,
        });
      });
    }
  }

  function buildTreeNodes() {
    const pdrts = form.getFieldValue('products');
    const parsedValues: any = [];
    for (let i = 0, l = pdrts.length; i < l; i++) {
      const pdrt = pdrts[i];
      const valueStr = `${pdrt.value}`;
      const valueParsed = JSON.parse(valueStr);
      parsedValues.push(valueParsed);
    }
    const parsedToNodes = parsedValues.map((root: any) => {
      return {
        key: `${hash()}`,
        title: `#${root.id} - ${root.name}`,
        children: (root.combo_rules || []).map((field: any) => {
          return {
            key: `${hash()}`,
            title: `#${field.product.id} - ${field.product.name}`,
          };
        }),
      };
    });
    setTreeNodes(parsedToNodes);
  }

  const filterAlreadySelectedValues = (options?: any[]): any[] => {
    if (options) {
      const prdts = form.getFieldValue('products') || [];
      const parsedIds: any[] = [];
      for (let i = 0, l = prdts.length; i < l; i++) {
        const valueStr = `${prdts[i].value}`;
        const valueParsed = JSON.parse(valueStr);
        parsedIds.push(valueParsed.id);

        if (valueParsed.combo_rules && valueParsed.combo_rules.length) {
          valueParsed.combo_rules.forEach((cr: any) =>
            parsedIds.push(cr.product.id),
          );
        }
      }
      return options.filter(o => {
        return !parsedIds.includes(o.id);
      });
    }
    return [];
  };

  return (
    <Modal
      visible={isVisible}
      title="Selecionar produto"
      closable={false}
      maskClosable={false}
      okText="Selecionar"
      okButtonProps={{
        htmlType: 'submit',
      }}
      cancelButtonProps={{}}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch(info => {
            console.log('Validate Failed: ', info);
          });
      }}
      onCancel={() => {
        onReset();
        onCancel && onCancel();
      }}
    >
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Form.Item
          name="products"
          label="Produtos"
          rules={[{ required: true }]}
        >
          <DebounceSelect
            labelInValue
            mode="multiple"
            placeholder="Selecione os produtos"
            showSearch
            fetchOptions={fetchProductsList}
            onChange={() => {
              removeCyclicOptions();
              buildTreeNodes();
            }}
            filterOptions={filterAlreadySelectedValues}
            style={{ width: '100%' }}
            labelProp="name"
            valueProp={false}
          />
        </Form.Item>
      </Form>

      {treeNodes && treeNodes.length ? (
        <>
          <Tree
            selectable={false}
            showLine
            switcherIcon={<DownOutlined />}
            defaultExpandAll={true}
            treeData={treeNodes}
          />
        </>
      ) : null}
    </Modal>
  );
};

export default SelectProduct;
