import React, { useState } from "react";
import {
  notification,
  Form,
  Modal,
  Input,
} from "antd";

import api from "../../../../api";
import { UserType } from "../../../../utils/enums";

const UsersNew: React.FC<{ isVisible?: boolean; onCreate?: () => void; onCancel?: () => void; }> = ({
  isVisible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {

      values.user_type = UserType.Admin;
      
      await api.post(`/user`, values);

      notification.success({
        message: "Usuário inserido com sucesso",
      });

      onReset();
      onCreate && onCreate();

      setLoading(false);
    } catch (error) {

      notification.error({
        message:
          "Ocorreu algum erro ao inserir o usuário. Tente novamente., " +
          error,
      });
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={isVisible}
      title="Adicionar usuário"
      closable={false}
      maskClosable={false}

      okText="Adicionar"
      okButtonProps={{
        htmlType: 'submit',
        disabled: loading,
        loading: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch(info => {
            console.log('Validate Failed: ', info);
          });
      }}
      onCancel={() => { onReset(); onCancel && onCancel(); }}
    >
      <Form autoComplete="off" layout="vertical" form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item name="name" label="Nome" rules={[{ required: true, max: 512, min: 2 }]}>
          <Input placeholder='Digite o nome do usuário' />
        </Form.Item>
        <Form.Item name="email" label="E-mail" rules={[{ required: true, type: 'email' }]}>
          <Input type={'email'} placeholder='Digite o e-mail do usuário' />
        </Form.Item>
        <Form.Item name="password" label="Senha" rules={[{ required: true, min: 8, max: 24 }]}>
          <Input.Password autoComplete="new-password" placeholder='Digite a senha do usuário' />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UsersNew;
