import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Table,
  TableColumnType,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";

import api from "../../../api";
import { buildFilterAttrs } from "../../../utils/filters";
import RegulationsDetails from "./RegulationsDetails";
import RegulationsNew from "./RegulationsNew";
import { Container } from "./styles";

const defaultPageSize = 10;

const Regulations: React.FC = () => {
  const [shouldReloadTable, setShouldReloadTable] = useState(false);
  const [inserting, setInserting] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [paginationData, setpaginationData] = useState({
    current: 1,
    pageSize: defaultPageSize,
    showSizeChanger: true,
    total: 0,
    data: [],
  });
  const [detailsOpened, setDetailsOpened] = useState<any>(null);

  const loadData = useCallback(async (params: any) => {
    setTableLoading(true);

    const { current, pageSize, sortField, sortOrder, filters } = params;

    try {
      const { data } = await api.get("/regulation", {
        params: {
          page: current,
          pageSize: pageSize,
          offset: (current - 1) * pageSize,
          ...(filters ? { filters } : {}),
          ...(sortField ? { order_by: sortField } : {}),
          ...(sortOrder ? { sort_by: sortOrder } : {}),
        },
      });

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setTableLoading(false);
    }
  }, []);

  const onHandleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (!pagination) return;

    let newFilters: any = {};
    for (const key in filters) {
      if (filters[key] === null) continue;
      const value = filters[key];

      if (value.length > 1) {
        newFilters[key] = value;
        continue;
      }

      newFilters[key] = value[0];
    }

    loadData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...pagination,
      filters: newFilters,
    })
      .then((response) => {
        setpaginationData((old) => ({
          ...old,
          ...pagination,
          total: response.total,
          data: response.data,
        }));
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));
  };

  const onHandleReloadData = async (currentPage: number) => {
    try {
      const response = await loadData({
        current: currentPage || paginationData.current,
        pageSize: paginationData.pageSize,
      });

      setpaginationData((old) => ({
        ...old,
        total: response.total,
        data: response.data,
      }));

      const newPage = Math.ceil(response.total / paginationData.pageSize);

      setShouldReloadTable(!shouldReloadTable);

      setpaginationData((old) => ({
        ...old,
        current: newPage,
      }));
    } catch (error) {
      notification.error({ message: "Erro ao carregar dados!" });
    }
  };

  const onHandleOpenMoreDetails = async (row: Partial<any>) => {
    setDetailsOpened(row);
  };

  const onHandleAddRegulation = async () => {
    const currentPage = paginationData.current || 1;

    try {
      const response = await loadData({
        current: currentPage,
        pageSize: paginationData.pageSize,
      });

      const totalItems = response.total;

      const newPage = Math.ceil(totalItems / paginationData.pageSize);

      setInserting(false);
      setpaginationData((old) => ({
        ...old,
        current: newPage,
        total: totalItems,
        data: response.data,
      }));

      setShouldReloadTable(true);
    } catch (error) {
      notification.error({ message: "Erro ao carregar dados!" });
    }
  };

  const reloadTableData = async (currentPage: number) => {
    try {
      const response = await loadData({
        current: currentPage,
        pageSize: paginationData.pageSize,
      });

      setpaginationData((old) => ({
        ...old,
        total: response.total,
        data: response.data,
      }));
    } catch (error) {
      notification.error({ message: "Erro ao carregar dados!" });
    }
  };

  const onHandleReloadButtonClick = () => {
    reloadTableData(paginationData.current);
  };

  useEffect(() => {
    if (shouldReloadTable) {
      onHandleReloadData(paginationData.current);
      setShouldReloadTable(false);
    }
  }, [shouldReloadTable, paginationData.current]);
  const handleDelete = useCallback(
    async (id: number, currentPage: number) => {
      try {
        await api.delete(`/regulation/${id}/`);
        notification.success({
          message: "Regulamento deletado com sucesso",
        });

        setDetailsOpened(null);

        const remainingItems = paginationData.total - 1;

        const newMaxPage = Math.ceil(remainingItems / paginationData.pageSize);

        const newCurrentPage = Math.min(currentPage, newMaxPage);

        setpaginationData((old) => ({
          ...old,
          current: newCurrentPage,
          total: remainingItems,
        }));

        await reloadTableData(newCurrentPage);
      } catch (error) {
        notification.error({
          message:
            "Ocorreu algum erro ao deletar o regulamento. Tente novamente., " +
            error,
        });
      }
    },
    [
      api,
      reloadTableData,
      setDetailsOpened,
      setpaginationData,
      paginationData.total,
      paginationData.pageSize,
    ]
  );

  useEffect(() => {
    let didCancel = false;

    loadData({
      current: paginationData.current,
      pageSize: defaultPageSize,
    })
      .then((response) => {
        !didCancel &&
          setpaginationData((old) => ({
            ...old,
            total: response.total,
            data: response.data,
          }));
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, []);

  const tableCols: TableColumnType<any>[] = [
    {
      key: "id",
      title: "#",
      dataIndex: "id",
      width: "70px",
      sorter: true,
      ...buildFilterAttrs({
        searchLabel: "Código",
      }),
    },
    {
      key: "name",
      title: "Nome",
      dataIndex: "name",
      sorter: true,
      ...buildFilterAttrs({
        searchLabel: "Nome",
      }),
    },
    {
      key: "description",
      title: "Descrição",
      dataIndex: "description",
      width: "40%",
      ...buildFilterAttrs({
        searchLabel: "Descrição",
      }),
    },
    {
      key: "file_link",
      title: "Ver documento",
      dataIndex: "file_link",
      width: "200px",
      render: (value) => (
        <>
          {value ? (
            <a href={value} target="_blank" rel="noreferrer">
              Abrir documento!
            </a>
          ) : (
            "Sem documento anexado!"
          )}
        </>
      ),
    },
    {
      key: "view",
      title: "Visualizar",
      width: "100px",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            key="bt-view"
            size="small"
            onClick={() => onHandleOpenMoreDetails(record)}
          >
            Detalhes
          </Button>
        );
      },
    },
    {
      key: "actions",
      title: "Ações",
      width: "100px",
      align: "center",
      render: (_, record) => {
        return (
          <Popconfirm
            title="Tem certeza, que deseja deletar o regulamento?!"
            okType="default"
            onConfirm={async () =>
              await handleDelete(record.id, paginationData.current)
            }
            okText="Deletar"
            cancelText="Cancelar"
          >
            <Button
              key="bt-prod-delete"
              size="small"
              color="danger"
              danger
              type="primary"
            >
              Deletar
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <Container>
      <RegulationsNew
        isVisible={inserting}
        onCancel={() => {
          setInserting(false);
        }}
        onAddRegulation={onHandleAddRegulation}
      />
      <PageHeader
        title="Regulamentos"
        subTitle=""
        extra={[
          <Button
            key="bt-ds-reload"
            icon={<ReloadOutlined />}
            onClick={onHandleReloadButtonClick}
          >
            Recarregar dados
          </Button>,
          <Button
            key="bt-ds-new"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setInserting(true);
            }}
          >
            Novo
          </Button>,
        ]}
      />
      <Row style={{ marginTop: 8 }}>
        <Col md={24}>
          <Table
            style={{ padding: "0 24px" }}
            size="middle"
            rowKey={(record: any) => record.id}
            dataSource={paginationData.data}
            columns={tableCols}
            loading={tableLoading}
            pagination={paginationData}
            onChange={onHandleTableChange}
          />
        </Col>
      </Row>
      {detailsOpened ? (
        <RegulationsDetails
          details={detailsOpened}
          onCancel={() => setDetailsOpened(null)}
          onDataLoading={(v) => setTableLoading(v)}
          onDelete={(id, currentPage) => {
            handleDelete(id, currentPage);
            setDetailsOpened(null);
          }}
          onDeleteLoading={(v) => setTableLoading(v)}
          onEditLoading={(v) => setTableLoading(v)}
          paginationData={paginationData}
        />
      ) : null}
    </Container>
  );
};

export default Regulations;
