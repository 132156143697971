import styled from 'styled-components';




export const BoxFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 90px;
  width: 100%;
`;

export const ButtonsFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
`;
