import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Table,
  TableColumnType,
  Tag,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../../api";
import FranchisesZoneNew from "./ZoneNew";
import { Container } from "./styles";
import { useAuth } from "../../../../providers/Auth";
import { UserType } from "../../../../utils/enums";
import CheckPermission from "../../../../components/CheckPermission";

const defaultPageSize = 10;

const FranchisesZone: React.FC<{ franchiseId?: string }> = ({
  franchiseId: franchiseIdStatic,
}) => {
  const history = useHistory();

  const { franchisee_id: franchiseIdLogin, userTypeIs, checkPermission } = useAuth();
  const { franchiseId: franchiseIdDynamic } =
    useParams<{ franchiseId: string }>();

  const [franchiseId] = useState(
    franchiseIdStatic || franchiseIdDynamic || franchiseIdLogin
  );

  const [userTypeIsFranchisee] = useState(userTypeIs(UserType.Franchisee));

  const [shouldReloadTable, setShouldReloadTable] = useState(false);
  const [inserting, setInserting] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: defaultPageSize,
    showSizeChanger: true,
  });

  const loadData = useCallback(
    async (params: any) => {
      setTableLoading(true);

      const { current, pageSize, sortField, sortOrder, filters } = params;

      try {
        const { data } = await api.get(`/franchisees/${franchiseId}/zone`, {
          params: {
            page: current,
            pageSize: pageSize,
            offset: (current - 1) * pageSize,
            ...(filters ? { filters } : {}),
            ...(sortField ? { order_by: sortField } : {}),
            ...(sortOrder ? { sort_by: sortOrder } : {}),
          },
        });

        return data;
      } catch (error) {
        throw new Error("Erro ao carregar dados! " + error);
      } finally {
        setTableLoading(false);
      }
    },
    [franchiseId]
  );

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        await api.delete(`/franchisees/${franchiseId}/zone/${id}/`);
        notification.success({
          message: "Zona deletada com sucesso",
        });

        setShouldReloadTable((oldState) => !oldState);
      } catch (error) {
        notification.error({
          message:
            "Ocorreu algum erro ao deletar a zona. Tente novamente., " + error,
        });
      }
    },
    [franchiseId]
  );

  const onHandleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (!pagination) return;

    loadData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...pagination,
      ...filters,
    })
      .then((response) => {
        setTablePagination((old) => ({
          ...old,
          ...pagination,
          total: response.total,
        }));
        setData(response.data);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));
  };

  const onHandleOpenMoreDetails = async (row: Partial<any>) => {
    history.push(`${!userTypeIsFranchisee ? `/franchise/${franchiseId}/` : 'franchisee-'}zone/${row.id}`);
  };

  const onHandleReloadData = () => setShouldReloadTable(!shouldReloadTable);

  const tableCols: TableColumnType<any>[] = [
    {
      key: "id",
      title: "#",
      dataIndex: "id",
      width: "70px",
      sorter: true,
    },
    {
      key: "city",
      title: "Cidade",
      dataIndex: "city",
      sorter: false,
      render: (_, row) => row.city.name ? `${row.city.name} / ${row.city.uf}` : "-",
    },
    {
      key: "district",
      title: "Bairro",
      dataIndex: "district",
      sorter: true,
    },
    {
      key: "zone",
      title: "Zona",
      dataIndex: "zone",
      sorter: true,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sorter: true,
      width: "150px",
      align: "center",
      render: (value) => (
        <Tag color={value === true ? "green" : "volcano"}>
          {value === true ? "Ativada" : "Desativada"}
        </Tag>
      ),
    },
    {
      key: "view",
      title: "Visualizar",
      width: "100px",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            key="bt-view"
            size="small"
            onClick={() => onHandleOpenMoreDetails(record)}
          >
            Detalhes
          </Button>
        );
      },
    },
  ];

  if (checkPermission(UserType.Admin)) {
    tableCols.push({
      key: "actions",
      title: "Ações",
      width: "100px",
      align: "center",
      render: (_, record) => {
        return (
          <Popconfirm
            title="Tem certeza, que deseja deletar a zona?!"
            okType="default"
            onConfirm={async () => await handleDelete(record.id)}
            okText="Deletar"
            cancelText="Cancelar"
          >
            <Button
              key="bt-prod-delete"
              size="small"
              color="danger"
              danger
              type="primary"
            >
              Deletar
            </Button>
          </Popconfirm>
        );
      },
    },);
  }

  useEffect(() => {
    let didCancel = false;

    loadData({
      current: 1,
      pageSize: defaultPageSize,
    })
      .then((response) => {
        !didCancel && setData(response.data);
        setTablePagination((old) => ({ ...old, total: response.total }));
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [loadData, shouldReloadTable]);

  return (
    <Container>
      {inserting ? (
        <FranchisesZoneNew
          isVisible={inserting}
          onCancel={() => {
            setInserting(false);
          }}
          onCreate={() => {
            setInserting(false);
            onHandleReloadData();
          }}
        />
      ) : null}

      <PageHeader
        title={franchiseIdStatic ? null : "Zonas"}
        subTitle=""
        extra={[
          <Button
            key="bt-ds-reload"
            icon={<ReloadOutlined />}
            onClick={onHandleReloadData}
          >
            Recarregar dados
          </Button>,
          <CheckPermission key="bt-ds-new-container" userType={UserType.Admin}>
            <Button
              key="bt-ds-new"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setInserting(true)}
            >
              Novo
            </Button>
          </CheckPermission>,
        ]}
      >
        <Row style={{ marginTop: 12 }}>
          <Col md={24}>
            <Table
              size="middle"
              rowKey={(record: any) => record.id}
              dataSource={data}
              columns={tableCols}
              loading={tableLoading}
              pagination={tablePagination}
              onChange={onHandleTableChange}
            />
          </Col>
        </Row>
      </PageHeader>
    </Container>
  );
};

export default FranchisesZone;
