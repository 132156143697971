import React, { useEffect, useState } from "react";

import { Button, Card, Popconfirm, Space, Typography } from 'antd';

import {
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

import SelectProduct from './SelectProduct';

import {
  Container,
  KanbanColumn,
  KanbanColumnContent,
  KanbanColumnHeader,
  KanbanContainer,
} from './styles';
import { deepCopyFunction, hash, hexToRGB } from "../../../../../../utils/functions";

const CombosNewProducts: React.FC<{
  products: any[],
  onNext: (values: any) => void;
  onPrevius: (values: any) => void;
  onRemoved: (prdt: any) => void;
  onInserted: (prdts: any[]) => void;
  onChanged: (prdts: any[]) => void;
  onCancel: () => void;
}> = ({
  products: productsInitial,
  onNext,
  onPrevius,
  onInserted,
  onRemoved,
  onChanged,
  onCancel,
}) => {
    const [products, setProducts] = useState<any[]>([]);
    const [selecting, setSelecting] = useState(false);

    const handleInsert = (product: any[]) => {
      setSelecting(false);
      onInserted(product);

      const clonedProducts = deepCopyFunction((products || []));
      const newPrdts: any[] = [
        ...(Array.isArray(product) ? product : [product]),
      ];

      const alreadyInRules: string[] = [];
      const newDependsOnRule: { [x: string]: any } = deepCopyFunction(clonedProducts).filter((v: any) => !!v.join_with)
        .reduce((o: any, v: any) => {
          alreadyInRules.push(v.id);
          return {
            ...o,
            [v.id]: Array.isArray(o[v.id]) ? [...o[v.id], ...v.join_with] : [...v.join_with],
          };
        }, {});

      for (let i = 0, l = newPrdts.length; i < l; i++) {
        if (newPrdts[i].combo_rules) {
          newPrdts[i].rule = {
            min: 1,
            max: 1,
            product: (() => {
              const clone = deepCopyFunction(newPrdts[i]);
              delete clone['rule'];
              return clone;
            })(),
          };
          newPrdts[i].combo_rules.forEach((rule: any) => {
            if (rule.product.id) {
              if (!newDependsOnRule[rule.product.id]) {
                newDependsOnRule[rule.product.id] = [];
              }
              const rulePrdt = {
                ...rule.product,
                depends_on: newPrdts[i],
                rule: rule
              };
              newDependsOnRule[rule.product.id].push(rulePrdt);
            }
          });
        }
      }

      const mergedPrds: any[] = [];

      for (const prdtId in newDependsOnRule) {
        if (prdtId in newDependsOnRule) {
          const prdts = newDependsOnRule[prdtId];
          if (prdts && prdts.length) {
            const first = prdts[0];
            if (first) {
              const { id, name } = first;
              let rule!: any;
              for (let i = 0, l = prdts.length; i < l; i++) {
                if (!i) {
                  rule = { ...prdts[i].rule };
                } else {
                  rule.min += prdts[i].rule.min || 0;
                  rule.max += prdts[i].rule.max || 0;
                }
              }
              const model = {
                id,
                name,
                rule,
                join_with: newDependsOnRule[prdtId],
              };

              mergedPrds.push(model);
            }
          }
        }
      }

      const nwVls = [
        ...(!alreadyInRules.length ? clonedProducts : clonedProducts.filter((v: any) => !alreadyInRules.includes(v.id))),
        ...newPrdts,
        ...mergedPrds,
      ];
      setProducts(nwVls);
      onChanged(nwVls);
    };

    const handleDelete = (index: number) => {
      if (index in products) {
        let pdrts = products.slice(0);
        const removed: any = pdrts.splice(index, 1)[0];

        const removeAllPrdcs: string[] = [];

        for (let i = 0, l = pdrts.length; i < l; i++) {
          const pdrt = pdrts[i];
          if (pdrt.join_with && pdrt.join_with.length) {
            const index = (pdrt.join_with as any[]).findIndex((v) => v.depends_on.id === removed.id);
            if (index >= 0) {
              pdrt.join_with.splice(index, 1);
              if (!pdrt.join_with.length) {
                removeAllPrdcs.push(pdrt.id);
              } else {
                let rule!: any;
                // recalculate
                for (let io = 0, lo = pdrt.join_with.length; io < lo; io++) {
                  if (!io) {
                    ;
                    rule = { ...pdrt.join_with[io].rule };
                  } else {
                    rule.min += pdrt.join_with[io].rule.min || 0;
                    rule.max += pdrt.join_with[io].rule.max || 0;
                  }
                }
                pdrt.rule = rule;
              }
            }
          }
        }
        if (removeAllPrdcs) {
          pdrts = pdrts.filter((p) => !removeAllPrdcs.includes(p.id));
        }

        onRemoved([removed]);
        setProducts(pdrts);
        onChanged(pdrts);
      }
    };

    useEffect(() => {
      let didCancel = false;
      !didCancel && setProducts(productsInitial || []);

      return () => {
        didCancel = true;
      };
    }, [productsInitial])

    return (
      <Container>
        <SelectProduct
          isVisible={selecting}
          products={products}
          onCancel={() => {
            setSelecting(false);
          }}
          onSelect={(product) => handleInsert(product)}
        />

        <Space direction="vertical" style={{ width: `100%` }}>
          <KanbanContainer>
            <Space align="start">
              <KanbanColumn>
                <KanbanColumnHeader>
                  <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    Produtos
                  </Typography.Title>
                </KanbanColumnHeader>
                <KanbanColumnContent>
                  <Space direction="vertical" style={{ width: `100%` }}>
                    {products.map((prdt, idx) => {

                      return (
                        <Card key={prdt.id} className={(prdt.join_with && prdt.join_with.length) && 'disabled-hover depends_on-active'} style={{ background: hexToRGB(prdt.base_color || `#FFF`, .05) }}>
                          <div style={{ display: `flex`, justifyContent: `space-between`, alignItems: `center` }}>
                            <div style={{ width: `100%` }}>
                              <Typography.Text>
                                #{prdt.id} - {prdt.name}
                              </Typography.Text>
                              <br />
                              {(prdt.join_with && prdt.join_with.length) ? prdt.join_with.map((p: any) => (
                                <div key={hash()}>
                                  <Typography.Text className={'depends_on-text'} strong underline type={"secondary"}>
                                    Produto atrelado a: #{p.depends_on.id} - {p.depends_on.name}
                                  </Typography.Text>
                                  <br />
                                </div>
                              )) : null}
                            </div>
                            <div style={{ alignSelf: `flex-start` }}>
                              {!(prdt.join_with && prdt.join_with.length) && (
                                <Popconfirm
                                  title="Tem certeza que deseja remover este produto?!"
                                  onConfirm={() => handleDelete(idx)}
                                  okText="Sim"
                                  cancelText="Não"
                                >
                                  <Button shape="circle" icon={<DeleteOutlined />} />
                                </Popconfirm>
                              )}
                            </div>
                          </div>
                        </Card>
                      );
                    })}
                    <Card onClick={() => setSelecting(true)}>
                      <Typography.Link>
                        <PlusOutlined /> Adicionar produto
                      </Typography.Link>
                    </Card>
                  </Space>
                </KanbanColumnContent>
              </KanbanColumn>
            </Space>
          </KanbanContainer>

          <div style={{ display: `flex`, justifyContent: `flex-end` }}>
            <Space>
              <Button onClick={() => onCancel()}>
                Cancelar
              </Button>
              <Button onClick={() => onPrevius(products)}>
                Voltar
              </Button>
              <Button type="primary" disabled={!products.length} onClick={() => onNext(products)}>
                Próximo
              </Button>
            </Space>
          </div>
        </Space>
      </Container>
    );
  };

export default CombosNewProducts;
