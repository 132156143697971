import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tabs,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import api from "../../../../api";
import { formatPrice, hash } from "../../../../utils/functions";
import parse from "../../../../utils/parse";
import CombosEdit from "./CombosEdit";
import {
  Container,
  ContainerLoading,
  ProductsAndOffersList,
  ProductsAndOffersListOItem,
  ProductsAndOffersListPItem,
} from "./styles";

const CombosTemplateAbout: React.FC<{}> = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const history = useHistory();

  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const [originalData, setOriginalData] = useState<any>({});
  const [products, setProducts] = useState<any[]>([]);
  const [offers, setOffers] = useState<{ [x: string]: any[] }>({});
  const [loading, setLoading] = useState(false);
  const [totalSumPrice, setTotalSumPrice] = useState(0);
  const [treeNodes, setTreeNodes] = useState<any[]>([]);

  const buildTreeNodes = (
    prdts: any[] = products,
    ofrs: { [x: string]: any[] } = offers
  ) => {
    let price = 0;
    setTotalSumPrice(0);
    setTreeNodes(null as unknown as any[]);
    setTimeout(() => {
      const treeNodes = prdts.map((p) => {
        const children: any[] = [];

        if (p.allowFranchiseSelect) {
          children.push({
            key: `${hash()}`,
            expanded: true,
            title: `As ofertas para este produto serão selecionadas pelo o franqueado!`,
          });
        } else {
          const po = ofrs[p.id] || [];
          if (!po.length) {
            children.push({
              key: `${hash()}`,
              expanded: true,
              title: `Não há ofertas selecionadas para este produto!`,
            });
          } else {
            po.forEach((o) => {
              price += +`${o.price || 0}`;
              children.push({
                key: `${hash()}`,
                expanded: true,
                title: `#${o.id} - ${o.title}`,
                price: formatPrice(o.price),
              });
            });
          }
        }

        return {
          key: `${hash()}`,
          title: `#${p.id} - ${p.name}`,
          children,
          expanded: true,
        };
      });

      setTotalSumPrice(price);
      setTreeNodes(treeNodes);
    });
  };

  const loadInfo = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/combo/${pageId}/`);

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setLoading(false);
    }
  }, [pageId]);

  const handleDelete = useCallback(async () => {
    setIsDeletingLoading(true);
    try {
      await api.delete(`/combo/${pageId}/`);
      notification.success({
        message: "Combo deletado com sucesso",
      });

      setData(null);

      history.goBack();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao deletar o combo. Tente novamente., " + error,
      });
    } finally {
      setIsDeletingLoading(false);
    }
  }, [pageId, history]);

  const onFinish = async (values: any) => {
    const data = {
      ...originalData,
      ...values,
    };
    setOriginalData(data);
    setData(data);
    parse(data, (v) => {
      const { products: parsedProducts = [], offers: parsedOffers = {} } = v;
      setProducts(parsedProducts);
      setOffers(parsedOffers);

      Promise.resolve().then(() =>
        setTimeout(buildTreeNodes, 0, parsedProducts, parsedOffers)
      );

      Promise.resolve().then(() => {
        onCancel(false);
      });
    });
  };

  const onCancel = (doParse: boolean = true) => {
    setIsEditing(false);
    Promise.resolve().then(() => {
      setData(originalData);
      form.setFieldsValue(originalData);
      if (doParse) {
        parse(originalData, (v) => {
          const { products: parsedProducts = [], offers: parsedOffers = {} } =
            v;
          setProducts(parsedProducts);
          setOffers(parsedOffers);

          Promise.resolve().then(() =>
            setTimeout(buildTreeNodes, 0, parsedProducts, parsedOffers)
          );
        });
      }
    });
  };

  useEffect(() => {
    let didCancel = false;

    loadInfo()
      .then((response) => {
        if (!didCancel) {
          setOriginalData(response);
          setData(response);
          form.setFieldsValue(response);
          parse(response, (v) => {
            const { products: parsedProducts = [], offers: parsedOffers = {} } =
              v;
            setProducts(parsedProducts);
            setOffers(parsedOffers);

            Promise.resolve().then(() =>
              setTimeout(buildTreeNodes, 0, parsedProducts, parsedOffers)
            );
          });
        }
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));
    // .catch((e) => console.log(e));

    return () => {
      didCancel = true;
    };
  }, [form, loadInfo]); // No 'details' dep. is needed

  const loadingTemplate = (
    <ContainerLoading>
      <Spin></Spin>
    </ContainerLoading>
  );

  const loadedTemplate = (
    <PageHeader
      title="Dados do template de combo"
      onBack={() => history.goBack()}
    >
      {!isEditing ? (
        <Tabs defaultActiveKey="about">
          <Tabs.TabPane tab="Dados gerais" key="about">
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              autoComplete="off"
            >
              <Row gutter={16}>
                <Col md={8}>
                  <Form.Item
                    name="name"
                    label="Nome"
                    rules={[{ required: true, max: 512, min: 2 }]}
                  >
                    <Input
                      placeholder="Digite o nome do combo"
                      disabled={true}
                    />
                  </Form.Item>

                  <Form.Item name="published" valuePropName="checked">
                    <Checkbox disabled={true}>Publicado?</Checkbox>
                  </Form.Item>
                </Col>
                <Col md={12} style={{ display: "flex" }}>
                  <Divider type="vertical" style={{ height: "100%" }} />
                  <div>
                    <Typography.Title level={3}>
                      Produtos e ofertas
                    </Typography.Title>

                    <ProductsAndOffersList>
                      {treeNodes &&
                        treeNodes.map((p) => {
                          return (
                            <ProductsAndOffersListPItem key={p.key}>
                              <div>{p.title}</div>

                              {((p.children || []) as any[]).map((o) => {
                                return (
                                  <ProductsAndOffersListOItem key={o.key}>
                                    <div>
                                      {o.title} <br />
                                      {o.price ? (
                                        <Typography.Text type="secondary">
                                          Preço: {o.price}
                                        </Typography.Text>
                                      ) : null}
                                    </div>
                                  </ProductsAndOffersListOItem>
                                );
                              })}
                            </ProductsAndOffersListPItem>
                          );
                        })}
                    </ProductsAndOffersList>

                    <Divider />

                    <div>
                      <Typography.Title level={4}>
                        Soma total dos valores das ofertas:
                      </Typography.Title>
                      <Typography.Text
                        type="secondary"
                        strong
                        style={{ fontSize: 18 }}
                      >
                        {formatPrice(totalSumPrice || 0)}
                      </Typography.Text>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>

            <Row justify="end">
              {!isEditing ? (
                <Space>
                  <Popconfirm
                    title="Tem certeza, que deseja deletar o combo?!"
                    okType="default"
                    onConfirm={async () => await handleDelete()}
                    okText="Deletar"
                    cancelText="Cancelar"
                  >
                    <Button
                      key="bt-page-delete"
                      color="danger"
                      danger
                      type="primary"
                    >
                      Deletar
                    </Button>
                  </Popconfirm>

                  <Button
                    key={"edit-page"}
                    disabled={isDeletingLoading}
                    onClick={() => setIsEditing(true)}
                    type="primary"
                    htmlType="button"
                  >
                    Editar
                  </Button>
                </Space>
              ) : null}
            </Row>
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <CombosEdit
          registerId={originalData.id}
          formData={data}
          products={products}
          offers={offers}
          onCancel={onCancel}
          onFinished={onFinish}
        />
      )}
    </PageHeader>
  );

  return <Container>{loading ? loadingTemplate : loadedTemplate}</Container>;
};

export default CombosTemplateAbout;
