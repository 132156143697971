import React, { useCallback, useEffect, useState } from "react";
import {
  notification,
  Form,
  Modal,
  Input,
  Select,
} from "antd";


import api from "../../../../../../api";
import { useParams } from "react-router-dom";


const { Option } = Select;


const ZoneNew: React.FC<{ franchisorLeadId?: string; isVisible?: boolean; onCreate?: () => void; onCancel?: () => void; }> = ({
  isVisible,
  onCreate,
  onCancel,
  franchisorLeadId: franchisorLeadIdStatic,
}) => {
  const { franchisorLeadId: franchisorLeadIdDynamic } = useParams<{ franchisorLeadId: string; }>();

  const franchisorLeadId = franchisorLeadIdStatic || franchisorLeadIdDynamic;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [loadingCities, setLoadingCities] = useState(false);
  const [cities, setCities] = useState<any[]>([]);

  const loadCities = useCallback(async () => {
    setLoadingCities(true);
    try {
      const { data } = await api.get("/cities", {
        params: {
          pageSize: 999,
          offset: 0,
        },
      });

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setLoadingCities(false);
    }
  }, []);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {

      if ('city_id' in values && typeof values.city_id === 'string') {
        try {
          values.city_id = +values.city_id;
        } catch (_) { }
      }

      await api.post(`/franchisor-lead/${franchisorLeadId}/zone`, values);

      notification.success({
        message: "Zona de interesse inserida com sucesso",
      });

      onReset();
      onCreate && onCreate();

      setLoading(false);
    } catch (error) {

      notification.error({
        message:
          "Ocorreu algum erro ao inserir a zona de interesse. Tente novamente., " +
          error,
      });
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    let didCancel = false;

    loadCities()
      .then((response) => {
        !didCancel && setCities(response.data);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [loadCities]);

  return (
    <Modal
      visible={isVisible}
      title="Adicionar zona de interesse"
      closable={false}
      maskClosable={false}

      okText="Adicionar"
      okButtonProps={{
        htmlType: 'submit',
        disabled: loading,
        loading: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch(info => {
            console.log('Validate Failed: ', info);
          });
      }}
      onCancel={() => { onReset(); onCancel && onCancel(); }}
    >
      <Form layout="vertical" form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item name="city_id" label="Cidade" rules={[{ required: true, }]}>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Selecione uma cidade"
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            loading={loadingCities}
          >
            {cities.map((item, index) => {
              return <Option key={`cities-${index}`} value={item.id}>{item.name}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item name="district" label="Bairro" rules={[{ required: true, max: 512, min: 2 }]}>
          <Input placeholder='Digite o bairro' />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ZoneNew;
