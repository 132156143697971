import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Table,
  TableColumnType,
  Tag,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../../api';
import { buildFilterAttrs } from '../../../utils/filters';
import { Container } from './styles';

const defaultPageSize = 10;

const CombosTemplate: React.FC = () => {
  const history = useHistory();

  const [shouldReloadTable, setShouldReloadTable] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: defaultPageSize,
    showSizeChanger: true,
  });

  const loadData = useCallback(async (params: any) => {
    setTableLoading(true);

    const { current, pageSize, sortField, sortOrder, filters } = params;

    try {
      const { data } = await api.get('/combo', {
        params: {
          page: current,
          pageSize: pageSize,
          offset: (current - 1) * pageSize,
          ...(filters ? { filters } : {}),
          ...(sortField ? { order_by: sortField } : {}),
          ...(sortOrder ? { sort_by: sortOrder } : {}),
        },
      });

      return data;
    } catch (error) {
      throw new Error('Erro ao carregar dados! ' + error);
    } finally {
      setTableLoading(false);
    }
  }, []);

  const handleDelete = useCallback(async (id: any) => {
    try {
      await api.delete(`/combo/${id}/`);
      notification.success({
        message: 'Combo deletado com sucesso',
      });

      setShouldReloadTable(oldState => !oldState);
    } catch (error) {
      notification.error({
        message:
          'Ocorreu algum erro ao deletar o combo. Tente novamente., ' + error,
      });
    }
  }, []);

  const onHandleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (!pagination) return;
    // console.log(filters);

    const newFilters: any = {};
    for (const key in filters) {
      if (filters[key] === null) continue;
      const value = filters[key];

      if (value.length > 1) {
        newFilters[key] = value;
        continue;
      }

      newFilters[key] = value[0];
    }

    loadData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...pagination,
      filters: newFilters,
    })
      .then(response => {
        setTablePagination(old => ({
          ...old,
          ...pagination,
          total: response.total,
        }));
        setData(response.data);
      })
      .catch(() => notification.error({ message: 'Erro ao carregar dados!' }));
  };

  const onHandleOpenMoreDetails = async (row: Partial<any>) => {
    history.push(`/combo-template/${row.id}`);
  };

  const onHandleReloadData = () => setShouldReloadTable(!shouldReloadTable);

  const tableCols: TableColumnType<any>[] = [
    {
      key: 'id',
      title: '#',
      dataIndex: 'id',
      width: '70px',
      sorter: true,
      ...buildFilterAttrs({
        searchLabel: 'Código',
      }),
    },
    {
      key: 'name',
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
      ...buildFilterAttrs({
        searchLabel: 'Nome',
      }),
    },
    {
      key: 'published',
      title: 'Publicado?',
      dataIndex: 'published',
      sorter: true,
      width: '150px',
      align: 'center',
      render: value => (
        <Tag color={value === true ? 'green' : 'volcano'}>
          {value === true ? 'Sim' : 'Não'}
        </Tag>
      ),
    },
    {
      key: 'view',
      title: 'Visualizar',
      width: '100px',
      align: 'center',
      render: (_, record) => {
        return (
          <Button
            key="bt-view"
            size="small"
            onClick={() => onHandleOpenMoreDetails(record)}
          >
            Detalhes
          </Button>
        );
      },
    },
    {
      key: 'actions',
      title: 'Ações',
      width: '100px',
      align: 'center',
      render: (_, record) => {
        return (
          <Popconfirm
            title="Tem certeza, que deseja deletar o combo?!"
            okType="default"
            onConfirm={async () => await handleDelete(record.id)}
            okText="Deletar"
            cancelText="Cancelar"
          >
            <Button
              key="bt-prod-delete"
              size="small"
              color="danger"
              danger
              type="primary"
            >
              Deletar
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  useEffect(() => {
    let didCancel = false;

    loadData({
      current: 1,
      pageSize: defaultPageSize,
    })
      .then(response => {
        !didCancel && setData(response.data);
        setTablePagination(old => ({ ...old, total: response.total }));
      })
      .catch(() => notification.error({ message: 'Erro ao carregar dados!' }));

    return () => {
      didCancel = true;
    };
  }, [loadData, shouldReloadTable]);

  return (
    <Container>
      <PageHeader
        title="Template de combos"
        subTitle=""
        extra={[
          <Button
            key="bt-ds-reload"
            icon={<ReloadOutlined />}
            onClick={onHandleReloadData}
          >
            Recarregar dados
          </Button>,
          <Button
            key="bt-ds-new"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => history.push(`/combos-template/new`)}
          >
            Novo
          </Button>,
        ]}
      >
        <Row style={{ marginTop: 12 }}>
          <Col md={24}>
            <Table
              size="middle"
              rowKey={(record: any) => record.id}
              dataSource={data}
              columns={tableCols}
              loading={tableLoading}
              pagination={tablePagination}
              onChange={onHandleTableChange}
            />
          </Col>
        </Row>
      </PageHeader>
    </Container>
  );
};

export default CombosTemplate;
