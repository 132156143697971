import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import React, { useState } from "react";

import FIELDS from "./../fields";

const FieldsProductRegister: React.FC<{ isReadonly?: boolean }> = ({
  isReadonly,
}) => {
  const [fieldTypeList] = useState(FIELDS);

  const renderValidators = (field: any, _prefixAccess?: any[]) => {
    return (
      <Form.Item noStyle shouldUpdate={() => true}>
        {(form) => {
          const prefixAccess = _prefixAccess || ["config", field.key];
          const fieldValue = form.getFieldValue([...prefixAccess, "type"]);
          const validatorsList: { label: string; value: any }[] =
            fieldTypeList.filter((i) => i.value === fieldValue)?.[0]
              ?.validators || [];

          if (fieldValue && validatorsList.length) {
            return (
              <Col md={12}>
                <Typography.Title type="secondary" level={5}>
                  Validadores:
                </Typography.Title>
                <Card>
                  <Form.List name={[field.key, "validators"]}>
                    {(
                      validatorsFields,
                      { add: addValidator, remove: removeValidator }
                    ) => (
                      <Space direction="vertical" style={{ width: "100%" }}>
                        {validatorsFields.map((fieldValidator) => (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <Row
                              gutter={15}
                              key={fieldValidator.key}
                              style={{ width: "100%" }}
                            >
                              <Col md={12}>
                                <Form.Item
                                  {...fieldValidator}
                                  label="Validador"
                                  name={[fieldValidator.name, "validator"]}
                                  fieldKey={[fieldValidator.key, "validator"]}
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    placeholder="Selecione o validador..."
                                    disabled={!!isReadonly}
                                  >
                                    {(validatorsList || []).map((item) => (
                                      <Select.Option
                                        value={item.value}
                                        key={item.value}
                                      >
                                        {item.label}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>

                              <Col md={12}>
                                <Form.Item noStyle shouldUpdate={() => true}>
                                  {(form) => {
                                    const prefixAccess = _prefixAccess || [
                                      "config",
                                      field.key,
                                    ];
                                    const validatorValue = form.getFieldValue([
                                      ...prefixAccess,
                                      "validators",
                                      fieldValidator.key,
                                      "validator",
                                    ]);
                                    if (
                                      ["min", "max"].includes(validatorValue)
                                    ) {
                                      return (
                                        <Form.Item
                                          {...fieldValidator}
                                          label="Valor do validador"
                                          name={[fieldValidator.name, "value"]}
                                          fieldKey={[
                                            fieldValidator.key,
                                            "value",
                                          ]}
                                          rules={[{ required: true }]}
                                        >
                                          <InputNumber
                                            style={{ width: "100%" }}
                                            min={1}
                                            placeholder="Digite o valor do validador"
                                            disabled={!!isReadonly}
                                          />
                                        </Form.Item>
                                      );
                                    }
                                    return null;
                                  }}
                                </Form.Item>
                              </Col>
                            </Row>

                            {!isReadonly ? (
                              <Form.Item
                                style={{
                                  marginBottom: 0,
                                  marginLeft: 10,
                                  marginTop: 5,
                                }}
                              >
                                <Tooltip title="Remover validador">
                                  <Button
                                    shape="circle"
                                    type="dashed"
                                    onClick={() =>
                                      removeValidator(fieldValidator.name)
                                    }
                                    block
                                    icon={<DeleteOutlined />}
                                  ></Button>
                                </Tooltip>
                              </Form.Item>
                            ) : null}
                          </div>
                        ))}

                        {!isReadonly ? (
                          <Form.Item style={{ marginBottom: 0 }}>
                            <Button
                              type="dashed"
                              onClick={() => addValidator()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Adicionar validador
                            </Button>
                          </Form.Item>
                        ) : null}
                      </Space>
                    )}
                  </Form.List>
                </Card>
              </Col>
            );
          }
          return null;
        }}
      </Form.Item>
    );
  };

  const renderConfigs = (field: any, _prefixAccess?: any[]) => {
    return (
      <Form.Item noStyle shouldUpdate={() => true}>
        {(form) => {
          const prefixAccess = _prefixAccess || ["config", field.key];
          const fieldValue = form.getFieldValue([...prefixAccess, "type"]);
          const configList =
            fieldTypeList.filter((i) => i.value === fieldValue)?.[0]?.configs ||
            [];

          if (configList.length) {
            return (
              <Col md={12} key={field.key}>
                <Typography.Title type="secondary" level={5}>
                  Configuração:
                </Typography.Title>
                <Card>
                  <Row gutter={15}>
                    <Form.Item noStyle shouldUpdate={() => true}>
                      {() => {
                        return configList.map((configItem, index) => {
                          let showFn = () => true;

                          if ("show" in configItem && configItem.show) {
                            // eslint-disable-next-line no-new-func
                            const _showFn = new Function(
                              "values",
                              `return !!(${configItem.show});`
                            );
                            showFn = () => {
                              const fieldValues = form.getFieldValue([
                                ...prefixAccess,
                              ]);
                              return _showFn(fieldValues);
                            };
                          }

                          if (showFn()) {
                            if (configItem.type === "select") {
                              return (
                                <Col md={configItem.size} key={index}>
                                  <Form.Item
                                    {...field}
                                    label={configItem.label}
                                    name={[field.name, configItem.key]}
                                    fieldKey={[field.key, configItem.key]}
                                    rules={configItem.rules || []}
                                  >
                                    <Select
                                      placeholder={configItem.placeholder}
                                      disabled={!!isReadonly}
                                    >
                                      {configItem.options.map((item: any) => (
                                        <Select.Option
                                          value={item.value}
                                          key={item.value}
                                        >
                                          {item.label}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              );
                            } else if (configItem.type === "text") {
                              return (
                                <Col md={configItem.size} key={index}>
                                  <Form.Item
                                    {...field}
                                    label={configItem.label}
                                    name={[field.name, configItem.key]}
                                    fieldKey={[field.key, configItem.key]}
                                    rules={configItem.rules || []}
                                  >
                                    <Input
                                      placeholder={configItem.placeholder}
                                      disabled={!!isReadonly}
                                    />
                                  </Form.Item>
                                </Col>
                              );
                            } else if (configItem.type === "list") {
                              return (
                                <Col md={configItem.size} key={index}>
                                  <Typography.Title type="secondary" level={5}>
                                    {configItem.label}:
                                  </Typography.Title>
                                  <Card>
                                    <Form.List
                                      name={[field.key, configItem.key]}
                                    >
                                      {(
                                        optionsFields,
                                        { add: addField, remove: removeField }
                                      ) => (
                                        <Space
                                          direction="vertical"
                                          style={{ width: "100%" }}
                                        >
                                          {optionsFields.map((fieldField) => (
                                            <div
                                              style={{
                                                display: "flex",
                                                width: "100%",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Row
                                                gutter={15}
                                                key={fieldField.key}
                                                style={{ width: "100%" }}
                                              >
                                                <Form.Item
                                                  noStyle
                                                  shouldUpdate={() => true}
                                                >
                                                  {() => {
                                                    return configItem.options.map(
                                                      (
                                                        optionItem: any,
                                                        index
                                                      ) => {
                                                        if (
                                                          optionItem.type ===
                                                          "select"
                                                        ) {
                                                          return (
                                                            <Col
                                                              md={
                                                                optionItem.size
                                                              }
                                                              key={
                                                                "options-" +
                                                                index
                                                              }
                                                            >
                                                              <Form.Item
                                                                {...fieldField}
                                                                label={
                                                                  optionItem.label
                                                                }
                                                                name={[
                                                                  fieldField.name,
                                                                  optionItem.key,
                                                                ]}
                                                                fieldKey={[
                                                                  fieldField.key,
                                                                  optionItem.key,
                                                                ]}
                                                                rules={
                                                                  optionItem.rules ||
                                                                  []
                                                                }
                                                              >
                                                                <Select
                                                                  placeholder={
                                                                    optionItem.placeholder
                                                                  }
                                                                  disabled={
                                                                    !!isReadonly
                                                                  }
                                                                >
                                                                  {optionItem.options.map(
                                                                    (
                                                                      item: any
                                                                    ) => (
                                                                      <Select.Option
                                                                        value={
                                                                          item.value
                                                                        }
                                                                        key={
                                                                          item.value
                                                                        }
                                                                      >
                                                                        {
                                                                          item.label
                                                                        }
                                                                      </Select.Option>
                                                                    )
                                                                  )}
                                                                </Select>
                                                              </Form.Item>
                                                            </Col>
                                                          );
                                                        } else if (
                                                          optionItem.type ===
                                                          "text"
                                                        ) {
                                                          return (
                                                            <Col
                                                              md={
                                                                optionItem.size
                                                              }
                                                              key={
                                                                "options-" +
                                                                index
                                                              }
                                                            >
                                                              <Form.Item
                                                                {...fieldField}
                                                                label={
                                                                  optionItem.label
                                                                }
                                                                name={[
                                                                  fieldField.name,
                                                                  optionItem.key,
                                                                ]}
                                                                fieldKey={[
                                                                  fieldField.key,
                                                                  optionItem.key,
                                                                ]}
                                                                rules={
                                                                  optionItem.rules ||
                                                                  []
                                                                }
                                                              >
                                                                <Input
                                                                  placeholder={
                                                                    optionItem.placeholder
                                                                  }
                                                                  disabled={
                                                                    !!isReadonly
                                                                  }
                                                                />
                                                              </Form.Item>
                                                            </Col>
                                                          );
                                                        }
                                                        return null;
                                                      }
                                                    );
                                                  }}
                                                </Form.Item>
                                              </Row>
                                              {!isReadonly ? (
                                                <Form.Item
                                                  style={{
                                                    marginBottom: 0,
                                                    marginLeft: 10,
                                                    marginTop: 5,
                                                  }}
                                                >
                                                  <Tooltip title="Remover opção">
                                                    <Button
                                                      shape="circle"
                                                      type="dashed"
                                                      onClick={() =>
                                                        removeField(
                                                          fieldField.name
                                                        )
                                                      }
                                                      block
                                                      icon={<DeleteOutlined />}
                                                    ></Button>
                                                  </Tooltip>
                                                </Form.Item>
                                              ) : null}
                                            </div>
                                          ))}

                                          {!isReadonly ? (
                                            <Form.Item
                                              style={{ marginBottom: 0 }}
                                            >
                                              <Button
                                                type="dashed"
                                                onClick={() => addField()}
                                                block
                                                icon={<PlusOutlined />}
                                              >
                                                Adicionar opção
                                              </Button>
                                            </Form.Item>
                                          ) : null}
                                        </Space>
                                      )}
                                    </Form.List>
                                  </Card>
                                </Col>
                              );
                            }
                          }
                          return null;
                        });
                      }}
                    </Form.Item>
                  </Row>
                </Card>
              </Col>
            );
          }
          return null;
        }}
      </Form.Item>
    );
  };

  const renderChildren = (field: any, _prefixAccess?: any[]) => {
    return (
      <Form.Item noStyle shouldUpdate={() => true}>
        {(form) => {
          const prefixAccess = _prefixAccess || ["config", field.key];
          const fieldValue = form.getFieldValue([...prefixAccess, "type"]);

          if (fieldValue === "list") {
            return (
              <Col md={24} key={field.key}>
                <Typography.Title type="secondary" level={5}>
                  Sub-campos:
                </Typography.Title>
                <Form.List name={[field.key, "children"]}>
                  {(fields, { add, remove }) => {
                    return (
                      <Space
                        key={"Some-list"}
                        direction="vertical"
                        style={{ width: "100%" }}
                      >
                        {renderFields(fields, remove, [
                          "config",
                          field.key,
                          "children",
                        ])}
                        {!isReadonly ? (
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Adicionar sub-campo
                            </Button>
                          </Form.Item>
                        ) : null}
                      </Space>
                    );
                  }}
                </Form.List>
              </Col>
            );
          }
          return null;
        }}
      </Form.Item>
    );
  };

  const renderFields = (
    fields: any[],
    remove: Function,
    _prefixAccess?: any[]
  ) => {
    return fields.map((field, index) => {
      let __prefixAccess!: any[];
      if (_prefixAccess?.length) {
        __prefixAccess = [..._prefixAccess, field.key];
      }

      return (
        <Card key={field.key} bodyStyle={{ paddingTop: 18 }}>
          <Space>
            <Typography.Title level={4}>
              {__prefixAccess?.length ? "Sub-campo" : "Metadado"} #{index + 1}
            </Typography.Title>

            {!isReadonly ? (
              <Tooltip
                title={
                  "Remover " +
                  (__prefixAccess?.length ? "sub-campo" : "metadado")
                }
              >
                <Button
                  type="dashed"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => remove(field.key)}
                  style={{ marginTop: -10 }}
                />
              </Tooltip>
            ) : null}
          </Space>

          <Row gutter={15}>
            <Col md={6}>
              <Form.Item
                {...field}
                label="Chave do campo"
                name={[field.name, "key"]}
                fieldKey={[field.key, "key"]}
                rules={[{ required: true, min: 2 }]}
              >
                <Input
                  placeholder="Ex.: chave_do_campo"
                  disabled={!!isReadonly}
                />
              </Form.Item>
            </Col>

            <Col md={6}>
              <Form.Item
                {...field}
                label="Tipo do campo"
                name={[field.name, "type"]}
                fieldKey={[field.key, "type"]}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Selecione o tipo..."
                  disabled={!!isReadonly}
                >
                  {(_prefixAccess?.length
                    ? fieldTypeList
                        .slice()
                        .filter((item) => item.value != "list")
                    : fieldTypeList
                  ).map((item) => (
                    <Select.Option value={item.value} key={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                {...field}
                label="Nome do campo"
                name={[field.name, "label"]}
                fieldKey={[field.key, "label"]}
                rules={[{ required: true, min: 2 }]}
              >
                <Input
                  placeholder="Ex.: Nome do campo"
                  disabled={!!isReadonly}
                />
              </Form.Item>
            </Col>

            <Col md={6}>
              <Form.Item shouldUpdate={() => true}>
                {(form) => {
                  return __prefixAccess?.length ? null : (
                    <Form.Item
                      {...field}
                      label="Mostrar quando"
                      name={[field.name, "disable_when"]}
                    >
                      <Select
                        placeholder="Selecionar campo..."
                        disabled={!!isReadonly}
                      >
                        {form.getFieldsValue() &&
                          form.getFieldsValue().config &&
                          ((form.getFieldsValue().config as any[]) || [])
                            .filter(
                              (formItem) =>
                                formItem && formItem.type === "checkbox"
                            )
                            .map((item: any) => (
                              <Select.Option key={item.key}>
                                {item.key}
                              </Select.Option>
                            ))}
                      </Select>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>

            <Form.Item noStyle shouldUpdate={() => true}>
              {(form) => {
                const prefixAccess = __prefixAccess || ["config", field.key];
                const fieldValue = form.getFieldValue([
                  ...prefixAccess,
                  "type",
                ]);
                if (fieldValue === "list") return null;

                return (
                  <Col md={6}>
                    <Form.Item
                      {...field}
                      label="Placeholder do campo"
                      name={[field.name, "placeholder"]}
                      fieldKey={[field.key, "placeholder"]}
                      rules={[{ required: true, min: 2 }]}
                    >
                      <Input
                        placeholder="Ex.: Digite o nome do campo"
                        disabled={!!isReadonly}
                      />
                    </Form.Item>
                  </Col>
                );
              }}
            </Form.Item>
          </Row>

          <Row gutter={15}>
            {renderValidators(field, __prefixAccess)}

            {renderConfigs(field, __prefixAccess)}

            {/* Non suport for children->children */}
            {__prefixAccess?.length ? null : renderChildren(field)}
          </Row>
        </Card>
      );
    });
  };

  return (
    <Row>
      <Col md={24}>
        <Form.List name={"config"}>
          {(fields, { add, remove }) => {
            return (
              <Space
                key={"Some-list"}
                direction="vertical"
                style={{ width: "100%" }}
              >
                {renderFields(fields, remove)}
                {!isReadonly ? (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Adicionar metadado
                    </Button>
                  </Form.Item>
                ) : null}
              </Space>
            );
          }}
        </Form.List>
      </Col>
    </Row>
  );
};

export default FieldsProductRegister;
