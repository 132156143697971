import { Checkbox, Form, Input, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useParams } from "react-router-dom";
import api from "../../../../../api";
import { useAuth } from "../../../../../providers/Auth";
import { documentCPF, phoneNumber } from "../../../../../utils/validators";

interface ClerkNewProps {
  isVisible?: boolean;
  franchiseId?: string;
  onCreate?: () => void;
  onCancel?: () => void;
}

const ClerkNew: React.FC<ClerkNewProps> = ({
  isVisible,
  onCreate,
  onCancel,
  franchiseId: staticFranchiseId,
}) => {
  const { franchisee_id } = useAuth();

  const { franchiseId: paramRouteFranchiseId } = useParams<{
    franchiseId: string;
  }>();

  const [franchiseId] = useState(
    staticFranchiseId || paramRouteFranchiseId || franchisee_id
  );

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await api.post(`/franchisees/${franchiseId}/attendant`, {
        ...values,
        status: values.status ? values.status : false,
      });

      notification.success({
        message: "Atendente inserido com sucesso",
      });
      onCreate && onCreate();

      setLoading(false);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao inserir o atendente. Tente novamente., " +
          error,
      });
      setLoading(false);
    }
  };

  useEffect(onReset, [onFinish]);

  return (
    <Modal
      visible={isVisible}
      title="Adicionar atendente"
      closable={false}
      maskClosable={false}
      okText="Adicionar"
      okButtonProps={{
        htmlType: "submit",
        disabled: loading,
        loading: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch((info) => {
            console.log("Validate Failed: ", info);
          });
      }}
      onCancel={() => {
        onReset();
        onCancel && onCancel();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="control-hooks"
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label="Nome Completo"
          rules={[{ required: true, max: 512, min: 3 }]}
        >
          <Input placeholder="Digite o nome do atendente" />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[{ required: true, type: "email" }]}
        >
          <Input type={"email"} placeholder="Digite o e-mail do atendente" />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label="Número"
          rules={[{ required: true }, { validator: phoneNumber }]}
        >
          <ReactInputMask mask="(99) \9 9999-9999">
            {(props: Partial<any>) => (
              <Input {...props} placeholder="(00) 9 0000-0000" />
            )}
          </ReactInputMask>
        </Form.Item>
        <Form.Item
          name="document"
          label="Documento"
          rules={[{ required: true }, { validator: documentCPF }]}
        >
          <ReactInputMask mask="999.999.999-99">
            {(props: Partial<any>) => (
              <Input {...props} placeholder="000.000.000-00" />
            )}
          </ReactInputMask>
        </Form.Item>
        <Form.Item
          name="password"
          label="Senha"
          rules={[{ required: true, min: 8, max: 24 }]}
        >
          <Input.Password placeholder="Digite a senha do atendente" />
        </Form.Item>
        <Form.Item name="status" valuePropName="checked">
          <Checkbox>Ativo?</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ClerkNew;
