import { PlusOutlined } from "@ant-design/icons";
import { Col, Row, Table, Button, PageHeader, TableColumnType } from "antd";
import ComboRulesNew from "./ComboRulesNew";
import React, { useEffect, useState } from "react";

const ComboRules: React.FC<{
  product: any;
  updateProduct: (newValues: any) => void;
}> = ({ product, updateProduct }) => {
  const [inserting, setInserting] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setTableLoading(true);
    setData(product.combo_rules || []);
    setTableLoading(false);
  }, [product.combo_rules]);

  const tableCols: TableColumnType<any>[] = [
    {
      key: "id",
      title: "#",
      dataIndex: "id",
      width: "70px",
      sorter: false,
      render: (_, row) => row.product.id,
    },
    {
      key: "product_name",
      title: "Produto",
      dataIndex: "product_name",
      sorter: false,
      render: (_, row) => row.product.name,
    },
    {
      key: "min",
      title: "Mínimo",
      dataIndex: "min",
      width: "70px",
      sorter: false,
      render: (_, row) => row.min,
    },
    {
      key: "max",
      title: "Máximo",
      dataIndex: "max",
      width: "70px",
      sorter: false,
      render: (_, row) => row.max,
    },
  ];

  return (
    <>
      <ComboRulesNew
        product={product}
        isVisible={inserting}
        onCancel={() => {
          setInserting(false);
        }}
        onCreate={(data) => {
          setInserting(false);
          setData(data?.combo_rules || []);
          updateProduct(data);
        }}
      />

      <PageHeader
        title=""
        subTitle=""
        extra={[
          <Button
            key="bt-ds-new"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setInserting(true)}
          >
            Novo
          </Button>,
        ]}
      >
        <Row style={{ marginTop: 12 }}>
          <Col md={24}>
            <Table
              size="middle"
              rowKey={(record: any) =>
                `${record.max}-${record.min}-${record.product.id}`
              }
              dataSource={data}
              columns={tableCols}
              loading={tableLoading}
              pagination={false}
            />
          </Col>
        </Row>
      </PageHeader>
    </>
  );
};

export default ComboRules;
