import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Descriptions,
  notification,
  Popconfirm,
  Form,
  Input,
  Space,
  InputNumber,
} from "antd";

import api from "../../../../../../../api";


import { ButtonsFlex } from './styles';
import { useAuth } from "../../../../../../../providers/Auth";
import { useParams } from "react-router-dom";



export interface OfficeDetailsProps {
  details?: Partial<any>;

  zoneId?: string;
  franchiseId?: string;

  onCancel?: () => void;

  onDataLoading?: (isLoading: boolean) => void;
  onEdit?: () => void;
  onEditLoading?: (isLoading: boolean) => void;
  onDelete?: () => void;
  onDeleteLoading?: (isLoading: boolean) => void;
}


const OfficeDetails: React.FC<OfficeDetailsProps> = ({
  details,

  zoneId: staticZoneId,
  franchiseId: staticFranchiseId,

  onCancel,
  onDataLoading,
  onEdit,
  onEditLoading,
  onDelete,
  onDeleteLoading,
}) => {
  const { franchisee_id } = useAuth();

  const { zoneId: paramRouteZoneId, franchiseId: paramRouteFranchiseId } = useParams<{ zoneId: string; franchiseId: string; }>();

  const [zoneId] = useState(staticZoneId || paramRouteZoneId);
  const [franchiseId] = useState(staticFranchiseId || paramRouteFranchiseId || franchisee_id);

  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLoading, setIsEditingLoading] = useState(false);

  const loadInfo = async (id: any) => {
    onDataLoading && onDataLoading(true);
    try {
      const { data } = await api.get(`/franchisees/${franchiseId}/zone/${zoneId}/office/${id}/`);

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      onDataLoading && onDataLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    onDeleteLoading && onDeleteLoading(true);
    try {
      await api.delete(`/franchisees/${franchiseId}/zone/${zoneId}/office/${id}/`);
      notification.success({
        message: "Escritório deletado com sucesso",
      });

      setData(null);
      setIsEditing(false);

      onDelete && onDelete();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao deletar o escritório. Tente novamente., " +
          error,
      });
    } finally {
      onDeleteLoading && onDeleteLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setIsEditingLoading(true);
    onEditLoading && onEditLoading(true);

    try {
      await api.patch(`/franchisees/${franchiseId}/zone/${zoneId}/office/${data?.id}`, values);

      notification.success({
        message: "Escritório atualizado com sucesso",
      });

      setData({
        ...data,
        ...values,
      });

      onReset();
      setIsEditing(false);

      onEdit && onEdit();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar o escritório. Tente novamente., " +
          error,
      });
    } finally {
      onEditLoading && onEditLoading(false);
      setIsEditingLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const detailsTemplate = () => {
    if (data) {
      return (<>
        <Descriptions title="Informações Gerais" column={{ md: 2 }}>
          <Descriptions.Item label="Nome">
            {data.name}
          </Descriptions.Item>
          <Descriptions.Item label="Endereço">
            {data.address_street}
          </Descriptions.Item>
          <Descriptions.Item label="Número">
            {data.address_number}
          </Descriptions.Item>
          <Descriptions.Item label="Bairro">
            {data.address_district}
          </Descriptions.Item>
          <Descriptions.Item label="Geolocalização (latitude)">
            {data.geo_lat}
          </Descriptions.Item>
          <Descriptions.Item label="Geolocalização (longitude)">
            {data.geo_lng}
          </Descriptions.Item>
        </Descriptions>
      </>);
    }
    return null;
  };

  const editTemplate = () => {
    if (data) {
      return (<>
        <Form layout="vertical" form={form} name="control-hooks" onFinish={onFinish}>
          <Form.Item name="name" label="Nome" rules={[{ required: true, max: 512, min: 2 }]}>
            <Input placeholder='Digite o nome do escritório' />
          </Form.Item>
          <Form.Item name="address_street" label="Endereço" rules={[{ required: true, max: 512, min: 2 }]}>
            <Input placeholder='Digite o endereço do escritório' />
          </Form.Item>
          <Form.Item name="address_number" label="Número" rules={[{ required: true, max: 512, min: 1 }]}>
            <Input placeholder='Digite o número do escritório' />
          </Form.Item>
          <Form.Item name="address_district" label="Bairro" rules={[{ required: true, max: 512, min: 1 }]}>
            <Input placeholder='Digite o bairro do escritório' />
          </Form.Item>
          <Form.Item name="geo_lat" label="Geolocalização (latitude)" rules={[{ required: true }]}>
            <InputNumber defaultValue={0} placeholder='Digite a latitude do escritório' style={{ display: 'block', width: '100%' }} />
          </Form.Item>
          <Form.Item name="geo_lng" label="Geolocalização (longitude)" rules={[{ required: true }]}>
            <InputNumber defaultValue={0} placeholder='Digite a longitude do escritório' style={{ display: 'block', width: '100%' }} />
          </Form.Item>
        </Form>
      </>);
    }
    return null;
  };

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      loadInfo(details?.id)
        .then((response) => {
          !didCancel && setData(response);
        })
        .catch(() => notification.error({ message: "Erro ao carregar dados!" }));
    }

    return () => {
      didCancel = true;
    };
  }, []); // No 'details' dep. is needed

  return (
    <Drawer
      width="40%"
      title="Detalhes do escritório"
      visible={data !== null}
      onClose={() => { setData(null); setIsEditing(false); onCancel && onCancel(); }}

      maskClosable={!isEditingLoading}
      closable={!isEditingLoading}
    >
      {data !== null && (
        <>
          {isEditing ? editTemplate() : detailsTemplate()}

          <ButtonsFlex>
            <Space>
              {!isEditing ? <Popconfirm
                title="Tem certeza, que deseja deletar o escritório?!"
                okType="default"
                onConfirm={async () => await handleDelete(data.id)}
                okText="Deletar"
                cancelText="Cancelar"
              >
                <Button
                  key="bt--modal-prod-delete"
                  color="danger"
                  danger
                  type="primary"
                >
                  Deletar
                </Button>
              </Popconfirm> : null}

              {!isEditing ? <Button onClick={() => { setIsEditing(true); form.setFieldsValue({ ...data }) }}>
                Editar
              </Button> : null}
              {isEditing ? <>
                <Button
                  onClick={() => { onReset(); setIsEditing(false); }}
                  disabled={isEditingLoading}
                > Cancelar </Button>
                <Button
                  onClick={() => { form.submit(); }}
                  loading={isEditingLoading}
                  disabled={isEditingLoading}
                  type="primary"
                >
                  Salvar
                </Button>
              </> : null}
            </Space>
          </ButtonsFlex>
        </>
      )}
    </Drawer>
  );
}

export default OfficeDetails;
