import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  notification,
  PageHeader,
  Row,
  Space,
  Spin,
  Steps,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import api from "../../../../../../../api";
import DebounceSelect from "../../../../../../../components/DebounceSelect";
import { useAuth } from "../../../../../../../providers/Auth";
import { deepCopyFunction } from "../../../../../../../utils/functions";
import parse from "../../../../../../../utils/parse";
import validator from "../../../../../../../utils/validator";
import CombosNewOffers from "./CombosNewOffers";
import Resume from "./Resume";
import { Container } from "./styles";

const OffersNew: React.FC<{ zoneId?: string; franchiseId?: string }> = ({
  zoneId: staticZoneId,
  franchiseId: staticFranchiseId,
}) => {
  const history = useHistory();

  const { franchisee_id } = useAuth();

  const { zoneId: paramRouteZoneId, franchiseId: paramRouteFranchiseId } =
    useParams<{ zoneId: string; franchiseId: string }>();
  const [zoneId] = useState(staticZoneId || paramRouteZoneId);
  const [franchiseId] = useState(
    staticFranchiseId || paramRouteFranchiseId || franchisee_id
  );

  const [form] = Form.useForm();
  const [stepIndex, setStepIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [products, setProducts] = useState<any[]>([]);
  const [internetPlus, setInternetPlus] = useState<{
    id_offer: string;
    plus_download: number;
  } | null>(null);
  const [offers, setOffers] = useState<{ [x: string]: any[] }>({});
  const [loadingTemplateOne, setLoadingTemplateOne] = useState(false);
  const [fullTemplateCombo, setFullTemplateCombo] = useState<any>({});
  const [additionalInfoCombo, setAdditionalInfoCombo] = useState<
    {
      id_offer: string;
      info: string[];
    }[]
  >([]);

  async function fetchComboTemplateList(comboName: string): Promise<any[]> {
    try {
      const { data } = await api.get(`/combo`, {
        params: {
          pageSize: 999,
          offset: 0,
          ...(!comboName ? {} : { filters: { name: comboName } }),
        },
      });

      return data.data.slice(0);
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    }
  }

  const onReset = () => {
    setFormData({});
    form.resetFields();
    setProducts([]);
    setOffers({});
    setStepIndex(0);
  };

  const onFinish = async () => {
    const handlers: { [s: number]: () => any } = {
      0: () => {
        form
          .validateFields()
          .then(() => {
            setFormData(form.getFieldsValue());
            setStepIndex(stepIndex + 1);
          })
          .catch((info) => {
            console.log("Validate Failed: ", info);
          });
      },
      1: () => {
        const err = validator(products, offers);
        if (!err) {
          setStepIndex(stepIndex + 1);
        } else {
          notification.warn({
            message: err,
          });
        }
      },
      2: async () => {
        const cloneForm = deepCopyFunction(formData);
        const cloneOffers: { [x: string]: any[] } = deepCopyFunction(offers);

        const custom_offers_price: { [x: string]: number } = {};

        const final = {
          ...cloneForm,
          template_id: +fullTemplateCombo.id,
          offers: [
            ...Object.keys(cloneOffers)
              .reduce(
                (a: any[], v) => [
                  ...a,
                  ...(Array.isArray(cloneOffers[v])
                    ? cloneOffers[v]
                    : [cloneOffers[v]]),
                ],
                []
              )
              .map((v) => {
                if (
                  typeof v.custom_offers_price === "number" ||
                  /^(\d+(\.\d+)?)$/.test(`${v.custom_offers_price}`)
                ) {
                  const parsedCustom = +v.custom_offers_price;
                  const parsedOriginal = +v.price;
                  if (parsedCustom !== parsedOriginal) {
                    custom_offers_price[v.id] = parsedCustom;
                  }
                }
                return v;
              })
              .filter((v) =>
                products.some(
                  (p) => !!p.allowFranchiseSelect && v.product.id === p.id
                )
              )
              .map((v) => [+v.product.id, +v.id]),
          ],
          custom_offers_price: {},
          additional_info_offers: additionalInfoCombo,
          ...{ ...(internetPlus && { speed_plus: [internetPlus] }) },
        };

        final.custom_offers_price = custom_offers_price;
        final.published = !!final.published;

        setLoading(true);
        try {
          await api.post(
            `/franchisees/${franchiseId}/zone/${zoneId}/combo`,
            final
          );

          notification.success({
            message: "Combo inserido com sucesso",
          });

          onReset();

          setLoading(false);

          history.goBack();
        } catch (error) {
          notification.error({
            message:
              "Ocorreu algum erro ao inserir o combo. Tente novamente., " +
              error,
          });
          setLoading(false);
        }
      },
    };

    return handlers[stepIndex]();
  };

  const onChangedComboTemplate = async (value: any) => {
    if (value) {
      const parsed = JSON.parse(value.value);
      setLoadingTemplateOne(true);
      try {
        const { data } = await api.get(`/combo/${parsed.id}`);
        setFullTemplateCombo(data);
        parse(
          data,
          (v) => {
            const { products: parsedProducts = [], offers: parsedOffers = {} } =
              v;
            setProducts(parsedProducts);
            setOffers(parsedOffers);
          },
          false
        );
      } catch (error) {
        throw new Error("Erro ao carregar dados! " + error);
      } finally {
        setLoadingTemplateOne(false);
      }
    } else {
      setProducts([]);
      setOffers({});
    }
  };

  return (
    <Container>
      <Spin spinning={loading}>
        <PageHeader title="Novo combo" onBack={() => history.goBack()}>
          <Space direction="vertical" style={{ width: `100%` }}>
            <Steps current={stepIndex}>
              <Steps.Step title="Dados gerais" />
              <Steps.Step title="Ofertas" />
              <Steps.Step title="Resumo" />
            </Steps>

            <div style={{ height: 15 }}></div>

            {stepIndex === 0 && (
              <Form
                layout="vertical"
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Row gutter={15}>
                  <Col md={6}>
                    <Form.Item
                      name="name"
                      label="Nome"
                      rules={[{ required: true, max: 512, min: 2 }]}
                    >
                      <Input placeholder="Digite o nome do combo" />
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item
                      name="template_id"
                      label="Template do combo"
                      rules={[{ required: true }]}
                    >
                      <DebounceSelect
                        labelInValue
                        placeholder="Selecione o template de combo"
                        showSearch
                        fetchOptions={fetchComboTemplateList}
                        style={{ width: "100%" }}
                        labelProp="name"
                        onChange={onChangedComboTemplate}
                        valueProp={false}
                        disabled={loadingTemplateOne}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} />
                  <Col md={6}>
                    <Form.Item name="published" valuePropName="checked">
                      <Checkbox>Publicado?</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col md={18} />

                  <Col md={8}>
                    <Typography.Title level={5}>
                      Informações adicionais
                    </Typography.Title>
                    <Form.List name="additional_info">
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              label={`Informação ${index + 1}`}
                              required={true}
                              key={field.key}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={["onChange", "onBlur"]}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message:
                                      "Preencha este campo, ou remova ele.",
                                  },
                                ]}
                                noStyle
                                style={{ display: "flex" }}
                              >
                                <Input
                                  placeholder="Digite a informação..."
                                  style={{ width: "calc(100% - 30px)" }}
                                />
                              </Form.Item>
                              <MinusOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              style={{ width: "100%" }}
                              icon={<PlusOutlined />}
                            >
                              Adicionar informação
                            </Button>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>

                <div style={{ display: `flex`, justifyContent: `flex-end` }}>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Próximo
                    </Button>
                  </Space>
                </div>
              </Form>
            )}

            {stepIndex === 1 && (
              <CombosNewOffers
                offers={offers}
                zoneId={zoneId}
                onNext={onFinish}
                products={products}
                onRemoved={() => {}}
                onInserted={() => {}}
                franchiseId={franchiseId}
                internetPlus={internetPlus!}
                onChanged={(values) => {
                  setOffers(values);
                }}
                handlerInternetPlus={(v) => {
                  setInternetPlus(v);
                }}
                onChangedInfoCombo={(infoCombo) => {
                  setAdditionalInfoCombo(infoCombo);
                }}
                additional_info_offers={additionalInfoCombo}
                onPrevius={() => setStepIndex(stepIndex - 1)}
              />
            )}

            {stepIndex === 2 && (
              <Resume
                offers={offers}
                onNext={onFinish}
                formData={formData}
                products={products}
                speedPlus={internetPlus}
                infoOffers={additionalInfoCombo}
                onPrevius={() => setStepIndex(stepIndex - 1)}
                loading={loading}
              />
            )}
          </Space>
        </PageHeader>
      </Spin>
    </Container>
  );
};

export default OffersNew;
