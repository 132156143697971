import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  PageHeader,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import api from "../../../../../../api";
import FieldsOfferRegister from "../FieldsOfferRegister";
import { Container, ContainerLoading } from "./styles";

const { Title } = Typography;

const OffersNew: React.FC<{}> = () => {
  const { productId } = useParams<{ productId: string }>();
  const history = useHistory();

  const [form] = Form.useForm();
  const [isInsertingLoading, setIsInsertingLoading] = useState(false);
  const [productData, setProductData] = useState<Partial<any>>({});
  const [loadingProduct, setLoadingProduct] = useState(false);

  const loadInfoProduct = useCallback(async () => {
    setLoadingProduct(true);
    try {
      const { data } = await api.get(`/product/${productId}/`);

      return data;
    } catch (error) {
      history.goBack();
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setLoadingProduct(false);
    }
  }, [productId, history]);

  const onFinish = async (values: any) => {
    setIsInsertingLoading(true);

    values.status = !!values.status;

    try {
      await api.post(`/product/${productId}/offer`, {
        ...values,
        status_in_combo: values.status_in_combo
          ? values.status_in_combo
          : false,
      });
      notification.success({
        message: "Oferta inserida com sucesso",
      });

      history.goBack();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao inserir a oferta. Tente novamente., " + error,
      });
    } finally {
      setIsInsertingLoading(false);
    }
  };

  useEffect(() => {
    let didCancel = false;

    loadInfoProduct()
      .then((response) => {
        !didCancel && setProductData(response);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [loadInfoProduct]);

  let loadingTemplate = (
    <ContainerLoading>
      <Spin></Spin>
    </ContainerLoading>
  );

  let loadedTemplate = (
    <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
      <Row gutter={16}>
        <Col md={8}>
          <Form.Item
            name="title"
            label="Título"
            rules={[{ required: true, max: 512, min: 2 }]}
          >
            <Input placeholder="Digite o título da oferta" />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item name="price" label="Preço" rules={[{ required: true }]}>
            <InputNumber
              style={{ display: "block", width: "100%" }}
              formatter={(value) =>
                `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value?.replace(/R\$\s?|(,*)/g, "") as any}
            />
          </Form.Item>
        </Col>
        <Col md={8}></Col>

        <Col md={8}>
          <Form.Item
            name="status"
            valuePropName="checked"
            wrapperCol={{ offset: 0, span: 24 }}
          >
            <Checkbox checked>Ativo?</Checkbox>
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            name="status_in_combo"
            valuePropName="checked"
            wrapperCol={{ offset: 0, span: 24 }}
          >
            <Checkbox checked>Habilitado para combo?</Checkbox>
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            name={["metadata", "best_offer"]}
            valuePropName="checked"
            wrapperCol={{ offset: 0, span: 24 }}
          >
            <Checkbox checked>Melhor oferta?</Checkbox>
          </Form.Item>
        </Col>

        <Col md={8}>
          <Title level={5}>Informações adicionais</Title>
          <Form.List name="additional_info">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={`Informação ${index + 1}`}
                    required={true}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Preencha este campo, ou remova ele.",
                        },
                      ]}
                      noStyle
                      style={{ display: "flex" }}
                    >
                      <Input
                        placeholder={`Digite a informção ${index + 1}`}
                        style={{ width: "calc(100% - 30px)" }}
                      />
                    </Form.Item>
                    <MinusOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: "100%" }}
                    icon={<PlusOutlined />}
                  >
                    Adicionar informação
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
        <Col md={16}></Col>

        {productData && productData.config ? (
          <Col md={16}>
            <Title level={5}>Informações sobre a oferta</Title>

            <Row gutter={15}>
              <Col md={24}>
                <FieldsOfferRegister
                  config={((productData as any)?.config as any) || []}
                />
              </Col>
            </Row>
          </Col>
        ) : null}
      </Row>

      <Row justify="end">
        <Col>
          <Space>
            <Button
              key={"save-product-offer"}
              disabled={isInsertingLoading}
              loading={isInsertingLoading}
              type="primary"
              htmlType="submit"
            >
              Salvar dados
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Container>
      <Spin spinning={isInsertingLoading}>
        <PageHeader title="Nova oferta" onBack={() => history.goBack()}>
          {loadingProduct ? loadingTemplate : loadedTemplate}
        </PageHeader>
      </Spin>
    </Container>
  );
};

export default OffersNew;
