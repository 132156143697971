import { Empty, Select, Spin } from "antd";
import { SelectProps } from "antd/es/select";
import debounce from "lodash/debounce";
import React from "react";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, "options" | "children"> {
  block?: boolean;
  labelProp?: string;
  initialName?: null | string;
  debounceTimeout?: number;
  emptyDataMessage?: string;
  valueProp?: string | false;
  startsWithSearch?: boolean;
  initialOptions?: ValueType[];
  shouldLoadOnInit?: string | boolean;
  filterOptions?: (options?: any[]) => any[];
  afterFetchOptions?: (options?: any[]) => void;
  fetchOptions?: (search: string) => Promise<ValueType[]>;
  onSelectedValue?: (value: { key: any; value: any }) => void;
  renderLabel?: (item: ValueType) => string | null | React.ReactNode;
}

function DebounceSelect<
  ValueType extends {
    key?: string;
    name: string;
    label: React.ReactNode;
    value: string | number;
    onSelectedValue?: (value: any) => void;
  } = any
>({
  initialName,
  renderLabel,
  fetchOptions,
  block = true,
  filterOptions,
  onSelectedValue,
  startsWithSearch,
  afterFetchOptions,
  valueProp = "value",
  labelProp = "label",
  initialOptions = [],
  debounceTimeout = 800,
  shouldLoadOnInit = false,
  emptyDataMessage = "Sem dados!",
  ...props
}: DebounceSelectProps) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<ValueType[]>(initialOptions);
  const fetchRef = React.useRef(0);

  const debounceFetcher = React.useMemo(() => {
    if (fetchOptions) {
      const loadOptions = (value: string) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);

        fetchOptions(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            return;
          }

          setOptions(newOptions);
          afterFetchOptions && afterFetchOptions(newOptions);
          setFetching(false);
        });
      };

      return debounce(loadOptions, debounceTimeout);
    }
  }, [fetchOptions, debounceTimeout, afterFetchOptions]);

  React.useEffect(() => {
    if (initialName && initialName.length > 0) {
      if (fetchOptions) {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);

        fetchOptions(initialName).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            return;
          }

          setOptions(newOptions);
          afterFetchOptions && afterFetchOptions(newOptions);
          setFetching(false);
        });
      }
    }
  }, [initialName]);

  return (
    <Select<ValueType>
      showSearch
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={
        fetching ? (
          <Spin size="small" />
        ) : (
          <Empty description={emptyDataMessage} />
        )
      }
      onSelect={(val: any, option: any) =>
        onSelectedValue &&
        onSelectedValue({
          key: option.children,
          value: option.value,
        })
      }
      {...(block ? { style: { width: `100%` } } : {})}
      {...props}
      {...(props.labelInValue
        ? {
            options: (filterOptions ? filterOptions(options) : options).map(
              (item: any) => ({
                value: valueProp
                  ? item[valueProp || "value"]
                  : JSON.stringify(item),
                label: renderLabel
                  ? renderLabel(item)
                  : item[labelProp || "label"],
              })
            ),
          }
        : {})}
    >
      {!props.labelInValue
        ? (filterOptions ? filterOptions(options) : options).map(
            (option: any) => {
              const value = valueProp
                ? option[valueProp || "value"]
                : JSON.stringify(option);

              return (
                <Select.Option key={value} value={value}>
                  {renderLabel
                    ? renderLabel(option)
                    : option[labelProp || "label"]}
                </Select.Option>
              );
            }
          )
        : null}
    </Select>
  );
}

export default DebounceSelect;
