import {
  Form,
  Input,
  notification,
  PageHeader,
  Tabs,
  Row,
  Col,
  Button,
  Space,
  Popconfirm,
  InputNumber,
  Checkbox,
  Typography,
  Spin,
  Select,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import api from "../../../../../../../api";

import { Container, ContainerLoading } from "./styles";
import { useAuth } from "../../../../../../../providers/Auth";
import FieldsOfferRegister from "../../../../../Products/About/Offers/FieldsOfferRegister";

const { Title } = Typography;
const { Option } = Select;

interface ProductsOfferAboutProps {
  zoneId?: string;
  franchiseId?: string;
  offerId?: string;
}

const ProductsOfferAbout: React.FC<ProductsOfferAboutProps> = ({
  zoneId: staticZoneId,
  franchiseId: staticFranchiseId,
  offerId: staticOfferId,
}) => {
  const history = useHistory();

  const { franchisee_id } = useAuth();

  const {
    zoneId: paramRouteZoneId,
    franchiseId: paramRouteFranchiseId,
    offerId: paramRouteOfferId,
  } = useParams<
    Pick<ProductsOfferAboutProps, "franchiseId" | "offerId" | "zoneId">
  >();

  const [franchiseId] = useState(
    staticFranchiseId || paramRouteFranchiseId || franchisee_id
  );
  const [zoneId] = useState(staticZoneId || paramRouteZoneId);
  const [offerId] = useState(staticOfferId || paramRouteOfferId);

  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLoading, setIsEditingLoading] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState<any[]>([]);
  const [productData, setProductData] = useState<Partial<any>>({});

  const loadProducts = useCallback(async () => {
    setLoadingProducts(true);
    try {
      const { data } = await api.get("/product", {
        params: {
          pageSize: 999,
          offset: 0,
        },
      });

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setLoadingProducts(false);
    }
  }, []);

  const loadInfo = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/franchisees/${franchiseId}/zone/${zoneId}/offer/${offerId}`
      );

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setLoading(false);
    }
  }, [franchiseId, zoneId, offerId]);

  const handleDelete = useCallback(async () => {
    setIsDeletingLoading(true);
    try {
      await api.delete(
        `/franchisees/${franchiseId}/zone/${zoneId}/offer/${offerId}`
      );
      notification.success({
        message: "Oferta deletada com sucesso",
      });

      setData(null);

      history.goBack();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao deletar a oferta. Tente novamente., " + error,
      });
    } finally {
      setIsDeletingLoading(false);
    }
  }, [franchiseId, zoneId, offerId, history]);

  const onFinish = async (values: any) => {
    setIsEditingLoading(true);

    if ("price" in values && typeof values.price === "string") {
      try {
        values.price = +values.price;
      } catch (_) {}
    }

    if ("product_id" in values && typeof values.product_id === "string") {
      try {
        values.product_id = +values.product_id;
      } catch (_) {}
    }

    values.status = !!values.status;

    try {
      await api.patch(
        `/franchisees/${franchiseId}/zone/${zoneId}/offer/${offerId}`,
        {
          ...values,
          status_in_combo: values.status_in_combo
            ? values.status_in_combo
            : false,
        }
      );

      notification.success({
        message: "Oferta atualizada com sucesso",
      });

      const dataMerged = {
        ...data,
        ...values,
      };

      setIsEditing(false);

      setTimeout(setData, 10, dataMerged);

      onChangedProductId(void 0, void 0);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar a oferta. Tente novamente., " +
          error,
      });
    } finally {
      setIsEditingLoading(false);
    }
  };

  const onChangedProductId = (
    value: any = data.product.id,
    skipClear: boolean = true,
    options?: any[]
  ) => {
    if (value) {
      const _options = options || products;
      if (!skipClear) {
        form.setFieldsValue({
          ...(form.getFieldsValue() || {}),
          metadata: {},
        });
        form.resetFields([`metadata`]);
      }
      const product = _options
        .slice(0)
        .filter((item) => item.id === value)
        .shift();
      if (product) {
        setProductData(product);
      }
    } else {
      setProductData({});
    }
  };

  useEffect(() => {
    let didCancel = false;

    loadProducts()
      .then((productsRes) => {
        !didCancel && setProducts(productsRes.data);

        loadInfo()
          .then((response) => {
            !didCancel && setData(response);
            !didCancel &&
              form.setFieldsValue({
                ...response,

                product_id: response.product.id,
              });
            !didCancel &&
              onChangedProductId(response.product.id, true, productsRes.data);
          })
          .catch(() =>
            notification.error({ message: "Erro ao carregar dados!" })
          );
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [form, loadInfo, loadProducts]); // No 'details' dep. is needed

  const loadingTemplate = (
    <ContainerLoading>
      <Spin></Spin>
    </ContainerLoading>
  );

  const loadedTemplate = (
    <Spin spinning={isDeletingLoading || isEditingLoading}>
      <PageHeader title="Dados da oferta" onBack={() => history.goBack()}>
        <Tabs defaultActiveKey="about">
          <Tabs.TabPane tab="Dados gerais" key="about">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={onFinish}
            >
              <Row gutter={16}>
                <Col md={8}>
                  <Form.Item
                    name="product_id"
                    label="Produto"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Selecione um produto"
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      loading={loadingProducts}
                      onChange={(evt: any) =>
                        onChangedProductId(evt as any, false)
                      }
                      disabled={!isEditing}
                    >
                      {products.map((item, index) => {
                        return (
                          <Option
                            key={`products-${index}`}
                            value={`${item.id}`}
                          >
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="title"
                    label="Título"
                    rules={[{ required: true, max: 512, min: 2 }]}
                  >
                    <Input
                      placeholder="Digite o título da oferta"
                      disabled={!isEditing}
                    />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="price"
                    label="Preço"
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      style={{ display: "block", width: "100%" }}
                      formatter={(value) =>
                        `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) =>
                        value?.replace(/R\$\s?|(,*)/g, "") as any
                      }
                      disabled={!isEditing}
                    />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="status"
                    valuePropName="checked"
                    wrapperCol={{ offset: 0, span: 24 }}
                  >
                    <Checkbox checked disabled={!isEditing}>
                      Ativo?
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="status_in_combo"
                    valuePropName="checked"
                    wrapperCol={{ offset: 0, span: 24 }}
                  >
                    <Checkbox checked disabled={!isEditing}>
                      Habilitado para combo?
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    valuePropName="checked"
                    name={["metadata", "is_best_plan"]}
                    wrapperCol={{ offset: 0, span: 24 }}
                  >
                    <Checkbox checked disabled={!isEditing}>
                      Melhor oferta?
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Title level={5}>Informações adicionais</Title>
                  <Form.List name="additional_info">
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item
                            label={`Informação ${index + 1}`}
                            required={true}
                            key={field.key}
                          >
                            <Form.Item
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message:
                                    "Preencha este campo, ou remova ele.",
                                },
                              ]}
                              noStyle
                              style={{ display: "flex" }}
                            >
                              <Input
                                placeholder="passenger name"
                                style={{
                                  width: `calc(100% - ${isEditing ? 30 : 0}px)`,
                                }}
                                disabled={!isEditing}
                              />
                            </Form.Item>
                            {isEditing ? (
                              <MinusOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </Form.Item>
                        ))}

                        {isEditing ? (
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              style={{ width: "100%" }}
                              icon={<PlusOutlined />}
                              disabled={!isEditing}
                            >
                              Adicionar informação
                            </Button>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        ) : null}
                      </>
                    )}
                  </Form.List>
                </Col>
                <Col md={8}></Col>

                {productData && productData.config ? (
                  <Col md={16}>
                    <Title level={5}>Informações sobre a oferta</Title>

                    <Row gutter={15}>
                      <Col md={24}>
                        <FieldsOfferRegister
                          config={((productData as any)?.config as any) || []}
                          isDisabled={!isEditing}
                        />
                      </Col>
                    </Row>
                  </Col>
                ) : null}
              </Row>

              <Row justify="end">
                <Col>
                  {isEditing ? (
                    <Space>
                      <Button
                        key={"cancel-edit-product"}
                        onClick={() => {
                          setIsEditing(false);
                          form.setFieldsValue(data);
                          onChangedProductId(void 0, void 0);
                        }}
                        disabled={isEditingLoading}
                        htmlType="button"
                      >
                        Cancelar
                      </Button>
                      <Button
                        key={"save-product"}
                        disabled={isEditingLoading}
                        loading={isEditingLoading}
                        type="primary"
                        htmlType="submit"
                      >
                        Salvar dados
                      </Button>
                    </Space>
                  ) : null}
                </Col>
              </Row>
            </Form>
            <Row justify="end">
              {!isEditing ? (
                <Space>
                  <Popconfirm
                    title="Tem certeza, que deseja deletar o produto?!"
                    okType="default"
                    onConfirm={async () => await handleDelete()}
                    okText="Deletar"
                    cancelText="Cancelar"
                  >
                    <Button
                      key="bt-prod-delete"
                      color="danger"
                      danger
                      type="primary"
                    >
                      Deletar
                    </Button>
                  </Popconfirm>

                  <Button
                    key={"edit-product"}
                    disabled={isDeletingLoading}
                    onClick={() => {
                      setIsEditing(true);
                      onChangedProductId(void 0, void 0);
                    }}
                    type="primary"
                    htmlType="button"
                  >
                    Editar
                  </Button>
                </Space>
              ) : null}
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </PageHeader>
    </Spin>
  );

  return <Container>{loading ? loadingTemplate : loadedTemplate}</Container>;
};

export default ProductsOfferAbout;
