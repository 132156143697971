import { Button, Form, Input, Modal } from "antd";
import React from "react";

import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const AddInfo: React.FC<{
  index: number;
  isVisible?: boolean;
  onCancel?: () => void;
  onSubmit?: (idOffer: number, value: string[]) => void;
}> = ({ isVisible, index, onSubmit, onCancel }) => {
  const [form] = Form.useForm();

  const onFinish = async (value: any) => {
    if (value.additional_info.length > 0) {
      onSubmit && onSubmit(index, value.additional_info);
      onReset();
      onCancel && onCancel();
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      closable={false}
      okText="Concluir"
      visible={isVisible}
      maskClosable={false}
      title="Adicionar informação"
      okButtonProps={{
        htmlType: "submit",
      }}
      cancelButtonProps={{}}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch((info) => {
            console.log("Validate Failed: ", info);
          });
      }}
      onCancel={() => {
        onReset();
        onCancel && onCancel();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="control-hooks"
      >
        <Form.List name="additional_info">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  label={`Informação ${index + 1}`}
                  required={true}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Preencha este campo, ou remova ele.",
                      },
                    ]}
                    noStyle
                    style={{ display: "flex" }}
                  >
                    <Input
                      placeholder="Digite a informação..."
                      style={{ width: "calc(100% - 30px)" }}
                    />
                  </Form.Item>
                  <MinusOutlined
                    style={{ marginLeft: "10px" }}
                    onClick={() => remove(field.name)}
                  />
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "100%" }}
                  icon={<PlusOutlined />}
                >
                  Adicionar informação
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default AddInfo;
