import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Drawer,
  Form,
  Input,
  message,
  notification,
  Popconfirm,
  Space,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";

import api, { baseURL } from "../../../../api";
import { useAuth } from "../../../../providers/Auth";
import { ButtonsFlex } from "./styles";

export interface RegulationsDetailsProps {
  details?: Partial<any>;
  onCancel?: () => void;
  onDataLoading?: (isLoading: boolean) => void;
  onEdit?: () => void;
  onEditLoading?: (isLoading: boolean) => void;
  onDelete: (id: number, currentPage: number) => void;
  onDeleteLoading?: (isLoading: boolean) => void;
  paginationData: {
    current: number;
    pageSize: number;
    showSizeChanger: boolean;
  };
}

const RegulationsDetails: React.FC<RegulationsDetailsProps> = ({
  details,
  onCancel,
  onDataLoading,
  onEdit,
  onEditLoading,
  onDelete,
  onDeleteLoading,
  paginationData,
}) => {
  const { access_token } = useAuth() as any;

  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLoading, setIsEditingLoading] = useState(false);
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [inserted, setInserted] = useState(false);

  const loadInfo = async (id: number) => {
    onDataLoading && onDataLoading(true);
    try {
      const { data } = await api.get(`/regulation/${id}/`);

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      onDataLoading && onDataLoading(false);
    }
  };

  const handleDeleteInDetails = async (id: number, currentPage: number) => {
    onDeleteLoading && onDeleteLoading(true);
    try {
      await onDelete(id, currentPage);
      notification.success({
        message: "Regulamento deletado com sucesso",
      });
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao deletar o regulamento. Tente novamente., " +
          error,
      });
    } finally {
      onDeleteLoading && onDeleteLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setIsEditingLoading(true);
    onEditLoading && onEditLoading(true);

    try {
      await api.patch(`/regulation/${data?.id}`, values);

      notification.success({
        message: "Regulamento atualizado com sucesso",
      });

      setData({
        ...data,
        ...values,
      });

      onReset();
      setIsEditing(false);

      onEdit && onEdit();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar o regulamento. Tente novamente., " +
          error,
      });
    } finally {
      onEditLoading && onEditLoading(false);
      setIsEditingLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const beforeUpload = (file: File) => {
    const isTypeValid = file.type === "application/pdf";
    if (!isTypeValid) {
      message.error("Você só pode mandar documento com o tipo PDF!");
    }
    const size = 5;
    const isLt2M = file.size / 1024 / 1024 < size;
    if (!isLt2M) {
      message.error(`O documento deve ser menor que ${size}MB!`);
    }
    return isTypeValid && isLt2M;
  };

  const handleChange = (info: any) => {
    console.log(info);
    if (info.file.status === "uploading") {
      setIsEditingLoading(true);
      setLoadingUploadFile(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      setIsEditingLoading(false);
      setLoadingUploadFile(false);
      setInserted(true);
      onEdit && onEdit();
    }
    if (info.file.status === "error") {
      setIsEditingLoading(false);
      setLoadingUploadFile(false);
      notification.error({
        message:
          "Ouve um erro ao tentar enviar o documento do regulamento! Tente novamente.",
      });
    }
  };

  const detailsTemplate = () => {
    if (data) {
      return (
        <>
          <Descriptions title="Informações Gerais" column={{ md: 1 }}>
            <Descriptions.Item label="Nome">{data.name}</Descriptions.Item>
            <Descriptions.Item label="Descrição">
              {data.description}
            </Descriptions.Item>
            <Descriptions.Item label="Documento">
              {data.file_link ? (
                <a href={data.file_link} target="_blank" rel="noreferrer">
                  Abrir documento!
                </a>
              ) : inserted ? (
                "O link ainda não foi gerado!"
              ) : (
                "Sem documento anexado!"
              )}
            </Descriptions.Item>
          </Descriptions>
        </>
      );
    }
    return null;
  };

  const editTemplate = () => {
    if (data) {
      return (
        <>
          <Form
            layout="vertical"
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              label="Nome"
              rules={[{ required: true, max: 512, min: 3 }]}
            >
              <Input
                placeholder="Digite o nome do regulamento"
                disabled={isEditingLoading}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="Descrição"
              rules={[{ required: true, max: 512, min: 3 }]}
            >
              <Input.TextArea
                placeholder="Digite uma descrição para o regulamento"
                rows={4}
                disabled={isEditingLoading}
              />
            </Form.Item>
          </Form>
          <Upload
            name="file"
            listType="text"
            showUploadList={false}
            action={`${
              baseURL.endsWith("/") ? baseURL : `${baseURL}/`
            }regulation/${data?.id}/upload`}
            method="post"
            beforeUpload={beforeUpload}
            maxCount={1}
            onChange={handleChange}
            headers={{
              Authorization: `Bearer ${access_token}`,
            }}
            disabled={loadingUploadFile}
          >
            <Button loading={loadingUploadFile} icon={<UploadOutlined />}>
              Enviar documento
            </Button>
          </Upload>
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      loadInfo(details?.id)
        .then((response) => {
          !didCancel && setData(response);
        })
        .catch(() =>
          notification.error({ message: "Erro ao carregar dados!" })
        );
    }

    return () => {
      didCancel = true;
    };
  }, []); // No 'details' dep. is needed

  return (
    <Drawer
      width="40%"
      title="Detalhes do regulamento"
      visible={data !== null}
      onClose={() => {
        setData(null);
        setIsEditing(false);
        onCancel && onCancel();
      }}
      maskClosable={!isEditingLoading}
      closable={!isEditingLoading}
    >
      {data !== null && (
        <>
          {isEditing ? editTemplate() : detailsTemplate()}

          <ButtonsFlex>
            <Space>
              {!isEditing ? (
                <Popconfirm
                  title="Tem certeza, que deseja deletar a cidade?!"
                  okType="default"
                  onConfirm={async () =>
                    await handleDeleteInDetails(data.id, paginationData.current)
                  }
                  okText="Deletar"
                  cancelText="Cancelar"
                >
                  <Button
                    key="bt--modal-prod-delete"
                    color="danger"
                    danger
                    type="primary"
                  >
                    Deletar
                  </Button>
                </Popconfirm>
              ) : null}

              {!isEditing ? (
                <Button
                  onClick={() => {
                    setIsEditing(true);
                    form.setFieldsValue({ ...data });
                  }}
                >
                  Editar
                </Button>
              ) : null}
              {isEditing ? (
                <>
                  <Button
                    onClick={() => {
                      onReset();
                      setIsEditing(false);
                    }}
                    disabled={isEditingLoading || loadingUploadFile}
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={() => {
                      form.submit();
                    }}
                    loading={isEditingLoading || loadingUploadFile}
                    disabled={isEditingLoading || loadingUploadFile}
                    type="primary"
                  >
                    Salvar
                  </Button>
                </>
              ) : null}
            </Space>
          </ButtonsFlex>
        </>
      )}
    </Drawer>
  );
};

export default RegulationsDetails;
