import React, { useEffect, useState } from "react";
import {
  notification,
  Form,
  Modal,
  Input,
  Checkbox,
  SelectProps,
  Select,
  /*Select,*/
} from "antd";

import api from "../../../../../api";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../providers/Auth";
import DebounceSelect from "../../../../../components/DebounceSelect";
import { deepMerge } from "../../../../../utils/functions";

/*const { Option } = Select;*/

const ZoneNew: React.FC<{
  franchiseId?: string;
  isVisible?: boolean;
  onCreate?: () => void;
  onCancel?: () => void;
}> = ({ isVisible, onCreate, onCancel, franchiseId: franchiseIdStatic }) => {
  const { franchisee_id: franchiseIdLogin } = useAuth();
  const { franchiseId: franchiseIdDynamic } = useParams<{
    franchiseId: string;
  }>();
  const franchiseId =
    franchiseIdStatic || franchiseIdDynamic || franchiseIdLogin;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<SelectProps["options"]>([]);

  const onLoadProduct = async () => {
    try {
      const { data } = await api.get("/product");

      setProducts(
        data.data.map((product: any) => ({
          label: product.name,
          value: product.id,
        }))
      );
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    let clone: any = deepMerge({}, values);
    try {
      clone.status = !!clone.status;

      if ("city_id" in clone && typeof clone.city_id === "string") {
        try {
          clone.city_id = +clone.city_id;
        } catch (_) {}
      } else if ("city_id" in clone && typeof clone.city_id === "object") {
        try {
          clone.city_id = +clone.city_id.value;
        } catch (_) {}
      }

      await api.post(`/franchisees/${franchiseId}/zone`, clone);

      notification.success({
        message: "Zona inserida com sucesso",
      });

      onReset();
      onCreate && onCreate();

      setLoading(false);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao inserir a zona. Tente novamente., " + error,
      });
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  async function fetchCityList(cityname: string): Promise<any[]> {
    try {
      const { data } = await api.get("/cities", {
        params: {
          pageSize: 999,
          offset: 0,
          ...(!cityname ? {} : { filters: { name: cityname } }),
        },
      });

      return data.data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    }
  }

  /*useEffect(() => {
    let didCancel = false;

    loadCities()
      .then((response) => {
        !didCancel && setCities(response.data);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [loadCities]);*/

  useEffect(() => {
    if (isVisible) {
      onLoadProduct();
    }
  }, [isVisible]);
  return (
    <Modal
      visible={isVisible && products !== undefined}
      title="Adicionar zona"
      closable={false}
      maskClosable={false}
      okText="Adicionar"
      okButtonProps={{
        htmlType: "submit",
        disabled: loading,
        loading: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch((info) => {
            console.log("Validate Failed: ", info);
          });
      }}
      onCancel={() => {
        onReset();
        onCancel && onCancel();
      }}
    >
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        initialValues={{
          visible_products: ["1", "2", "5"],
        }}
      >
        <Form.Item name="city_id" label="Cidade" rules={[{ required: true }]}>
          <DebounceSelect
            placeholder="Selecione uma cidade"
            showSearch
            fetchOptions={fetchCityList}
            style={{ width: "100%" }}
            labelProp="name"
            valueProp="id"
          />
        </Form.Item>

        <Form.Item
          name="visible_products"
          label="Produtos visíveis"
          rules={[{ required: true }]}
        >
          <Select
            allowClear
            mode="multiple"
            options={products}
            style={{ width: "100%" }}
            placeholder="Selecionar produtos visíveis"
          />
        </Form.Item>
        {/*<Form.Item name="city_id" label="Cidade" rules={[{ required: true, }]}>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Selecione uma cidade"
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            loading={loadingCities}
          >
            {cities.map((item, index) => {
              return <Option key={`cities-${index}`} value={item.id}>{item.name}</Option>;
            })}
          </Select>
        </Form.Item>*/}
        <Form.Item
          name="zone"
          label="Zona"
          rules={[{ required: true, max: 512, min: 2 }]}
        >
          <Input placeholder="Digite a zona" />
        </Form.Item>
        <Form.Item
          name="district"
          label="Distrito"
          rules={[{ required: true, max: 512, min: 2 }]}
        >
          <Input placeholder="Digite o distrito" />
        </Form.Item>
        <Form.Item name="status" valuePropName="checked">
          <Checkbox>Ativa?</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ZoneNew;
