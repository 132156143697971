import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tabs,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import api from "../../../../../../../api";
import DebounceSelect from "../../../../../../../components/DebounceSelect";
import { useAuth } from "../../../../../../../providers/Auth";
import { ProductTypesId } from "../../../../../../../utils/enums";
import { formatPrice, hash } from "../../../../../../../utils/functions";
import parse from "../../../../../../../utils/parse";
import CombosEdit from "./CombosEdit";
import {
  Container,
  ContainerLoading,
  ProductsAndOffersList,
  ProductsAndOffersListOItem,
  ProductsAndOffersListPItem,
} from "./styles";

interface CombosAboutProps {
  zoneId?: string;
  franchiseId?: string;
  comboId?: string;
}

const CombosAbout: React.FC<CombosAboutProps> = ({
  zoneId: staticZoneId,
  franchiseId: staticFranchiseId,
  comboId: staticComboId,
}) => {
  const history = useHistory();

  const { franchisee_id } = useAuth();

  const {
    zoneId: paramRouteZoneId,
    franchiseId: paramRouteFranchiseId,
    comboId: paramRouteComboId,
  } = useParams<Pick<CombosAboutProps, "franchiseId" | "comboId" | "zoneId">>();

  const [franchiseId] = useState(
    staticFranchiseId || paramRouteFranchiseId || franchisee_id
  );
  const [zoneId] = useState(staticZoneId || paramRouteZoneId);
  const [comboId] = useState(staticComboId || paramRouteComboId);

  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const [originalData, setOriginalData] = useState<any>({});
  const [products, setProducts] = useState<any[]>([]);
  const [offers, setOffers] = useState<{ [x: string]: any[] }>({});
  const [loading, setLoading] = useState(false);
  const [totalSumPrice, setTotalSumPrice] = useState(0);
  const [treeNodes, setTreeNodes] = useState<any[]>([]);
  const [infoCombo, setInfoCombo] = useState<any[] | []>([]);
  const [speedPlus, setSpeedPlus] = useState<any[] | []>([]);

  const buildTreeNodes = (
    prdts: any[] = products,
    ofrs: { [x: string]: any[] } = offers
  ) => {
    let price = 0;
    setTotalSumPrice(0);
    setTreeNodes(null as unknown as any[]);
    setTimeout(() => {
      const treeNodes = prdts.map((p) => {
        const children: any[] = [];
        const po = ofrs[p.id] || [];

        if (!po.length) {
          children.push({
            key: `${hash()}`,
            expanded: true,
            title: `Não há ofertas selecionadas para este produto!`,
          });
        } else {
          po.forEach((o) => {
            price += +`${
              (typeof o.custom_offers_price === "number" ||
              /^(\d+(\.\d+)?)$/.test(`${o.custom_offers_price}`)
                ? o.custom_offers_price
                : o.price) || 0
            }`;

            children.push({
              id: o.id,
              expanded: true,
              key: `${hash()}`,
              productID: o.product.id,
              price: formatPrice(o.price),
              title: `#${o.id} - ${o.title}`,
              custom_offers_price: o.custom_offers_price,
              ...(typeof o.custom_offers_price === "number"
                ? { custom_offers_price: formatPrice(o.custom_offers_price) }
                : {}),
              ...{
                ...(+o.product.id === ProductTypesId.Internet && {
                  original_speed: `${o.metadata.download} ${o.metadata.download_suffix}`,
                }),
              },
            });
          });
        }

        return {
          key: `${hash()}`,
          title: `#${p.id} - ${p.name}`,
          children,
          expanded: true,
        };
      });

      setTotalSumPrice(price);
      setTreeNodes(treeNodes);
    });
  };

  const parser = (data: any, cb?: Function) => {
    parse(
      data || {},
      (v) => {
        const {
          offers: parsedOffers = {},
          products: parsedProducts = [],
          offers_speed_plus: speed_plus,
          additional_info_offers: info_offers,
        } = v;
        setOffers(parsedOffers);
        setSpeedPlus(speed_plus);
        setInfoCombo(info_offers);
        setProducts(parsedProducts);

        Promise.resolve().then(() =>
          setTimeout(buildTreeNodes, 0, parsedProducts, parsedOffers)
        );

        cb && cb();
      },
      true
    );
  };

  const loadInfo = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/franchisees/${franchiseId}/zone/${zoneId}/combo/${comboId}`
      );
      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setLoading(false);
    }
  }, [franchiseId, zoneId, comboId]);

  const handleDelete = useCallback(async () => {
    setIsDeletingLoading(true);
    try {
      await api.delete(
        `/franchisees/${franchiseId}/zone/${zoneId}/combo/${comboId}`
      );
      notification.success({
        message: "Combo deletado com sucesso",
      });

      setData(null);

      history.goBack();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao deletar o combo. Tente novamente., " + error,
      });
    } finally {
      setIsDeletingLoading(false);
    }
  }, [franchiseId, zoneId, comboId, history]);

  const onFinish = async (values: any) => {
    if (window) {
      window.location.reload();
    }
  };

  const onCancel = (doParse: boolean = true) => {
    setIsEditing(false);
    Promise.resolve().then(() => {
      setData(originalData);
      form.setFieldsValue(originalData);
      if (doParse) {
        parser(originalData);
      }
    });
  };

  useEffect(() => {
    let didCancel = false;

    loadInfo()
      .then((response) => {
        if (!didCancel) {
          response = {
            ...response,
            template_id: response.template.id,
          };
          setOriginalData(response);
          setData(response);
          form.setFieldsValue(response);
          parser(response);
        }
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [form, loadInfo]); // No 'details' dep. is needed

  const loadingTemplate = (
    <ContainerLoading>
      <Spin></Spin>
    </ContainerLoading>
  );

  const loadedTemplate = (
    <PageHeader title="Dados do combo" onBack={() => history.goBack()}>
      {!isEditing ? (
        <Tabs defaultActiveKey="about">
          <Tabs.TabPane tab="Dados gerais" key="about">
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              autoComplete="off"
            >
              <Row gutter={16}>
                <Col md={8}>
                  <Row gutter={16}>
                    <Col md={12}>
                      <Form.Item
                        name="name"
                        label="Nome"
                        rules={[{ required: true, max: 512, min: 2 }]}
                      >
                        <Input
                          placeholder="Digite o nome do combo"
                          disabled={true}
                        />
                      </Form.Item>
                      <Form.Item name="published" valuePropName="checked">
                        <Checkbox disabled={true}>Publicado?</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="template_id"
                        label="Template do combo"
                        rules={[{ required: true }]}
                      >
                        {data && data.template && (
                          <DebounceSelect
                            placeholder="Selecione o template de combo"
                            showSearch
                            style={{ width: "100%" }}
                            labelProp="name"
                            valueProp={"id"}
                            disabled={true}
                            initialOptions={[data?.template]}
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col md={24}>
                      <Typography.Title level={5}>
                        Informações adicionais
                      </Typography.Title>
                      <Form.List name="additional_info">
                        {(fields) => (
                          <>
                            {!fields.length ? (
                              <Typography.Text type="secondary">
                                Sem informações adicionais!
                              </Typography.Text>
                            ) : null}
                            {fields.map((field, index) => (
                              <Form.Item
                                label={`Informação ${index + 1}`}
                                required={true}
                                key={field.key}
                              >
                                <Form.Item
                                  {...field}
                                  validateTrigger={["onChange", "onBlur"]}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message:
                                        "Preencha este campo, ou remova ele.",
                                    },
                                  ]}
                                  noStyle
                                  style={{ display: "flex" }}
                                >
                                  <Input
                                    placeholder="Digite a informação..."
                                    style={{ width: "calc(100% - 30px)" }}
                                    disabled
                                  />
                                </Form.Item>
                              </Form.Item>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} style={{ display: "flex" }}>
                  <Divider type="vertical" style={{ height: "100%" }} />
                  <div>
                    <Typography.Title level={3}>
                      Produtos e ofertas
                    </Typography.Title>

                    <ProductsAndOffersList>
                      {treeNodes &&
                        treeNodes.map((p) => {
                          return (
                            <ProductsAndOffersListPItem key={p.key}>
                              <div>{p.title}</div>

                              {((p.children || []) as any[]).map((o) => {
                                return (
                                  <ProductsAndOffersListOItem key={o.key}>
                                    <div>
                                      <Typography.Text>
                                        {o.title}
                                      </Typography.Text>
                                      <br />

                                      {+o.productID ===
                                        ProductTypesId.Internet && (
                                        <Typography.Text delete>
                                          Velocidade original:{" "}
                                          {o.original_speed}
                                        </Typography.Text>
                                      )}

                                      {+o.productID ===
                                        ProductTypesId.Internet && (
                                        <>
                                          <br />
                                          <Typography.Text>
                                            Nova velocidade:{" "}
                                            {speedPlus &&
                                            speedPlus.length > 0 &&
                                            +o.productID ===
                                              ProductTypesId.Internet
                                              ? `${speedPlus[0].plus_download} MB`
                                              : o.original_speed}
                                          </Typography.Text>
                                          <br />
                                        </>
                                      )}

                                      {o.price ? (
                                        <>
                                          <Typography.Text
                                            type="secondary"
                                            delete={true}
                                          >
                                            Preço original : {o.price}
                                          </Typography.Text>
                                          <br />
                                        </>
                                      ) : null}

                                      <Typography.Text type="secondary">
                                        Novo preço:{" "}
                                        {+o.productID ===
                                          ProductTypesId.Internet &&
                                        o.custom_offers_price
                                          ? o.custom_offers_price
                                          : o.price}
                                      </Typography.Text>

                                      {infoCombo &&
                                        infoCombo.length > 0 &&
                                        +o.productID ===
                                          ProductTypesId.Internet && (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <Divider />

                                            <div>Informações do combo</div>
                                            {infoCombo[0].info.map(
                                              (val: any, idx: any) => (
                                                <Typography.Text
                                                  type="secondary"
                                                  key={idx}
                                                >
                                                  {val + "."}
                                                </Typography.Text>
                                              )
                                            )}
                                            <Divider />
                                          </div>
                                        )}
                                    </div>
                                  </ProductsAndOffersListOItem>
                                );
                              })}
                            </ProductsAndOffersListPItem>
                          );
                        })}
                    </ProductsAndOffersList>

                    <Divider />

                    <div>
                      <Typography.Title level={4}>
                        Soma total dos valores das ofertas:
                      </Typography.Title>
                      <Typography.Text
                        type="secondary"
                        strong
                        style={{ fontSize: 18 }}
                      >
                        {formatPrice(totalSumPrice || 0)}
                      </Typography.Text>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>

            <Row justify="end">
              {!isEditing ? (
                <Space>
                  <Popconfirm
                    title="Tem certeza, que deseja deletar o combo?!"
                    okType="default"
                    onConfirm={async () => await handleDelete()}
                    okText="Deletar"
                    cancelText="Cancelar"
                  >
                    <Button
                      key="bt-page-delete"
                      color="danger"
                      danger
                      type="primary"
                    >
                      Deletar
                    </Button>
                  </Popconfirm>

                  <Button
                    key={"edit-page"}
                    disabled={isDeletingLoading}
                    onClick={() => setIsEditing(true)}
                    type="primary"
                    htmlType="button"
                  >
                    Editar
                  </Button>
                </Space>
              ) : null}
            </Row>
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <CombosEdit
          formData={data}
          offers={offers}
          products={products}
          onCancel={onCancel}
          onFinished={onFinish}
        />
      )}
    </PageHeader>
  );

  return <Container>{loading ? loadingTemplate : loadedTemplate}</Container>;
};

export default CombosAbout;
