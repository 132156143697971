import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Descriptions,
  notification,
  Popconfirm,
  Form,
  Input,
  Space,
  Checkbox,
} from "antd";

import api from "../../../../../api";


import { ButtonsFlex } from './styles';
import { useAuth } from "../../../../../providers/Auth";
import { useParams } from "react-router-dom";
import { documentCPF, phoneNumber } from "../../../../../utils/validators";
import ReactInputMask from "react-input-mask";
import { formatCpfCnpj, formatPhoneNumber, getStatusText } from "../../../../../utils/functions";



export interface ContactDetailsProps {
  details?: Partial<any>;

  franchiseId?: string;

  onCancel?: () => void;

  onDataLoading?: (isLoading: boolean) => void;
  onEdit?: () => void;
  onEditLoading?: (isLoading: boolean) => void;
  onDelete?: () => void;
  onDeleteLoading?: (isLoading: boolean) => void;
}


const ContactDetails: React.FC<ContactDetailsProps> = ({
  details,

  franchiseId: staticFranchiseId,

  onCancel,
  onDataLoading,
  onEdit,
  onEditLoading,
  onDelete,
  onDeleteLoading,
}) => {
  const { franchisee_id } = useAuth();

  const { franchiseId: paramRouteFranchiseId } = useParams<{ zoneId: string; franchiseId: string; }>();

  const [franchiseId] = useState(staticFranchiseId || paramRouteFranchiseId || franchisee_id);

  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLoading, setIsEditingLoading] = useState(false);

  const loadInfo = async (id: any) => {
    onDataLoading && onDataLoading(true);
    try {
      const { data } = await api.get(`/franchisees/${franchiseId}/attendant/${id}/`);

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      onDataLoading && onDataLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    onDeleteLoading && onDeleteLoading(true);
    try {
      await api.delete(`/franchisees/${franchiseId}/attendant/${id}/`);
      notification.success({
        message: "Atendente deletado com sucesso",
      });

      setData(null);
      setIsEditing(false);

      onDelete && onDelete();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao deletar o atendente. Tente novamente., " +
          error,
      });
    } finally {
      onDeleteLoading && onDeleteLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setIsEditingLoading(true);
    onEditLoading && onEditLoading(true);

    try {
      await api.patch(`/franchisees/${franchiseId}/attendant/${data?.id}`, values);

      notification.success({
        message: "Atendente atualizado com sucesso",
      });

      setData({
        ...data,
        ...values,
      });

      onReset();
      setIsEditing(false);

      onEdit && onEdit();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar o atendente. Tente novamente., " +
          error,
      });
    } finally {
      onEditLoading && onEditLoading(false);
      setIsEditingLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const detailsTemplate = () => {
    if (data) {
      return (<>
        <Descriptions title="Informações Gerais" column={{ md: 2 }}>
          <Descriptions.Item label="Nome Completo">
            {data.name}
          </Descriptions.Item>
          <Descriptions.Item label="Documento">
            {formatCpfCnpj(data.document)}
          </Descriptions.Item>
          <Descriptions.Item label="Número">
            {formatPhoneNumber(data.phone_number)}
          </Descriptions.Item>
          <Descriptions.Item label="E-mail">
            {data.email}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {getStatusText(data.status)}
          </Descriptions.Item>
        </Descriptions>
      </>);
    }
    return null;
  };

  const editTemplate = () => {
    if (data) {
      return (<>
        <Form layout="vertical" form={form} name="control-hooks" onFinish={onFinish}>
          <Form.Item name="name" label="Nome Completo" rules={[{ required: true, max: 512, min: 3 }]}>
            <Input placeholder='Digite o nome do atendente' />
          </Form.Item>
          <Form.Item name="email" label="E-mail" rules={[{ required: true, type: 'email' }]}>
            <Input type={'email'} placeholder='Digite o e-mail do atendente' />
          </Form.Item>
          <Form.Item name="phone_number" label="Número"
            rules={[
              { required: true },
              { validator: phoneNumber }
            ]}
          >
            <ReactInputMask mask="(99) \9 9999-9999">
              {(props: Partial<any>) => <Input {...props} placeholder='(00) 9 0000-0000' />}
            </ReactInputMask>
          </Form.Item>
          <Form.Item name="document" label="Documento"
            rules={[
              { required: true },
              { validator: documentCPF }
            ]}
          >
            <ReactInputMask mask="999.999.999-99">
              {(props: Partial<any>) => <Input {...props} placeholder='000.000.000-00' />}
            </ReactInputMask>
          </Form.Item>
          <Form.Item name="password" label="Senha" rules={[{ min: 8, max: 24 }]} extra="Caso não seja fornecido, a senha não será alterada!">
            <Input.Password placeholder='Digite a senha do atendente' />
          </Form.Item>
          <Form.Item name="status" valuePropName="checked">
            <Checkbox>Ativo?</Checkbox>
          </Form.Item>
        </Form>
      </>);
    }
    return null;
  };

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      loadInfo(details?.id)
        .then((response) => {
          !didCancel && setData(response);
        })
        .catch(() => notification.error({ message: "Erro ao carregar dados!" }));
    }

    return () => {
      didCancel = true;
    };
  }, []); // No 'details' dep. is needed

  return (
    <Drawer
      width="40%"
      title="Detalhes do atendente"
      visible={data !== null}
      onClose={() => { setData(null); setIsEditing(false); onCancel && onCancel(); }}

      maskClosable={!isEditingLoading}
      closable={!isEditingLoading}
    >
      {data !== null && (
        <>
          {isEditing ? editTemplate() : detailsTemplate()}

          <ButtonsFlex>
            <Space>
              {!isEditing ? <Popconfirm
                title="Tem certeza, que deseja deletar o atendente?!"
                okType="default"
                onConfirm={async () => await handleDelete(data.id)}
                okText="Deletar"
                cancelText="Cancelar"
              >
                <Button
                  key="bt--modal-prod-delete"
                  color="danger"
                  danger
                  type="primary"
                >
                  Deletar
                </Button>
              </Popconfirm> : null}

              {!isEditing ? <Button onClick={() => { setIsEditing(true); form.setFieldsValue({ ...data }) }}>
                Editar
              </Button> : null}
              {isEditing ? <>
                <Button
                  onClick={() => { onReset(); setIsEditing(false); }}
                  disabled={isEditingLoading}
                > Cancelar </Button>
                <Button
                  onClick={() => { form.submit(); }}
                  loading={isEditingLoading}
                  disabled={isEditingLoading}
                  type="primary"
                >
                  Salvar
                </Button>
              </> : null}
            </Space>
          </ButtonsFlex>
        </>
      )}
    </Drawer>
  );
}

export default ContactDetails;
