import React from "react";
import "./App.less";

import GlobalProvider from "./providers";
import Routes from "./routes";

const App: React.FC = () => {
  return (
    <GlobalProvider>
      <Routes />
    </GlobalProvider>
  );
};

export default App;
