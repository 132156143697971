import styled from 'styled-components';



export const ButtonsFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;

  button:not(:last-child) {
    margin-right: 10px;
  }
`;
