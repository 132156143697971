import React, { useState, useEffect } from 'react';

import { Space, Button, Typography, Divider, Tag } from 'antd';

import { formatPrice, hash } from '../../../../../utils/functions';

import {
  Container,
  ProductsAndOffersList,
  ProductsAndOffersListOItem,
  ProductsAndOffersListPItem,
} from './styles';

const Resume: React.FC<{
  formData: any;
  products: any[];
  offers: { [x: string]: any[] };
  onNext: () => void;
  onPrevius: () => void;

  loading?: boolean;
}> = ({
  formData: formDataInitial,
  products: productsInitial,
  offers: offersInitial,
  loading,
  onNext,
  onPrevius,
}) => {
  const [formData, setFormData] = useState<any>(formDataInitial || {});
  const [products, setProducts] = useState<any[]>(productsInitial || []);
  const [offers, setOffers] = useState<{ [x: string]: any[] }>(
    offersInitial || {},
  );
  const [treeNodes, setTreeNodes] = useState<any[]>([]);
  const [totalSumPrice, setTotalSumPrice] = useState(0);

  const buildTreeNodes = () => {
    let price = 0;

    const treeNodes = products.map(p => {
      const children: any[] = [];

      if (p.allowFranchiseSelect) {
        children.push({
          key: `${hash()}`,
          expanded: true,
          title: `As ofertas para este produto serão selecionadas pelo o franqueado!`,
        });
      } else {
        const po = offers[p.id] || [];
        if (!po.length) {
          children.push({
            key: `${hash()}`,
            expanded: true,
            title: `Não há ofertas selecionadas para este produto!`,
          });
        } else {
          po.forEach(o => {
            price += +`${o.price || 0}`;

            children.push({
              key: `${hash()}`,
              expanded: true,
              title: `#${o.id} - ${o.title}`,
              price: formatPrice(o.price),
            });
          });
        }
      }

      return {
        key: `${hash()}`,
        title: `#${p.id} - ${p.name}`,
        children,
        expanded: true,
      };
    });

    setTotalSumPrice(price);
    setTreeNodes(treeNodes);
  };

  useEffect(() => {
    let didCancel = false;
    !didCancel && setFormData(formDataInitial || {});

    return () => {
      didCancel = true;
    };
  }, [formDataInitial]);

  useEffect(() => {
    let didCancel = false;
    !didCancel && setProducts(productsInitial || []);
    !didCancel && setOffers(offersInitial || {});
    !didCancel && buildTreeNodes();

    return () => {
      didCancel = true;
    };
  }, [productsInitial, offersInitial]);

  return (
    <Container>
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <div>
            <Typography.Title level={3}>Dados gerais</Typography.Title>

            <Space direction="vertical">
              <div>
                <Space>
                  <Typography.Text>Nome:</Typography.Text>
                  <Typography.Text>{formData.name}</Typography.Text>
                </Space>
              </div>

              <div>
                <Space>
                  <Typography.Text>Publicado?:</Typography.Text>
                  <Tag color={formData.published ? 'green' : 'volcano'}>
                    {formData.published ? 'Sim' : 'Não'}
                  </Tag>
                </Space>
              </div>
            </Space>
          </div>

          <Divider />

          <div>
            <Typography.Title level={3}>Produtos e ofertas</Typography.Title>

            <ProductsAndOffersList>
              {treeNodes &&
                treeNodes.map(p => {
                  return (
                    <ProductsAndOffersListPItem key={p.key}>
                      <div>{p.title}</div>

                      {((p.children || []) as any[]).map(o => {
                        return (
                          <ProductsAndOffersListOItem key={o.key}>
                            <div>
                              {o.title} <br />
                              {o.price ? (
                                <Typography.Text type="secondary">
                                  Preço: {o.price}
                                </Typography.Text>
                              ) : null}
                            </div>
                          </ProductsAndOffersListOItem>
                        );
                      })}
                    </ProductsAndOffersListPItem>
                  );
                })}
            </ProductsAndOffersList>

            <Divider />

            <div>
              <Typography.Title level={4}>
                Soma total dos valores das ofertas:
              </Typography.Title>
              <Typography.Text type="secondary" strong style={{ fontSize: 18 }}>
                {formatPrice(totalSumPrice || 0)}
              </Typography.Text>
            </div>
          </div>
        </div>

        <div style={{ display: `flex`, justifyContent: `flex-end` }}>
          <Space>
            <Button disabled={loading} onClick={() => onPrevius()}>
              Voltar
            </Button>
            <Button loading={loading} type="primary" onClick={() => onNext()}>
              Adicionar
            </Button>
          </Space>
        </div>
      </Space>
    </Container>
  );
};

export default Resume;
