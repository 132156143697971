import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Tabs,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import api from "../../../../../api";
import CheckPermission from "../../../../../components/CheckPermission";
import DebounceSelect from "../../../../../components/DebounceSelect";
import { UserType } from "../../../../../utils/enums";
import { deepMerge } from "../../../../../utils/functions";
import { useAuth } from "./../../../../../providers/Auth";
import Combos from "./Combos";
import Contact from "./Contact";
import Offers from "./Offers";
import Office from "./Office";
import Sliders from "./Sliders";
import { Container, ContainerLoading } from "./styles";

/*const { Option } = Select;*/

const ProductsAbout: React.FC<{ franchiseId?: string; zoneId?: string }> = ({
  franchiseId: franchiseIdStatic,
  zoneId: zoneIdStatic,
}) => {
  const { franchiseId: franchiseIdDynamic, zoneId: zoneIdDynamic } = useParams<{
    zoneId: string;
    franchiseId: string;
  }>();
  const { franchisee_id: franchiseIdLogin, checkPermission /* userTypeIs */ } =
    useAuth();
  const franchiseId =
    franchiseIdStatic || franchiseIdDynamic || franchiseIdLogin;
  const zoneId = zoneIdStatic || zoneIdDynamic;
  const history = useHistory();
  const [form] = Form.useForm();
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLoading, setIsEditingLoading] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const [products, setProducts] = useState<SelectProps["options"]>([]);

  const onLoadProduct = async () => {
    try {
      const { data } = await api.get("/product");

      setProducts(
        data.data.map((product: any) => ({
          label: product.name,
          value: product.id,
        }))
      );
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    }
  };

  async function fetchCityList(cityname: string): Promise<any[]> {
    try {
      const { data } = await api.get("/cities", {
        params: {
          pageSize: 999,
          offset: 0,
          ...(!cityname ? {} : { filters: { name: cityname } }),
        },
      });

      return data.data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    }
  }

  const loadInfo = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/franchisees/${franchiseId}/zone/${zoneId}`
      );
      await onLoadProduct();
      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setLoading(false);
    }
  }, [franchiseId, zoneId]);

  const handleDelete = useCallback(async () => {
    setIsDeletingLoading(true);
    try {
      await api.delete(`/franchisees/${franchiseId}/zone/${zoneId}`);
      notification.success({
        message: "Zona deletada com sucesso",
      });

      setData(null);

      history.goBack();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao deletar a zona. Tente novamente., " + error,
      });
    } finally {
      setIsDeletingLoading(false);
    }
  }, [franchiseId, zoneId, history]);

  const onFinish = async (values: any) => {
    setIsEditingLoading(true);
    let clone: any = deepMerge({}, values);

    if ("city_id" in clone && typeof clone.city_id === "string") {
      try {
        clone.city_id = +clone.city_id;
      } catch (_) {}
    } else if ("city_id" in clone && typeof clone.city_id === "object") {
      try {
        clone.city_id = +clone.city_id.value;
      } catch (_) {}
    }

    try {
      await api.patch(`/franchisees/${franchiseId}/zone/${zoneId}`, clone);

      notification.success({
        message: "Zona atualizada com sucesso",
      });

      const dataMerged = {
        ...data,
        ...clone,
      };

      setIsEditing(false);

      setTimeout(setData, 10, dataMerged);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar a zona. Tente novamente., " + error,
      });
    } finally {
      setIsEditingLoading(false);
    }
  };

  useEffect(() => {
    let didCancel = false;

    /*loadCities()
      .then((responseCities) => {
        !didCancel && setCities(responseCities.data);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }))
      .finally(() => {
        !didCancel &&*/
    loadInfo()
      .then((response) => {
        const responseProcessed = {
          ...response,
          city_id: response.city.id,
        };

        !didCancel && setData(responseProcessed);
        !didCancel && form.setFieldsValue(responseProcessed);
        !didCancel && setLoaded(true);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }))
      .finally(() => {});
    /*});*/

    return () => {
      didCancel = true;
    };
  }, [form, loadInfo /*loadCities*/]); // No 'details' dep. is needed

  const loadingTemplate = (
    <ContainerLoading>
      <Spin></Spin>
    </ContainerLoading>
  );

  const tabsTemplate = (
    <>
      <Divider />

      <Tabs defaultActiveKey="offers">
        <Tabs.TabPane tab="Ofertas" key="offers">
          {data && franchiseId && zoneId ? (
            <Offers franchiseId={franchiseId} zoneId={zoneId} />
          ) : null}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Combos" key="combos">
          {data && franchiseId && zoneId ? (
            <Combos franchiseId={franchiseId} zoneId={zoneId} />
          ) : null}
        </Tabs.TabPane>

        {checkPermission(UserType.Admin) ? (
          <Tabs.TabPane tab="Slides" key="sliders">
            {data && franchiseId && zoneId ? (
              <Sliders franchiseId={franchiseId} zoneId={zoneId} />
            ) : null}
          </Tabs.TabPane>
        ) : null}

        <Tabs.TabPane tab="Contatos" key="contacts">
          {data && franchiseId && zoneId ? (
            <Contact franchiseId={franchiseId} zoneId={zoneId} />
          ) : null}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Escritórios" key="offices">
          {data && franchiseId && zoneId ? (
            <Office franchiseId={franchiseId} zoneId={zoneId} />
          ) : null}
        </Tabs.TabPane>
      </Tabs>
    </>
  );

  const loadedTemplate = (
    <PageHeader title="Dados da zona" onBack={() => history.goBack()}>
      <Tabs defaultActiveKey="about">
        <Tabs.TabPane tab="Dados gerais" key="about">
          {loaded && (
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              autoComplete="off"
            >
              <Row gutter={16}>
                <Col md={8}>
                  <Form.Item
                    name="city_id"
                    label="Cidade"
                    rules={[{ required: true }]}
                    shouldUpdate={() => false}
                  >
                    <DebounceSelect
                      placeholder="Selecione uma cidade"
                      startsWithSearch={true}
                      showSearch
                      fetchOptions={fetchCityList}
                      style={{ width: "100%" }}
                      labelProp="name"
                      valueProp="id"
                      disabled={true}
                      bordered={false}
                      showArrow={false}
                      initialOptions={[
                        {
                          ...((data || {})?.city || {}),
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col md={8}>
                  <Form.Item
                    name="zone"
                    label="Zona"
                    rules={[{ required: true, max: 512, min: 2 }]}
                  >
                    <Input placeholder="Digite a zona" disabled={!isEditing} />
                  </Form.Item>
                </Col>

                <Col md={8}>
                  <Form.Item
                    name="district"
                    label="Distrito"
                    rules={[{ required: true, max: 512, min: 2 }]}
                  >
                    <Input
                      placeholder="Digite o distrito"
                      disabled={!isEditing}
                    />
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item
                    name="visible_products"
                    label="Produtos visíveis"
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      mode="multiple"
                      options={products}
                      disabled={!isEditing}
                      style={{ width: "100%" }}
                      placeholder="Selecionar produtos visíveis"
                    />
                  </Form.Item>
                </Col>

                <Col md={8}>
                  <Form.Item name="status" valuePropName="checked">
                    <Checkbox disabled={!isEditing}>Ativa?</Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <CheckPermission userType={UserType.Admin}>
                <Row justify="end">
                  <Col>
                    {isEditing ? (
                      <Space>
                        <Button
                          key={"cancel-edit-franchisees"}
                          onClick={() => {
                            setIsEditing(false);
                            form.setFieldsValue(data);
                          }}
                          disabled={isEditingLoading}
                          htmlType="button"
                        >
                          Cancelar
                        </Button>
                        <Button
                          key={"save-franchisees"}
                          disabled={isEditingLoading}
                          loading={isEditingLoading}
                          type="primary"
                          htmlType="submit"
                        >
                          Salvar dados
                        </Button>
                      </Space>
                    ) : null}
                  </Col>
                </Row>
              </CheckPermission>
            </Form>
          )}

          <CheckPermission userType={UserType.Admin}>
            <Row justify="end">
              {!isEditing ? (
                <Space>
                  <Popconfirm
                    title="Tem certeza, que deseja deletar a zona?!"
                    okType="default"
                    onConfirm={async () => await handleDelete()}
                    okText="Deletar"
                    cancelText="Cancelar"
                  >
                    <Button
                      key="bt-prod-delete"
                      color="danger"
                      danger
                      type="primary"
                    >
                      Deletar
                    </Button>
                  </Popconfirm>

                  <Button
                    key={"edit-franchisees"}
                    disabled={isDeletingLoading}
                    onClick={() => setIsEditing(true)}
                    type="primary"
                    htmlType="button"
                  >
                    Editar
                  </Button>
                </Space>
              ) : null}
            </Row>
          </CheckPermission>
        </Tabs.TabPane>
      </Tabs>

      {tabsTemplate}
    </PageHeader>
  );

  return <Container>{loading ? loadingTemplate : loadedTemplate}</Container>;
};

export default ProductsAbout;
