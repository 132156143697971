import {
  Button,
  Checkbox,
  Form,
  Input,
  notification,
  Space,
  Steps,
} from 'antd';
import React, { useEffect, useState } from 'react';

import api from '../../../../../api';
import { deepCopyFunction } from '../../../../../utils/functions';
import validator from '../../../../../utils/validator';
import CombosEditOffers from './CombosEditOffers';
import CombosEditProducts from './CombosEditProducts';
import Resume from './Resume';
import { Container } from './styles';

const CombosEdit: React.FC<{
  registerId: any;
  formData: any;
  products: any;
  offers: any;
  onCancel: () => void;
  onFinished: (newValues: any) => void;
}> = ({
  registerId,
  formData: formDataInitial,
  products: productsInitial,
  offers: offersInitial,
  onCancel,
  onFinished,
}) => {
  const [formData, setFormData] = useState<any>(formDataInitial);
  const [stepForm0] = Form.useForm();
  const [products, setProducts] = useState<any[]>(productsInitial || []);
  const [offers, setOffers] = useState<{ [x: string]: any[] }>(
    offersInitial || {},
  );
  const [stepIndex, setStepIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const onReset = () => {
    setFormData({});
    stepForm0.resetFields();
    setProducts([]);
    setOffers({});
    setStepIndex(0);
  };

  const cancel = () => {
    onCancel && onCancel();
  };

  const onFinish = async () => {
    const handlers: { [s: number]: () => any } = {
      0: () => {
        stepForm0
          .validateFields()
          .then(() => {
            setFormData(stepForm0.getFieldsValue());
            setStepIndex(stepIndex + 1);
          })
          .catch(info => {
            console.log('Validate Failed: ', info);
          });
      },
      1: () => {
        setStepIndex(stepIndex + 1);
      },
      2: () => {
        const err = validator(products, offers);
        console.log('Message validator error: ', err);
        if (!err) {
          setStepIndex(stepIndex + 1);
        } else {
          notification.warn({
            message: err,
          });
        }
      },
      3: async () => {
        const cloneForm = deepCopyFunction(formData);
        const cloneProducts: any[] = deepCopyFunction(products);
        const cloneOffers: { [x: string]: any[] } = deepCopyFunction(offers);

        const finalNonMapped = {
          ...cloneForm,
          products: cloneProducts.slice(0).filter(i => {
            return (
              (cloneOffers[i.id] || []).length > 0 || i.allowFranchiseSelect
            );
          }),
          offers: [
            ...Object.keys(cloneOffers).reduce(
              (a: any[], v) => [
                ...a,
                ...(Array.isArray(cloneOffers[v])
                  ? cloneOffers[v]
                  : [cloneOffers[v]]),
              ],
              [],
            ),
          ],
          root_products_id: cloneProducts
            .slice(0)
            .filter(i => !i.join_with)
            .map(i => +i.id),
        };

        const final = {
          ...cloneForm,
          products: cloneProducts
            .slice(0)
            .filter(i => {
              return (
                (cloneOffers[i.id] || []).length > 0 || i.allowFranchiseSelect
              );
            })
            .map(v => +v.id),
          offers: [
            ...Object.keys(cloneOffers)
              .reduce(
                (a: any[], v) => [
                  ...a,
                  ...(Array.isArray(cloneOffers[v])
                    ? cloneOffers[v]
                    : [cloneOffers[v]]),
                ],
                [],
              )
              .map(v => [+v.product.id, +v.id]),
          ],
          root_products_id: cloneProducts
            .slice(0)
            .filter(i => !i.join_with)
            .map(i => +i.id),
        };

        final.published = !!final.published;

        setLoading(true);
        try {
          await api.patch(`/combo/${registerId}`, final);

          notification.success({
            message: 'Combo atualizado com sucesso',
          });

          onReset();

          setLoading(false);

          onFinished && onFinished(finalNonMapped);
        } catch (error) {
          notification.error({
            message:
              'Ocorreu algum erro ao atualizar o combo. Tente novamente., ' +
              error,
          });
          setLoading(false);
        }
      },
    };

    return handlers[stepIndex]();
  };

  useEffect(() => {
    let didCancel = false;

    !didCancel && stepForm0.setFieldsValue(formData);

    return () => {
      didCancel = true;
    };
  });

  return (
    <Container>
      <Space direction="vertical" style={{ width: `100%` }}>
        <Steps current={stepIndex}>
          <Steps.Step title="Dados gerais" />
          <Steps.Step title="Produtos" />
          <Steps.Step title="Ofertas" />
          <Steps.Step title="Resumo" />
        </Steps>

        <div style={{ height: 15 }}></div>

        {stepIndex === 0 && (
          <Form
            layout="vertical"
            form={stepForm0}
            name="control-hooks"
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              label="Nome"
              rules={[{ required: true, max: 512, min: 2 }]}
            >
              <Input placeholder="Digite o nome do combo" />
            </Form.Item>
            <Form.Item name="published" valuePropName="checked">
              <Checkbox>Publicado?</Checkbox>
            </Form.Item>

            <div style={{ display: `flex`, justifyContent: `flex-end` }}>
              <Space>
                <Button onClick={() => cancel()}>Cancelar</Button>

                <Button type="primary" htmlType="submit">
                  Próximo
                </Button>
              </Space>
            </div>
          </Form>
        )}

        {stepIndex === 1 && (
          <CombosEditProducts
            products={products}
            onNext={onFinish}
            onPrevius={() => setStepIndex(stepIndex - 1)}
            onCancel={() => cancel()}
            onChanged={values => {
              setProducts(values);
              const clonedOffers = deepCopyFunction(offers);
              const offersPrdtsKeys = Object.keys(clonedOffers);
              const newOffers = offersPrdtsKeys
                .filter(k => {
                  return values.some(v => v.id === k);
                })
                .reduce((o, k) => {
                  const product = values.find(v => v.id === k);
                  let offersFromProduct: any[] = clonedOffers[k];

                  if (offersFromProduct.length > product.rule.max) {
                    offersFromProduct = offersFromProduct
                      .slice(0)
                      .slice(0, product.rule.max);
                  }

                  return {
                    ...o,
                    [k]: offersFromProduct,
                  };
                }, {});

              setOffers(newOffers);
              setOffers(() => newOffers);
            }}
            onRemoved={() => {}}
            onInserted={() => {}}
          />
        )}

        {stepIndex === 2 && (
          <CombosEditOffers
            products={products}
            offers={offers}
            onNext={onFinish}
            onPrevius={() => setStepIndex(stepIndex - 1)}
            onCancel={() => cancel()}
            onChanged={values => {
              setOffers(values);
            }}
            onProductsChanged={values => {
              setProducts(values);
            }}
            onRemoved={() => {}}
            onInserted={() => {}}
          />
        )}

        {stepIndex === 3 && (
          <Resume
            formData={formData}
            products={products}
            offers={offers}
            onNext={onFinish}
            onPrevius={() => setStepIndex(stepIndex - 1)}
            onCancel={() => cancel()}
            loading={loading}
          />
        )}
      </Space>
    </Container>
  );
};

export default CombosEdit;
