import { ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  notification,
  PageHeader,
  Row,
  Table,
  TableColumnType,
  Tag,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../api";
import {
  formatPhoneNumber,
  getStatusFranchisorLeadColor,
  getStatusFranchisorLeadText,
} from "../../../utils/functions";
import { Container } from "./styles";

const defaultPageSize = 10;

const Leads: React.FC = () => {
  const history = useHistory();

  const [shouldReloadTable, setShouldReloadTable] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: defaultPageSize,
    showSizeChanger: true,
  });

  const loadData = useCallback(async (params: any) => {
    setTableLoading(true);

    const { current, pageSize, sortField, sortOrder, filters } = params;

    try {
      const { data } = await api.get(`/franchisor-lead`, {
        params: {
          page: current,
          pageSize: pageSize,
          offset: (current - 1) * pageSize,
          ...(filters ? { filters } : {}),
          ...(sortField ? { order_by: sortField } : {}),
          ...(sortOrder ? { sort_by: sortOrder } : {}),
        },
      });

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setTableLoading(false);
    }
  }, []);

  const onHandleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (!pagination) return;

    loadData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...pagination,
      ...filters,
    })
      .then((response) => {
        setTablePagination((old) => ({
          ...old,
          ...pagination,
          total: response.total,
        }));
        setData(response.data);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));
  };

  const onHandleOpenMoreDetails = async (row: Partial<any>) => {
    history.push(`/franchisor-lead/${row.id}`);
  };

  const onHandleReloadData = () => setShouldReloadTable(!shouldReloadTable);

  const tableCols: TableColumnType<any>[] = [
    {
      key: "id",
      title: "#",
      dataIndex: "id",
      width: "70px",
      sorter: true,
    },
    {
      key: "name",
      title: "Nome Completo",
      dataIndex: "name",
      sorter: true,
    },
    {
      key: "email",
      title: "E-mail",
      dataIndex: "email",
      width: "200px",
      sorter: true,
    },
    {
      key: "city",
      title: "Cidade",
      dataIndex: "city",
      sorter: false,
      render: (city: any) => `${city.name} - ${city.uf}`,
    },
    {
      key: "district",
      title: "Bairro",
      dataIndex: "district",
      sorter: true,
      width: "130px",
    },
    {
      key: "phone_number",
      title: "Número",
      dataIndex: "phone_number",
      width: "130px",
      render: (value) => formatPhoneNumber(value || ""),
      sorter: true,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sorter: true,
      width: "90px",
      align: "center",
      render: (value) => (
        <Tag color={getStatusFranchisorLeadColor(value)}>
          {getStatusFranchisorLeadText(value)}
        </Tag>
      ),
    },
    {
      key: "message",
      title: "Mensagem",
      dataIndex: "message",
      sorter: true,
    },
    {
      key: "view",
      title: "Visualizar",
      width: "100px",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            key="bt-view"
            size="small"
            onClick={() => onHandleOpenMoreDetails(record)}
          >
            Detalhes
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    let didCancel = false;

    loadData({
      current: 1,
      pageSize: defaultPageSize,
    })
      .then((response) => {
        !didCancel && setData(response.data);
        setTablePagination((old) => ({ ...old, total: response.total }));
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [loadData, shouldReloadTable]);

  return (
    <Container>
      <PageHeader
        title="Leads (quero ser franqueado)"
        subTitle=""
        extra={[
          <Button
            key="bt-ds-reload"
            icon={<ReloadOutlined />}
            onClick={onHandleReloadData}
          >
            Recarregar dados
          </Button>,
        ]}
      >
        <Row style={{ marginTop: 12 }}>
          <Col md={24}>
            <Table
              size="middle"
              rowKey={(record: any) => record.id}
              dataSource={data}
              columns={tableCols}
              loading={tableLoading}
              pagination={tablePagination}
              onChange={onHandleTableChange}
            />
          </Col>
        </Row>
      </PageHeader>
    </Container>
  );
};

export default Leads;
