import styled from "styled-components";



export const Container = styled.div`
  background-color: white;
`;

export const ContainerLoading = styled.div`
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
`;

export const ProductsAndOffersList = styled.div`
  padding: 0;
  margin: 0;
`;

export const ProductsAndOffersListPItem = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
`;

export const ProductsAndOffersListOItem = styled.div`
  padding-left: 20px;
  margin: 0;
  margin-top: 7px;
`;
