import { Form, Modal, notification } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import api from "../../../../../../api";
import DebounceSelect from "../../../../../../components/DebounceSelect";

interface RegulationsNewProps {
  isVisible?: boolean;
  productId?: string;
  onCreate?: (insertedValue: any) => void;
  onCancel?: () => void;
}

const RegulationsNew: React.FC<RegulationsNewProps> = ({
  isVisible,
  onCreate,
  onCancel,
  productId: productIdStatic,
}) => {
  const { productId: paramRouteFranchiseId } = useParams<{
    productId: string;
  }>();

  const [productId] = useState(productIdStatic || paramRouteFranchiseId);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    const parsed =
      "regulation" in values ? JSON.parse(values.regulation.value) : {};
    setLoading(true);
    try {
      await api.post(
        `/product/${productId}/attach/regulation/${parsed.id}`,
        values
      );

      notification.success({
        message: "Regulamento inserido com sucesso",
      });

      onReset();
      onCreate && onCreate(parsed);

      setLoading(false);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao inserir o regulameto. Tente novamente., " +
          error,
      });
      setLoading(false);
    }
  };

  async function fetchRegulationsList(regulation: string): Promise<any[]> {
    try {
      const { data } = await api.get("/regulation", {
        params: {
          pageSize: 999,
          offset: 0,
          ...(!regulation ? {} : { filters: { name: regulation } }),
        },
      });

      return data.data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    }
  }

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={isVisible}
      title="Adicionar regulamento"
      closable={false}
      maskClosable={false}
      okText="Adicionar"
      okButtonProps={{
        htmlType: "submit",
        disabled: loading,
        loading: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch((info) => {
            console.log("Validate Failed: ", info);
          });
      }}
      onCancel={() => {
        onReset();
        onCancel && onCancel();
      }}
    >
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Form.Item
          name="regulation"
          label="Regulamento"
          rules={[{ required: true }]}
        >
          <DebounceSelect
            labelInValue
            placeholder="Selecione um regulamento"
            showSearch
            fetchOptions={fetchRegulationsList}
            style={{ width: "100%" }}
            labelProp="name"
            valueProp={false}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RegulationsNew;
