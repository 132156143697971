import {
  Form,
  Input,
  Modal,
  notification,
  InputNumber
} from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../../../../../../api";
import { useAuth } from "../../../../../../../providers/Auth";



interface OfficeNewProps {
  isVisible?: boolean;
  zoneId?: string;
  franchiseId?: string;
  onCreate?: () => void;
  onCancel?: () => void;
}

const OfficeNew: React.FC<OfficeNewProps> = ({
  isVisible,
  onCreate,
  onCancel,
  zoneId: staticZoneId,
  franchiseId: staticFranchiseId
}) => {
  const { franchisee_id } = useAuth();

  const { zoneId: paramRouteZoneId, franchiseId: paramRouteFranchiseId } = useParams<{ zoneId: string; franchiseId: string; }>();

  const [zoneId] = useState(staticZoneId || paramRouteZoneId);
  const [franchiseId] = useState(staticFranchiseId || paramRouteFranchiseId || franchisee_id);


  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await api.post(`/franchisees/${franchiseId}/zone/${zoneId}/office`, values);

      notification.success({
        message: "Escritório inserido com sucesso",
      });

      onReset();
      onCreate && onCreate();

      setLoading(false);
    } catch (error) {

      notification.error({
        message:
          "Ocorreu algum erro ao inserir o escritório. Tente novamente., " +
          error,
      });
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={isVisible}
      title="Adicionar escritório"
      closable={false}
      maskClosable={false}

      okText="Adicionar"
      okButtonProps={{
        htmlType: 'submit',
        disabled: loading,
        loading: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch(info => {
            console.log('Validate Failed: ', info);
          });
      }}
      onCancel={() => { onReset(); onCancel && onCancel(); }}
    >
      <Form layout="vertical" form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item name="name" label="Nome" rules={[{ required: true, max: 512, min: 2 }]}>
          <Input placeholder='Digite o nome do escritório' />
        </Form.Item>
        <Form.Item name="address_street" label="Endereço" rules={[{ required: true, max: 512, min: 2 }]}>
          <Input placeholder='Digite o endereço do escritório' />
        </Form.Item>
        <Form.Item name="address_number" label="Número" rules={[{ required: true, max: 512, min: 1 }]}>
          <Input placeholder='Digite o número do escritório' />
        </Form.Item>
        <Form.Item name="address_district" label="Bairro" rules={[{ required: true, max: 512, min: 1 }]}>
          <Input placeholder='Digite o bairro do escritório' />
        </Form.Item>
        <Form.Item name="geo_lat" label="Geolocalização (latitude)" rules={[{ required: true }]}>
          <InputNumber defaultValue={0} placeholder='Digite a latitude do escritório' style={{ display: 'block', width: '100%' }} />
        </Form.Item>
        <Form.Item name="geo_lng" label="Geolocalização (longitude)" rules={[{ required: true }]}>
          <InputNumber defaultValue={0} placeholder='Digite a longitude do escritório' style={{ display: 'block', width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
}


export default OfficeNew;
