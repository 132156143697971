import React, { useCallback, useEffect, useState } from "react";
import {
  PageHeader,
  Row,
  Col,
  Table,
  TableColumnType,
  Button,
  notification,
  Popconfirm,
} from "antd";

import { ReloadOutlined, PlusOutlined } from "@ant-design/icons";

import api from "../../../api";

import DigitalServiceNew from "./DigitalServiceNew";
import DigitalServiceDetails from "./DigitalServiceDetails";

import { Container } from "./styles";
import { buildFilterAttrs } from "../../../utils/filters";

const defaultPageSize = 10;

const DEFAULT_AVATAR_IMAGE_STYLE = {
  width: 30,
  height: 30,
  borderRadius: "50%",
};

const DigitalService: React.FC = () => {
  const [shouldReloadTable, setShouldReloadTable] = useState(false);
  const [inserting, setInserting] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: defaultPageSize,
    showSizeChanger: true,
  });

  const [detailsOpened, setDetailsOpened] = useState<any>(null);

  const loadData = useCallback(async (params: any) => {
    setTableLoading(true);

    const { current, pageSize, sortField, sortOrder, filters } = params;

    try {
      const { data } = await api.get("/digital-service", {
        params: {
          page: current,
          pageSize: pageSize,
          offset: (current - 1) * pageSize,
          ...(filters ? { filters } : {}),
          ...(sortField ? { order_by: sortField } : {}),
          ...(sortOrder ? { sort_by: sortOrder } : {}),
        },
      });

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setTableLoading(false);
    }
  }, []);

  const handleDelete = useCallback(async (id: any) => {
    try {
      await api.delete(`/digital-service/${id}/`);
      notification.success({
        message: "Serviço digital deletado com sucesso",
      });

      setDetailsOpened(null);

      setShouldReloadTable((oldState) => !oldState);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao deletar o serviço digital. Tente novamente., " +
          error,
      });
    }
  }, []);

  const onHandleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (!pagination) return;
    // console.log(filters);

    let newFilters: any = {};
    for (const key in filters) {
      if (filters[key] === null) continue;
      const value = filters[key];

      if (value.length > 1) {
        newFilters[key] = value;
        continue;
      }

      newFilters[key] = value[0];
    }

    loadData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...pagination,
      filters: newFilters,
    })
      .then((response) => {
        setTablePagination((old) => ({
          ...old,
          ...pagination,
          total: response.total,
        }));
        setData(response.data);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));
  };

  const onHandleOpenMoreDetails = async (row: Partial<any>) => {
    setDetailsOpened(row);
  };

  const onHandleReloadData = () => setShouldReloadTable(!shouldReloadTable);

  const tableCols: TableColumnType<any>[] = [
    {
      key: "icon_url",
      dataIndex: "icon_url",
      align: "center",
      width: "70px",
      render: (value) => (
        <img
          src={value}
          onError={(evt) =>
            (evt.target as HTMLElement).setAttribute(
              "src",
              "assets/images/svas/not-found.jpg"
            )
          }
          style={DEFAULT_AVATAR_IMAGE_STYLE}
          alt={value}
        />
      ),
      sorter: false,
    },
    {
      key: "id",
      title: "#",
      dataIndex: "id",
      width: "70px",
      sorter: true,
      ...buildFilterAttrs({
        searchLabel: "Código",
      }),
    },
    {
      key: "name",
      title: "Nome",
      dataIndex: "name",
      sorter: true,
      ...buildFilterAttrs({
        searchLabel: "Nome",
      }),
    },
    {
      key: "description",
      title: "Descrição",
      dataIndex: "description",
      width: "40%",
      ...buildFilterAttrs({
        searchLabel: "Descrição",
      }),
    },
    {
      key: "view",
      title: "Visualizar",
      width: "100px",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            key="bt-view"
            size="small"
            onClick={() => onHandleOpenMoreDetails(record)}
          >
            Detalhes
          </Button>
        );
      },
    },
    {
      key: "actions",
      title: "Ações",
      width: "100px",
      align: "center",
      render: (_, record) => {
        return (
          <Popconfirm
            title="Tem certeza, que deseja deletar o serviço digital?!"
            okType="default"
            onConfirm={async () => await handleDelete(record.id)}
            okText="Deletar"
            cancelText="Cancelar"
          >
            <Button
              key="bt-prod-delete"
              size="small"
              color="danger"
              danger
              type="primary"
            >
              Deletar
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  useEffect(() => {
    let didCancel = false;

    loadData({
      current: 1,
      pageSize: defaultPageSize,
    })
      .then((response) => {
        !didCancel && setData(response.data);
        setTablePagination((old) => ({ ...old, total: response.total }));
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [loadData, shouldReloadTable]);

  return (
    <Container>
      <DigitalServiceNew
        isVisible={inserting}
        onCancel={() => {
          setInserting(false);
        }}
        onCreate={() => {
          setInserting(false);
          onHandleReloadData();
        }}
      />

      <PageHeader
        title="Serviços digitais"
        subTitle=""
        extra={[
          <Button
            key="bt-ds-reload"
            icon={<ReloadOutlined />}
            onClick={onHandleReloadData}
          >
            Recarregar dados
          </Button>,
          <Button
            key="bt-ds-new"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setInserting(true)}
          >
            Novo
          </Button>,
        ]}
      >
        <Row style={{ marginTop: 12 }}>
          <Col md={24}>
            <Table
              size="middle"
              rowKey={(record: any) => record.id}
              dataSource={data}
              columns={tableCols}
              loading={tableLoading}
              pagination={tablePagination}
              onChange={onHandleTableChange}
            />
          </Col>
        </Row>
      </PageHeader>

      {detailsOpened ? (
        <DigitalServiceDetails
          details={detailsOpened}
          onCancel={() => setDetailsOpened(null)}
          onDataLoading={(v) => setTableLoading(v)}
          onDelete={() => {
            setDetailsOpened(null);
            onHandleReloadData();
          }}
          onDeleteLoading={(v) => setTableLoading(v)}
          onEdit={() => {
            onHandleReloadData();
          }}
          onEditLoading={(v) => setTableLoading(v)}
        />
      ) : null}
    </Container>
  );
};

export default DigitalService;
