import styled from "styled-components";

export const Container = styled.div`
`;

export const KanbanContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  padding-bottom: 10px;
`;

export const KanbanColumn = styled.div<{baseColor?: string}>`
  width: 300px;
  min-width: 300px;
  min-height: 500px;
  border: 1px solid #CCC;
  border-radius: 4px;
  background: ${p => p.baseColor ?? `rgb(202 216 224 / 4%)`};
`;

export const KanbanColumnHeader = styled.div`
  border-bottom: 1px solid #CCC;
  padding: 15px;
`;

export const KanbanColumnContent = styled.div`
  padding: 15px;

  .ant-card {
    border-radius: 8px;
    cursor: pointer;
    transition: background 300ms ease-in-out, box-shadow 300ms ease-in-out;

    .ant-card-body {
      padding: 15px;
    }

    &:hover {
      background: rgb(221 221 221 / 26%);
      box-shadow: 0px 0px 5px 0px rgb(158 158 158 / 7%);
    }
  }
`;
