import {
  Form,
  Input,
  notification,
  PageHeader,
  Row,
  Col,
  Button,
  Space,
  InputNumber,
  Checkbox,
  Typography,
  Spin,
  Select,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import api from "../../../../../../../api";

import { Container } from "./styles";
import { useAuth } from "../../../../../../../providers/Auth";
import FieldsOfferRegister from "../../../../../Products/About/Offers/FieldsOfferRegister";

const { Title } = Typography;
const { Option } = Select;

const OffersNew: React.FC<{ zoneId?: string; franchiseId?: string }> = ({
  zoneId: staticZoneId,
  franchiseId: staticFranchiseId,
}) => {
  const history = useHistory();

  const { franchisee_id } = useAuth();

  const { zoneId: paramRouteZoneId, franchiseId: paramRouteFranchiseId } =
    useParams<{ zoneId: string; franchiseId: string }>();

  const [zoneId] = useState(staticZoneId || paramRouteZoneId);
  const [franchiseId] = useState(
    staticFranchiseId || paramRouteFranchiseId || franchisee_id
  );

  const [form] = Form.useForm();
  const [isInsertingLoading, setIsInsertingLoading] = useState(false);

  const [products, setProducts] = useState<any[]>([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [productData, setProductData] = useState<Partial<any>>({});

  const loadProducts = useCallback(async () => {
    setLoadingProducts(true);
    try {
      const { data } = await api.get("/product", {
        params: {
          pageSize: 999,
          offset: 0,
        },
      });

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setLoadingProducts(false);
    }
  }, []);

  const onFinish = async (values: any) => {
    setIsInsertingLoading(true);

    if ("product_id" in values && typeof values.product_id === "string") {
      try {
        values.product_id = +values.product_id;
      } catch (_) {}
    }

    values.status = !!values.status;

    try {
      await api.post(`/franchisees/${franchiseId}/zone/${zoneId}/offer`, {
        ...values,
        status_in_combo: values.status_in_combo
          ? values.status_in_combo
          : false,
      });
      notification.success({
        message: "Oferta inserida com sucesso",
      });

      history.goBack();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao inserir a oferta. Tente novamente., " + error,
      });
    } finally {
      setIsInsertingLoading(false);
    }
  };

  const onChangedProductId = (value: number) => {
    if (value) {
      form.setFieldsValue({
        ...(form.getFieldsValue() || {}),
        metadata: {},
      });
      form.resetFields([`metadata`]);
      const product = products
        .slice(0)
        .filter((item) => item.id === value)
        .shift();
      if (product) {
        setProductData(product);
      }
    } else {
      setProductData({});
    }
  };

  useEffect(() => {
    let didCancel = false;

    loadProducts()
      .then((response) => {
        !didCancel && setProducts(response.data);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [loadProducts]);

  return (
    <Container>
      <Spin spinning={isInsertingLoading}>
        <PageHeader title="Nova oferta" onBack={() => history.goBack()}>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col md={8}>
                <Form.Item
                  name="product_id"
                  label="Produto"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Selecione um produto"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    loading={loadingProducts}
                    onChange={onChangedProductId}
                  >
                    {products.map((item, index) => {
                      return (
                        <Option key={`products-${index}`} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="title"
                  label="Título"
                  rules={[{ required: true, max: 512, min: 2 }]}
                >
                  <Input placeholder="Digite o título da oferta" />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="price"
                  label="Preço"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    style={{ display: "block", width: "100%" }}
                    formatter={(value) =>
                      `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) =>
                      value?.replace(/R\$\s?|(,*)/g, "") as any
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="status"
                  valuePropName="checked"
                  wrapperCol={{ offset: 0, span: 24 }}
                >
                  <Checkbox checked>Ativo?</Checkbox>
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="status_in_combo"
                  valuePropName="checked"
                  wrapperCol={{ offset: 0, span: 24 }}
                >
                  <Checkbox checked>Habilitado para combo?</Checkbox>
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  valuePropName="checked"
                  name={["metadata", "is_best_plan"]}
                  wrapperCol={{ offset: 0, span: 24 }}
                >
                  <Checkbox checked>Melhor oferta?</Checkbox>
                </Form.Item>
              </Col>

              <Col md={8}>
                <Title level={5}>Informações adicionais</Title>
                <Form.List name="additional_info">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item
                          label={`Informação ${index + 1}`}
                          required={true}
                          key={field.key}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Preencha este campo, ou remova ele.",
                              },
                            ]}
                            noStyle
                            style={{ display: "flex" }}
                          >
                            <Input
                              placeholder="Digite a informação..."
                              style={{ width: "calc(100% - 30px)" }}
                            />
                          </Form.Item>
                          <MinusOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{ width: "100%" }}
                          icon={<PlusOutlined />}
                        >
                          Adicionar informação
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
              <Col md={8}></Col>

              {productData && productData.config ? (
                <Col md={16}>
                  <Title level={5}>Informações sobre a oferta</Title>

                  <Row gutter={15}>
                    <Col md={24}>
                      <FieldsOfferRegister
                        config={((productData as any)?.config as any) || []}
                      />
                    </Col>
                  </Row>
                </Col>
              ) : null}
            </Row>

            <Row justify="end">
              <Col>
                <Space>
                  <Button
                    key={"save-product-offer"}
                    disabled={isInsertingLoading}
                    loading={isInsertingLoading}
                    type="primary"
                    htmlType="submit"
                  >
                    Salvar dados
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </PageHeader>
      </Spin>
    </Container>
  );
};

export default OffersNew;
