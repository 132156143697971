import styled from "styled-components";

export const Container = styled.div``;

export const ContainerMessage = styled.div`
  background: #fff;
  text-align: center;
  padding: 100px 30px;

  font-size: 1.4rem;
`;
