import { ArrowUpOutlined, ClearOutlined, ClockCircleOutlined, DislikeOutlined, LikeOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, notification, PageHeader, Row, Select, Statistic, Table, TableColumnType, Tag } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";

import api from "../../../api";
import DebounceSelect from "../../../components/DebounceSelect";
import { FranchisorLeadStatus, FranchisorLeadStatusName } from "../../../utils/enums";
import { formatPhoneNumber, getStatusFranchisorLeadColor, getStatusFranchisorLeadText } from "../../../utils/functions";
import { Container } from "./styles";

const defaultPageSize = 10;

const ReportLeads: React.FC = () => {
  const [shouldReloadTable, setShouldReloadTable] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState<Partial<any>>({});
  const [meta, setMeta] = useState<Partial<any>>({
    total: null,
    approved: null,
    rejected: null,
    not_analyzed: null,
  });
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: defaultPageSize,
    showSizeChanger: true,
  });

  const loadData = useCallback(async (params: any) => {
    setTableLoading(true);

    const { current, pageSize, sortField, sortOrder, filters } = params;

    try {
      const { data } = await api.get(`/franchisor-lead/report`, {
        params: {
          page: current,
          pageSize: pageSize,
          offset: (current - 1) * pageSize,
          ...(filters ? { filters } : {}),
          ...(sortField ? { order_by: sortField } : {}),
          ...(sortOrder ? { sort_by: sortOrder } : {}),
        },
      });

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setTableLoading(false);
    }
  }, []);

  const onHandleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (!pagination) return;

    loadData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...pagination,
      ...filters,
    })
      .then((response) => {
        setTablePagination((old) => ({
          ...old,
          ...pagination,
          total: response.total,
        }));
        setData(response.data);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));
  };

  const onChangeFilters = (
    field_name: string,
    parser?: (value: any, key: string) => any
  ) => {
    return (value: any) => {
      if (parser) {
        const parsedValue = parser(value, field_name);
        const _filters = {
          ...filters,
          ...(parsedValue ?? {}),
        };
        setFilters(_filters);
      } else {
        const _filters = {
          ...filters,
          [field_name]: value,
        };
        setFilters(_filters);
      }
    };
  };

  const onHandleReloadData = () => setShouldReloadTable(!shouldReloadTable);

  async function fetchCityList(cityname: string): Promise<any[]> {
    try {
      const { data } = await api.get("/cities", {
        params: {
          pageSize: 999,
          offset: 0,
          ...(!cityname ? {} : { filters: { name: cityname } }),
        },
      });

      return data.data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    }
  }

  const tableCols: TableColumnType<any>[] = [
    {
      key: "id",
      title: "#",
      dataIndex: "id",
      width: "70px",
      sorter: true,
    },
    {
      key: "name",
      title: "Nome Completo",
      dataIndex: "name",
      sorter: true,
    },
    {
      key: "email",
      title: "E-mail",
      dataIndex: "email",
      width: "200px",
      sorter: true,
    },
    {
      key: "city",
      title: "Cidade",
      dataIndex: "city",
      sorter: false,
      render: (city: any) => `${city.name} - ${city.uf}`,
    },
    {
      key: "district",
      title: "Bairro",
      dataIndex: "district",
      sorter: true,
      width: "130px",
    },
    {
      key: "phone_number",
      title: "Número",
      dataIndex: "phone_number",
      width: "130px",
      render: (value) => formatPhoneNumber(value || ""),
      sorter: true,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sorter: true,
      width: "90px",
      align: "center",
      render: (value) => (
        <Tag color={getStatusFranchisorLeadColor(value)}>
          {getStatusFranchisorLeadText(value)}
        </Tag>
      ),
    },
    {
      key: "created_at",
      title: "Data Criação",
      dataIndex: "created_at",
      sorter: false,
      render: (value) => moment(value).format("DD/MM/YYYY"),
    },
  ];

  useEffect(() => {
    let didCancel = false;

    const _filters = Object.keys(filters).reduce((o: Partial<any>, k) => {
      if (Array.isArray(filters[k]) ? filters[k].length : filters[k]) {
        o[k] = filters[k];
      }
      return o;
    }, {});

    loadData({
      current: 1,
      pageSize: defaultPageSize,
      ...(Object.keys(filters).length ? { filters: _filters } : { }),
    })
      .then((response) => {
        !didCancel && setMeta(response.meta);
        !didCancel && setData(response.data);
        setTablePagination((old) => ({ ...old, total: response.total }));
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [loadData, shouldReloadTable, filters]);

  return (
    <Container>
      <PageHeader
        title="Relatório de leads (quero ser franqueado)"
        subTitle=""
        extra={[
          <Select
            style={{ width: 120 }}
            onChange={onChangeFilters("status")}
            value={(filters as any)?.["status"]}
            defaultValue={(filters as any)?.["status"]}
            placeholder="Status"
            allowClear
          >
            <Select.Option value={FranchisorLeadStatus.TREATED}>
              {FranchisorLeadStatusName.TREATED}
            </Select.Option>
            <Select.Option value={FranchisorLeadStatus.REJECTED}>
              {FranchisorLeadStatusName.REJECTED}
            </Select.Option>
            <Select.Option value={FranchisorLeadStatus.NOT_ANALYZED}>
              {FranchisorLeadStatusName.NOT_ANALYZED}
            </Select.Option>
          </Select>,
          <DebounceSelect
            key="bt-ds-city-search"
            placeholder="Pesquisar cidade..."
            showSearch
            fetchOptions={fetchCityList}
            style={{ minWidth: "200px", marginLeft: 12 }}
            labelProp="name"
            valueProp="id"
            onChange={onChangeFilters("city")}
            value={(filters as any)?.["city"]}
            allowClear
          />,
          <DatePicker.RangePicker
            key="bt-ds-period-search"
            ranges={{
              Hoje: [moment(), moment()],
              "Este mês": [moment().startOf("month"), moment().endOf("month")],
            }}
            onChange={onChangeFilters("period", (values) => ({
              created_at: values && values.length ? values.map((v: any) =>
                v.format("YYYY-MM-DD")
              ) : null,
            }))}
            value={
              filters?.created_at?.length
                ? [
                    moment(filters?.created_at[0]),
                    moment(filters?.created_at[1]),
                  ]
                : ([] as any)
            }
          />,
          filters?.created_at?.length || filters.city || filters.status ? (
            <Button
              title="Limpar filtros"
              key="bt-ds-clear-search"
              icon={<ClearOutlined />}
              onClick={() => setFilters({})}
            />
          ) : null,
          <Button
            key="bt-ds-reload"
            icon={<ReloadOutlined />}
            onClick={onHandleReloadData}
          >
            Recarregar dados
          </Button>,
        ]}
      >
        <Row style={{ marginTop: 12 }}>
          <Col span={24} style={{ marginBottom: 20 }}>
            <Row gutter={15}>
              <Col span={6}>
                <Card>
                  <Statistic
                    loading={tableLoading}
                    title="Total"
                    value={meta?.total ?? "---"}
                    valueStyle={{ color: "#00bcd4" }}
                    prefix={<ArrowUpOutlined />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    loading={tableLoading}
                    title="Aprovado"
                    value={meta?.approved ?? "---"}
                    valueStyle={{ color: "#3f8600" }}
                    prefix={<LikeOutlined />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    loading={tableLoading}
                    title="Rejeitado"
                    value={meta?.rejected ?? "---"}
                    valueStyle={{ color: "#cf1322" }}
                    prefix={<DislikeOutlined />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    loading={tableLoading}
                    title="Aberto"
                    value={meta?.not_analyzed ?? "---"}
                    valueStyle={{ color: "#9e9e9e" }}
                    prefix={<ClockCircleOutlined />}
                  />
                </Card>
              </Col>
            </Row>
          </Col>

          <Col md={24}>
            <Table
              size="middle"
              rowKey={(record: any) => record.id}
              dataSource={data}
              columns={tableCols}
              loading={tableLoading}
              pagination={tablePagination}
              onChange={onHandleTableChange}
            />
          </Col>
        </Row>
      </PageHeader>
    </Container>
  );
};

export default ReportLeads;
