import React, { useEffect, useState } from "react";
import { notification, Form, Modal, Input, Checkbox } from "antd";

import InputMask from "react-input-mask";

import api from "../../../../api";
import {
  documentCPF,
  phoneNumber,
  validateAndInsertCNPJ,
} from "../../../../utils/validators";

const FranchisesNew: React.FC<{
  isVisible?: boolean;
  onCreate?: () => void;
  onCancel?: () => void;
}> = ({ isVisible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await api.post(`/franchisees`, {
        ...values,
        ...(values.cnpj && { cnpj: values.cnpj }),
        status: values.status ? values.status : false,
      });

      notification.success({
        message: "Franquia inserida com sucesso",
      });

      onCreate && onCreate();

      setLoading(false);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao inserir a franquia. Tente novamente., " +
          error,
      });
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  useEffect(onReset, [onFinish]);

  return (
    <Modal
      visible={isVisible}
      title="Adicionar franquia"
      closable={false}
      maskClosable={false}
      okText="Adicionar"
      okButtonProps={{
        htmlType: "submit",
        disabled: loading,
        loading: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch((info) => {
            console.log("Validate Failed: ", info);
          });
      }}
      onCancel={() => {
        onReset();
        onCancel && onCancel();
      }}
    >
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label="Nome"
          rules={[{ required: true, max: 512, min: 2 }]}
        >
          <Input placeholder="Digite o nome da franquia" />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[{ required: true, type: "email" }]}
        >
          <Input type={"email"} placeholder="Digite o e-mail da franquia" />
        </Form.Item>
        <Form.Item
          name="cnpj"
          label="CNPJ"
          rules={[{ validator: validateAndInsertCNPJ }]}
        >
          <Input placeholder="Digite o CNPJ da franquia" />
        </Form.Item>

        <Form.Item
          name="document"
          label="Documento"
          rules={[{ required: true }, { validator: documentCPF }]}
        >
          <InputMask mask="999.999.999-99">
            {(props: Partial<any>) => (
              <Input {...props} placeholder="000.000.000-00" />
            )}
          </InputMask>
        </Form.Item>
        <Form.Item
          name="phone_number"
          label="Número"
          rules={[{ required: true }, { validator: phoneNumber }]}
        >
          <InputMask mask="(99) 9 9999-9999">
            {(props: Partial<any>) => (
              <Input {...props} placeholder="(00) 9 0000-0000" />
            )}
          </InputMask>
        </Form.Item>
        <Form.Item name="status" valuePropName="checked">
          <Checkbox>Ativa?</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FranchisesNew;
