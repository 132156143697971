import { ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Table,
  TableColumnType,
  Tag,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../../../api";
import { useAuth } from "../../../../providers/Auth";
import {
  FranchisseLeadStatus,
  ReturnScheduleStatusName,
} from "../../../../utils/enums";
import { buildFilterAttrs } from "../../../../utils/filters";
import {
  formatCEP,
  formatCpfCnpj,
  formatPhoneNumber,
  getStatusFranchiseeLeadColor,
  getStatusFranchiseeLeadText,
} from "../../../../utils/functions";
import ReturnScheduleDetails from "./ReturnScheduleDetails";
import { Container } from "./styles";

const defaultPageSize = 10;

const ReturnSchedule: React.FC<{ franchiseId?: string }> = ({
  franchiseId: franchiseIdStatic,
}) => {
  const { franchisee_id: franchiseIdLogin } = useAuth();
  const { franchiseId: franchiseIdDynamic } =
    useParams<{ franchiseId: string }>();

  const [franchiseId] = useState(
    franchiseIdStatic || franchiseIdDynamic || franchiseIdLogin
  );

  const [shouldReloadTable, setShouldReloadTable] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: defaultPageSize,
    showSizeChanger: true,
  });

  const [detailsOpened, setDetailsOpened] = useState<any>(null);

  const loadData = useCallback(
    async (params: any) => {
      setTableLoading(true);

      const { current, pageSize, sortField, sortOrder, filters } = params;

      try {
        const { data } = await api.get(
          `/franchisees/${franchiseId}/return-schedule/lead`,
          {
            params: {
              page: current,
              pageSize: pageSize,
              offset: (current - 1) * pageSize,
              ...(filters ? { filters } : {}),
              ...(sortField ? { order_by: sortField } : {}),
              ...(sortOrder ? { sort_by: sortOrder } : {}),
            },
          }
        );
        return data;
      } catch (error) {
        throw new Error("Erro ao carregar dados! " + error);
      } finally {
        setTableLoading(false);
      }
    },
    [franchiseId]
  );

  const handleAccept = useCallback(
    async (id: any) => {
      try {
        await api.patch(
          `/franchisees/${franchiseId}/return-schedule/lead/${id}/`,
          {
            status: FranchisseLeadStatus.TREATED,
          }
        );
        notification.success({
          message: "Status do Retorno atualizado com sucesso",
        });

        setShouldReloadTable((oldState) => !oldState);
      } catch (error) {
        notification.error({
          message:
            "Ocorreu algum erro ao atualizar o status do Retorno. Tente novamente., " +
            error,
        });
      }
    },
    [franchiseId]
  );

  const handleReject = useCallback(
    async (id: any) => {
      try {
        await api.patch(
          `/franchisees/${franchiseId}/return-schedule/lead/${id}/`,
          {
            status: FranchisseLeadStatus.REJECTED,
          }
        );
        notification.success({
          message: "Status do Retorno atualizado com sucesso",
        });

        setShouldReloadTable((oldState) => !oldState);
      } catch (error) {
        notification.error({
          message:
            "Ocorreu algum erro ao atualizar o status do Retorno. Tente novamente., " +
            error,
        });
      }
    },
    [franchiseId]
  );

  const onHandleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (!pagination) return;
    // console.log(filters);

    let newFilters: any = {};
    for (const key in filters) {
      if (filters[key] === null) continue;
      const value = filters[key];

      if (value.length > 1) {
        newFilters[key] = value;
        continue;
      }

      newFilters[key] = value[0];
    }

    loadData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...pagination,
      filters: newFilters,
    })
      .then((response) => {
        setTablePagination((old) => ({
          ...old,
          ...pagination,
          total: response.total,
        }));
        setData(response.data);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));
  };

  const onHandleOpenMoreDetails = async (row: Partial<any>) => {
    setDetailsOpened(row);
  };

  const onHandleReloadData = () => setShouldReloadTable(!shouldReloadTable);

  const tableCols: TableColumnType<any>[] = [
    {
      key: "id",
      title: "#",
      dataIndex: "id",
      width: "70px",
      sorter: true,
      ...buildFilterAttrs({
        searchLabel: "Código",
      }),
    },
    {
      key: "name",
      title: "Nome Completo",
      dataIndex: "name",
      sorter: true,
      ...buildFilterAttrs({
        searchLabel: "Nome",
      }),
    },
    {
      key: "document",
      title: "Documento",
      dataIndex: "document",
      render: (value) => formatCpfCnpj(value || ""),
      sorter: true,
      width: "130px",
      ...buildFilterAttrs({
        searchLabel: "Documento",
      }),
    },
    {
      key: "phone_number",
      title: "Número",
      dataIndex: "phone_number",
      width: "130px",
      render: (value) => formatPhoneNumber(value || ""),
      sorter: true,
    },
    {
      key: "city",
      title: "Cidade",
      dataIndex: "city",
      render: (value) => `${value.name} - ${value.uf}`,
      sorter: true,
    },
    {
      key: "cep",
      title: "CEP",
      dataIndex: "cep",
      render: (value) => {
        return formatCEP(value);
      },
      sorter: true,
    },
    {
      key: "department",
      title: "Departamento",
      dataIndex: "dept",
      width: "15%",
      sorter: true,
      render: (value) => value.name,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sorter: true,
      width: "90px",
      align: "center",
      render: (value) => (
        <Tag color={getStatusFranchiseeLeadColor(value)}>
          {getStatusFranchiseeLeadText(value)}
        </Tag>
      ),
      filters: [
        {
          text: ReturnScheduleStatusName.NOT_ANALYZED,
          value: 1,
        },
        {
          text: ReturnScheduleStatusName.TREATED,
          value: 2,
        },
        {
          text: ReturnScheduleStatusName.REJECTED,
          value: 3,
        },
      ],
    },
    {
      key: "view",
      title: "Visualizar",
      width: "100px",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            key="bt-view"
            size="small"
            onClick={() => onHandleOpenMoreDetails(record)}
          >
            Detalhes
          </Button>
        );
      },
    },
    {
      key: "actions",
      title: "Ações",
      width: "100px",
      align: "center",
      render: (_, record) => {
        return (
          <Space>
            <Popconfirm
              title="Atualize o status do seu Retorno. O Retorno foi tratado ou rejeitado?"
              okType="default"
              onConfirm={async () => await handleAccept(record.id)}
              onCancel={async () => await handleReject(record.id)}
              okText="Retorno tratado"
              cancelText="Retorno rejeitado"
              disabled={record.status !== FranchisseLeadStatus.NOT_ANALYZED}
            >
              <Button
                key="bt-update-status"
                size="small"
                color="primary"
                type="primary"
                disabled={record.status !== FranchisseLeadStatus.NOT_ANALYZED}
              >
                Finalizar
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    let didCancel = false;

    loadData({
      current: 1,
      pageSize: defaultPageSize,
    })
      .then((response) => {
        !didCancel && setData(response.data);
        setTablePagination((old) => ({ ...old, total: response.total }));
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [loadData, shouldReloadTable]);

  return (
    <Container>
      <PageHeader
        title={franchiseIdStatic ? null : "Agendamentos de Retornos"}
        subTitle=""
        extra={[
          <Button
            key="bt-ds-reload"
            icon={<ReloadOutlined />}
            onClick={onHandleReloadData}
          >
            Recarregar dados
          </Button>,
        ]}
      >
        <Row style={{ marginTop: 12 }}>
          <Col md={24}>
            <Table
              size="middle"
              rowKey={(record: any) => record.id}
              dataSource={data}
              columns={tableCols}
              loading={tableLoading}
              pagination={tablePagination}
              onChange={onHandleTableChange}
            />
          </Col>
        </Row>
      </PageHeader>

      {detailsOpened ? (
        <ReturnScheduleDetails
          details={detailsOpened}
          onCancel={() => setDetailsOpened(null)}
          onDataLoading={(v) => setTableLoading(v)}
          onTreating={() => {
            onHandleReloadData();
          }}
          onTreatingLoading={(v) => setTableLoading(v)}
        />
      ) : null}
    </Container>
  );
};

export default ReturnSchedule;
