import styled from "styled-components";



export const Container = styled.div`
  background-color: white;

  .dynamic-delete-button {
    margin-left: 10px;
  }
`;
