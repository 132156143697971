import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Descriptions,
  notification,
  Popconfirm,
  Form,
  Input,
  Space,
  Row,
  Col,
  Select,
} from "antd";

import api from "../../../../../../../api";

import { ButtonsFlex } from "./styles";
import { useAuth } from "../../../../../../../providers/Auth";
import { useParams } from "react-router-dom";
import {
  phoneNumber,
  phoneNumber0800,
  phoneResidential,
} from "../../../../../../../utils/validators";
import ReactInputMask from "react-input-mask";

export interface ContactDetailsProps {
  details?: Partial<any>;

  zoneId?: string;
  franchiseId?: string;

  onCancel?: () => void;

  onDataLoading?: (isLoading: boolean) => void;
  onEdit?: () => void;
  onEditLoading?: (isLoading: boolean) => void;
  onDelete?: () => void;
  onDeleteLoading?: (isLoading: boolean) => void;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({
  details,

  zoneId: staticZoneId,
  franchiseId: staticFranchiseId,

  onCancel,
  onDataLoading,
  onEdit,
  onEditLoading,
  onDelete,
  onDeleteLoading,
}) => {
  const { franchisee_id } = useAuth();

  const { zoneId: paramRouteZoneId, franchiseId: paramRouteFranchiseId } =
    useParams<{ zoneId: string; franchiseId: string }>();

  const [zoneId] = useState(staticZoneId || paramRouteZoneId);
  const [franchiseId] = useState(
    staticFranchiseId || paramRouteFranchiseId || franchisee_id
  );

  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLoading, setIsEditingLoading] = useState(false);

  const [phoneType, setPhoneType] = useState("0800");
  const [phoneOptions] = useState([
    {
      value: `0800`,
      label: `0800`,
    },
    {
      value: `cell`,
      label: `Celular`,
    },
    {
      value: `residential`,
      label: `Fixo`,
    },
  ]);
  const [phoneTypeOptions] = useState<any>({
    mask: {
      cell: "(99) \\9 9999-9999",
      residential: "(99) 9999-9999",
      "0800": "\\08\\0\\0 999-9999",
    },
    validator: {
      cell: phoneNumber,
      residential: phoneResidential,
      "0800": phoneNumber0800,
    },
    placeholder: {
      cell: "(00) 0 0000-0000",
      residential: "(00) 0000-0000",
      "0800": "0800 000-0000",
    },
  });

  const loadInfo = async (id: any) => {
    onDataLoading && onDataLoading(true);
    try {
      const { data } = await api.get(
        `/franchisees/${franchiseId}/zone/${zoneId}/contact/${id}/`
      );

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      onDataLoading && onDataLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    onDeleteLoading && onDeleteLoading(true);
    try {
      await api.delete(
        `/franchisees/${franchiseId}/zone/${zoneId}/contact/${id}/`
      );
      notification.success({
        message: "Contato deletado com sucesso",
      });

      setData(null);
      setIsEditing(false);

      onDelete && onDelete();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao deletar o contato. Tente novamente., " + error,
      });
    } finally {
      onDeleteLoading && onDeleteLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setIsEditingLoading(true);
    onEditLoading && onEditLoading(true);

    try {
      await api.patch(
        `/franchisees/${franchiseId}/zone/${zoneId}/contact/${data?.id}`,
        {
          ...(values.email && { email: values.email }),
          ...(values.phone_number && { phone_number: values.phone_number }),
          ...(values.twitter_link && { twitter_link: values.twitter_link }),
          ...(values.facebook_link && { facebook_link: values.facebook_link }),
          ...(values.linkedin_link && { linkedin_link: values.linkedin_link }),
          ...(values.instagram_link && {
            instagram_link: values.instagram_link,
          }),
          ...(values.whatsapp_number && {
            whatsapp_number: values.whatsapp_number,
          }),
        }
      );

      notification.success({
        message: "Contato atualizado com sucesso",
      });

      setData({
        ...data,
        ...values,
      });

      onReset();
      setIsEditing(false);

      onEdit && onEdit();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar o contato. Tente novamente., " +
          error,
      });
    } finally {
      onEditLoading && onEditLoading(false);
      setIsEditingLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const detailsTemplate = () => {
    if (data) {
      return (
        <>
          <Descriptions title="Informações Gerais" column={{ md: 2 }}>
            <Descriptions.Item label="Número">
              {data.phone_number}
            </Descriptions.Item>
            <Descriptions.Item label="WhatsApp">
              {data.whatsapp_number}
            </Descriptions.Item>
            <Descriptions.Item label="Instagram">
              {data.instagram_link}
            </Descriptions.Item>
            <Descriptions.Item label="Facebook">
              {data.facebook_link}
            </Descriptions.Item>
            <Descriptions.Item label="Linkedin">
              {data.linkedin_link}
            </Descriptions.Item>
            <Descriptions.Item label="Twitter">
              {data.twitter_link}
            </Descriptions.Item>
          </Descriptions>
        </>
      );
    }
    return null;
  };

  const editTemplate = () => {
    if (data) {
      return (
        <>
          <Form
            layout="vertical"
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <Form.Item label="Número">
              <Row gutter={5}>
                <Col md={4}>
                  <Form.Item noStyle>
                    <Select
                      defaultValue={phoneType}
                      onChange={(v) => {
                        if (v !== phoneType) {
                          setPhoneType(v);
                          form.setFieldsValue({
                            ...form.getFieldsValue(),
                          });
                        }
                      }}
                    >
                      {phoneOptions.map((item, idx) => (
                        <Select.Option key={idx} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={20}>
                  <Form.Item
                    name="phone_number"
                    initialValue={""}
                    rules={[
                      { message: `Por favor, insira Número` },
                      { validator: phoneTypeOptions.validator[phoneType] },
                    ]}
                    noStyle
                  >
                    <ReactInputMask mask={phoneTypeOptions.mask[phoneType]}>
                      {(props: Partial<any>) => (
                        <Input
                          {...(props || {})}
                          placeholder={phoneTypeOptions.placeholder[phoneType]}
                        />
                      )}
                    </ReactInputMask>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              name="email"
              label="E-mail"
              rules={[{ max: 512, min: 2, type: "email" }]}
            >
              <Input placeholder="Digite o email" type="email" />
            </Form.Item>

            <Form.Item
              name="whatsapp_number"
              label="WhatsApp"
              rules={[{ validator: phoneNumber }]}
            >
              <ReactInputMask mask="(99) \9 9999-9999">
                {(props: Partial<any>) => (
                  <Input {...props} placeholder="(00) 9 0000-0000" />
                )}
              </ReactInputMask>
            </Form.Item>
            <Form.Item
              name="instagram_link"
              label="Instagram"
              rules={[{ max: 512, min: 2 }]}
            >
              <Input placeholder="Digite o link do Instagram" />
            </Form.Item>
            <Form.Item
              name="facebook_link"
              label="Facebook"
              rules={[{ max: 512, min: 2 }]}
            >
              <Input placeholder="Digite o link do Facebook" />
            </Form.Item>
            <Form.Item
              name="linkedin_link"
              label="Linkedin"
              rules={[{ max: 512, min: 2 }]}
            >
              <Input placeholder="Digite o link do Linkedin" />
            </Form.Item>
            <Form.Item
              name="twitter_link"
              label="Twitter"
              rules={[{ max: 512, min: 2 }]}
            >
              <Input placeholder="Digite o link do Twitter" />
            </Form.Item>
          </Form>
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      loadInfo(details?.id)
        .then((response) => {
          !didCancel && setData(response);
          if (!didCancel) {
            const number = `${response.phone_number}`;
            if (!number.startsWith("0800")) {
              if (/\(\d\d\)\s?\9/gm.test(number)) {
                setPhoneType("cell");
              } else {
                setPhoneType("residential");
              }
            }
          }
        })
        .catch(() =>
          notification.error({ message: "Erro ao carregar dados!" })
        );
    }

    return () => {
      didCancel = true;
    };
  }, []); // No 'details' dep. is needed

  return (
    <Drawer
      width="40%"
      title="Detalhes do contato"
      visible={data !== null}
      onClose={() => {
        setData(null);
        setIsEditing(false);
        onCancel && onCancel();
      }}
      maskClosable={!isEditingLoading}
      closable={!isEditingLoading}
    >
      {data !== null && (
        <>
          {isEditing ? editTemplate() : detailsTemplate()}

          <ButtonsFlex>
            <Space>
              {!isEditing ? (
                <Popconfirm
                  title="Tem certeza, que deseja deletar o contato?!"
                  okType="default"
                  onConfirm={async () => await handleDelete(data.id)}
                  okText="Deletar"
                  cancelText="Cancelar"
                >
                  <Button
                    key="bt--modal-prod-delete"
                    color="danger"
                    danger
                    type="primary"
                  >
                    Deletar
                  </Button>
                </Popconfirm>
              ) : null}

              {!isEditing ? (
                <Button
                  onClick={() => {
                    setIsEditing(true);
                    form.setFieldsValue({ ...data });
                  }}
                >
                  Editar
                </Button>
              ) : null}
              {isEditing ? (
                <>
                  <Button
                    onClick={() => {
                      onReset();
                      setIsEditing(false);
                    }}
                    disabled={isEditingLoading}
                  >
                    {" "}
                    Cancelar{" "}
                  </Button>
                  <Button
                    onClick={() => {
                      form.submit();
                    }}
                    loading={isEditingLoading}
                    disabled={isEditingLoading}
                    type="primary"
                  >
                    Salvar
                  </Button>
                </>
              ) : null}
            </Space>
          </ButtonsFlex>
        </>
      )}
    </Drawer>
  );
};

export default ContactDetails;
