import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Descriptions,
  notification,
  Popconfirm,
  Form,
  Input,
  Space,
  Select,
  Typography
} from "antd";

import api from "../../../../../../api";


import { ButtonsFlex } from './styles';
import { useParams } from "react-router-dom";
import { getStatusAprovedText } from "../../../../../../utils/functions";
import { FranchisorLeadStatus } from "../../../../../../utils/enums";


const { Text } = Typography;


export interface ZoneDetailsProps {
  details?: Partial<any>;

  franchisorLeadId?: string;
  franchisorLeadStatus?: FranchisorLeadStatus;

  onCancel?: () => void;

  onDataLoading?: (isLoading: boolean) => void;
  onEdit?: () => void;
  onEditLoading?: (isLoading: boolean) => void;
  onDelete?: () => void;
  onDeleteLoading?: (isLoading: boolean) => void;
}


const ZoneDetails: React.FC<ZoneDetailsProps> = ({
  details,

  franchisorLeadId: staticFranchisorLeadId,
  franchisorLeadStatus,

  onCancel,
  onDataLoading,
  onEdit,
  onEditLoading,
  onDelete,
  onDeleteLoading,
}) => {
  const { franchisorLeadId: paramRouteFranchisorLeadId } = useParams<{ franchisorLeadId: string; }>();

  const [franchisorLeadId] = useState(staticFranchisorLeadId || paramRouteFranchisorLeadId);

  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLoading, setIsEditingLoading] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);

  const [loadingCities, setLoadingCities] = useState(false);
  const [cities, setCities] = useState<any[]>([]);

  const loadCities = useCallback(async () => {
    setLoadingCities(true);
    try {
      const { data } = await api.get("/cities", {
        params: {
          pageSize: 999,
          offset: 0,
        },
      });

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      setLoadingCities(false);
    }
  }, []);

  const loadInfo = async (id: any) => {
    onDataLoading && onDataLoading(true);
    try {
      const { data } = await api.get(`/franchisor-lead/${franchisorLeadId}/zone/${id}/`);

      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      onDataLoading && onDataLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    onDeleteLoading && onDeleteLoading(true);
    setIsDeletingLoading(true);
    try {
      await api.delete(`/franchisor-lead/${franchisorLeadId}/zone/${id}/`);
      notification.success({
        message: "Zona de interesse deletada com sucesso",
      });

      setData(null);
      setIsEditing(false);

      onDelete && onDelete();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao deletar a zona de interesse. Tente novamente., " +
          error,
      });
    } finally {
      onDeleteLoading && onDeleteLoading(false);
      setIsDeletingLoading(false);
    }
  };

  const handleAccept = async (id: any) => {
    onEditLoading && onEditLoading(true);
    setIsEditingLoading(true);
    try {
      await api.patch(`/franchisor-lead/${franchisorLeadId}/zone/${id}/`, {
        approved: true,
      });
      notification.success({
        message: "Status da zona de interesse atualizada com sucesso",
      });

      setData({
        ...data,
        approved: true,
      });
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar o status da zona de interesse. Tente novamente., " +
          error,
      });
    } finally {
      onEditLoading && onEditLoading(false);
      setIsEditingLoading(false);
    }
  };

  const handleReject = async (id: any) => {
    onEditLoading && onEditLoading(true);
    setIsEditingLoading(true);
    try {
      await api.patch(`/franchisor-lead/${franchisorLeadId}/zone/${id}/`, {
        approved: false,
      });
      notification.success({
        message: "Status da zona de interesse atualizada com sucesso",
      });

      setData({
        ...data,
        approved: false,
      });

    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar o status da zona de interesse. Tente novamente., " +
          error,
      });
    } finally {
      onEditLoading && onEditLoading(false);
      setIsEditingLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setIsEditingLoading(true);
    onEditLoading && onEditLoading(true);

    if ('city_id' in values && typeof values.city_id === 'string') {
      try {
        values.city_id = +values.city_id;
      } catch (_) { }
    }

    // Mock of approved value
    values.approved = null;

    try {
      await api.patch(`/franchisor-lead/${franchisorLeadId}/zone/${data?.id}`, values);

      notification.success({
        message: "Zona de interesse atualizada com sucesso",
      });

      setData({
        ...data,
        ...values,
      });

      onReset();
      setIsEditing(false);

      onEdit && onEdit();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar a zona de interesse. Tente novamente., " +
          error,
      });
    } finally {
      onEditLoading && onEditLoading(false);
      setIsEditingLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const detailsTemplate = () => {
    if (data) {
      return (<>
        <Descriptions title="Informações Gerais" column={{ md: 2 }}>
          <Descriptions.Item label="Cidade">
            {data?.city && data?.city.name ? `${data?.city?.name} / ${data?.city?.uf}` : '---'}
          </Descriptions.Item>
          <Descriptions.Item label="Bairro">
            {data.district}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {getStatusAprovedText(data.approved)}
          </Descriptions.Item>
        </Descriptions>
      </>);
    }
    return null;
  };

  const editTemplate = () => {
    if (data) {
      return (<>
        <Form layout="vertical" form={form} name="control-hooks" onFinish={onFinish}>
          <Form.Item name="city_id" label="Cidade" rules={[{ required: true, }]}>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Selecione uma cidade"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={loadingCities}
            >
              {cities.map((item, index) => {
                return <Select.Option key={`cities-${index}`} value={item.id}>{item.name}</Select.Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item name="district" label="Distrito" rules={[{ required: true, max: 512, min: 2 }]}>
            <Input placeholder='Digite o distrito' />
          </Form.Item>
        </Form>
      </>);
    }
    return null;
  };

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      loadCities()
        .then((response) => {
          !didCancel && setCities(response.data);
        })
        .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

      loadInfo(details?.id)
        .then((response) => {
          !didCancel && setData(response);
          if (!didCancel) {
            response.city_id = response?.city?.id;
          }
        })
        .catch(() => notification.error({ message: "Erro ao carregar dados!" }));
    }

    return () => {
      didCancel = true;
    };
  }, [loadCities]); // No 'details' dep. is needed

  return (
    <Drawer
      width="40%"
      title="Detalhes da zona de interesse"
      visible={data !== null}
      onClose={() => { setData(null); setIsEditing(false); onCancel && onCancel(); }}

      maskClosable={!isEditingLoading}
      closable={!isEditingLoading}
    >
      {data !== null && (
        <>
          {isEditing ? editTemplate() : detailsTemplate()}

          {!data.approved && typeof data.approved !== 'boolean' ? <>
            <ButtonsFlex>
              <Space>
                {!isEditing ? <>
                  <Popconfirm
                    title={[
                      <><Text>Atualize o status da zona de interesse. A zona de interesse foi aprovada ou desaprovada?</Text></>,
                      <br />,
                      <br />,
                      <><Text strong> *Atenção*:</Text> <Text type="secondary">após atualizar o status da zona, ela não poderá ser editada nem mesmo deletada!</Text></>
                    ]}
                    okType="default"
                    onConfirm={async () => await handleAccept(data.id)}
                    onCancel={async () => await handleReject(data.id)}
                    okText="Zona aprovada"
                    cancelText="Zona desaprovada"
                    disabled={isEditingLoading || isDeletingLoading}
                  >
                    <Button
                      key="bt-update-status"
                      color="primary"
                      type="primary"

                      loading={isEditingLoading}
                      disabled={isEditingLoading || isDeletingLoading}
                    >
                      Atualizar status
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    title="Tem certeza, que deseja deletar a zona de interesse?!"
                    okType="default"
                    onConfirm={async () => await handleDelete(data.id)}
                    okText="Deletar"
                    cancelText="Cancelar"

                    disabled={isEditingLoading || isDeletingLoading}
                  >
                    <Button
                      key="bt-prod-delete"
                      color="danger"
                      danger
                      type="primary"

                      loading={isDeletingLoading}
                      disabled={isEditingLoading || isDeletingLoading}
                    >
                      Deletar
                    </Button>
                  </Popconfirm>
                </> : null}

                {/* {!isEditing ? <Button onClick={() => { setIsEditing(true); form.setFieldsValue({ ...data }) }}>
                  Editar
                </Button> : null} */}
                {isEditing ? <>
                  <Button
                    onClick={() => { onReset(); setIsEditing(false); }}
                    disabled={isEditingLoading || isDeletingLoading}
                  > Cancelar </Button>
                  <Button
                    onClick={() => { form.submit(); }}
                    loading={isEditingLoading || isDeletingLoading}
                    disabled={isEditingLoading || isDeletingLoading}
                    type="primary"
                  >
                    Salvar
                  </Button>
                </> : null}
              </Space>
            </ButtonsFlex>
          </> : null}
        </>
      )}
    </Drawer>
  );
}

export default ZoneDetails;
