import styled from "styled-components";



export const Container = styled.div`
  background-color: white;

  .ant-form-item-labe--upload {
    margin-bottom: 10px;
  }
`;
