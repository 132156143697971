import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Descriptions,
  notification,
  Popconfirm,
  Form,
  Input,
  Checkbox,
} from "antd";

import api from "../../../../api";
import { ButtonsFlex } from "./styles";

export interface DepartmentDetailsProps {
  details?: Partial<any>;
  onCancel?: () => void;

  onDataLoading?: (isLoading: boolean) => void;
  onEdit?: () => void;
  onEditLoading?: (isLoading: boolean) => void;
  onDelete?: () => void;
  onDeleteLoading?: (isLoading: boolean) => void;
}

const DepartmentDetails: React.FC<DepartmentDetailsProps> = ({
  details,
  onCancel,
  onDataLoading,
  onEdit,
  onEditLoading,
  onDelete,
  onDeleteLoading,
}) => {
  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLoading, setIsEditingLoading] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);

  const loadInfo = async (id: any) => {
    onDataLoading && onDataLoading(true);
    try {
      const { data } = await api.get(`/return-schedule/department/${id}/`);

      const cloned = { ...data };

      delete cloned.password;

      return cloned;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      onDataLoading && onDataLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    onDeleteLoading && onDeleteLoading(true);
    setIsDeletingLoading(true);
    try {
      await api.delete(`/return-schedule/department/${id}/`);
      notification.success({
        message: "Setor deletado com sucesso",
      });

      setData(null);
      setIsEditing(false);

      onDelete && onDelete();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao deletar o Setor. Tente novamente., " + error,
      });
    } finally {
      onDeleteLoading && onDeleteLoading(false);
      setIsDeletingLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setIsEditingLoading(true);
    onEditLoading && onEditLoading(true);

    try {
      await api.patch(`/return-schedule/department/${data?.id}`, values);

      notification.success({
        message: "Setor atualizado com sucesso",
      });

      setData({
        ...data,
        ...values,
      });

      onReset();
      setIsEditing(false);

      onEdit && onEdit();
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar o Setor. Tente novamente., " + error,
      });
    } finally {
      setIsEditingLoading(false);
      onEditLoading && onEditLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const detailsTemplate = () => {
    if (data) {
      return (
        <>
          <Descriptions title="Informações Gerais" column={{ md: 2 }}>
            <Descriptions.Item label="Nome">{data.name}</Descriptions.Item>
          </Descriptions>
        </>
      );
    }
    return null;
  };

  const editTemplate = () => {
    if (data) {
      return (
        <>
          <Form
            layout="vertical"
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              label="Nome"
              rules={[{ required: true, max: 512, min: 2 }]}
            >
              <Input placeholder="Digite o nome do Setor" />
            </Form.Item>
            <Form.Item name="status" valuePropName="checked">
              <Checkbox disabled={!isEditing}>Ativa?</Checkbox>
            </Form.Item>
          </Form>
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      loadInfo(details?.id)
        .then((response) => {
          !didCancel && setData(response);
        })
        .catch(() =>
          notification.error({ message: "Erro ao carregar dados!" })
        );
    }

    return () => {
      didCancel = true;
    };
  }, []); // No 'details' dep. is needed

  return (
    <Drawer
      width="40%"
      title="Detalhes do Setor"
      visible={data !== null}
      onClose={() => {
        setData(null);
        setIsEditing(false);
        onCancel && onCancel();
      }}
      maskClosable={!isEditingLoading}
      closable={!isEditingLoading}
    >
      {data !== null && (
        <>
          {isEditing ? editTemplate() : detailsTemplate()}

          <ButtonsFlex>
            {!isEditing ? (
              <Popconfirm
                title="Tem certeza, que deseja deletar o Setor?!"
                okType="default"
                onConfirm={async () => await handleDelete(data.id)}
                okText="Deletar"
                disabled={isDeletingLoading || isEditingLoading}
                cancelText="Cancelar"
              >
                <Button
                  key="bt--modal-prod-delete"
                  color="danger"
                  danger
                  type="primary"
                  disabled={isDeletingLoading || isEditingLoading}
                  loading={isDeletingLoading}
                >
                  Deletar
                </Button>
              </Popconfirm>
            ) : null}

            {!isEditing ? (
              <Button
                onClick={() => {
                  setIsEditing(true);
                  form.setFieldsValue({ ...data });
                }}
              >
                Editar
              </Button>
            ) : null}
            {isEditing ? (
              <>
                <Button
                  onClick={() => {
                    onReset();
                    setIsEditing(false);
                  }}
                  disabled={isEditingLoading}
                >
                  {" "}
                  Cancelar{" "}
                </Button>
                <Button
                  onClick={() => {
                    form.submit();
                  }}
                  loading={isEditingLoading}
                  disabled={isEditingLoading || isDeletingLoading}
                  type="primary"
                >
                  Salvar
                </Button>
              </>
            ) : null}
          </ButtonsFlex>
        </>
      )}
    </Drawer>
  );
};

export default DepartmentDetails;
