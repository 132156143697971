import { Form, Modal, notification, InputNumber } from "antd";
import React, { useState } from "react";

import api from "../../../../../../api";
import DebounceSelect from "../../../../../../components/DebounceSelect";
import { deepCopyFunction } from "../../../../../../utils/functions";

const ComboRulesNew: React.FC<{
  isVisible?: boolean;
  onCreate?: (newValues: any) => void;
  onCancel?: () => void;
  product: any;
}> = ({ isVisible, onCreate, onCancel, product }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  async function fetchCityList(cityname: string): Promise<any[]> {
    try {
      const { data } = await api.get("/product", {
        params: {
          pageSize: 999,
          offset: 0,
          ...(!cityname ? {} : { filters: { name: cityname } }),
        },
      });

      return (data.data || []).slice(0).filter((item: any) => {
        return item.id !== product.id;
      });
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    }
  }

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const clonedValues: any = deepCopyFunction(values);
      const cloned: any = deepCopyFunction(product);
      if (!cloned.combo_rules) {
        cloned.combo_rules = [];
      }
      clonedValues.product.name = clonedValues.product.label;
      clonedValues.product.id = clonedValues.product.value;
      delete clonedValues.product.label;
      delete clonedValues.product.value;
      delete clonedValues.product.key;
      cloned.combo_rules.push(clonedValues);

      await api.patch(`/product/${product.id}`, cloned);

      notification.success({
        message: "Regra inserida com sucesso",
      });

      onReset();
      onCreate && onCreate(cloned);

      setLoading(false);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao inserir um produto. Tente novamente., " +
          error,
      });
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={isVisible}
      title="Adicionar regra"
      closable={false}
      maskClosable={false}
      okText="Adicionar"
      okButtonProps={{
        htmlType: "submit",
        disabled: loading,
        loading: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch((info) => {
            console.log("Validate Failed: ", info);
          });
      }}
      onCancel={() => {
        onReset();
        onCancel && onCancel();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="control-hooks"
      >
        <Form.Item name="product" label="Produto" rules={[{ required: true }]}>
          <DebounceSelect
            labelInValue
            placeholder="Selecione um produto"
            showSearch
            fetchOptions={fetchCityList}
            style={{ width: "100%" }}
            labelProp="name"
            valueProp="id"
          />
        </Form.Item>
        <Form.Item
          name="min"
          label="Mínimo"
          rules={[{ required: true }]}
          shouldUpdate={() => true}
        >
          <InputNumber
            min={0}
            max={form.getFieldValue("max") || null}
            style={{ width: "100%" }}
            placeholder="Quantidade mínima que deve ser selecionada"
          />
        </Form.Item>
        <Form.Item
          name="max"
          label="Máximo"
          rules={[{ required: true }]}
          shouldUpdate={() => true}
        >
          <InputNumber
            min={form.getFieldValue("min") || 0}
            style={{ width: "100%" }}
            placeholder="Quantidade máxima que deve ser selecionada"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ComboRulesNew;
