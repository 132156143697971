import { Form, Input, Modal, notification } from "antd";
import React, { useState } from "react";

import api from "../../../../api";
import FieldsProductRegister from "../FieldsProductRegister";

const ProductsNew: React.FC<{
  isVisible?: boolean;
  onCreate?: () => void;
  onCancel?: () => void;
}> = ({ isVisible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await api.post(`/product`, values);

      notification.success({
        message: "Produto inserido com sucesso",
      });

      onReset();
      onCreate && onCreate();

      setLoading(false);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao inserir um produto. Tente novamente., " +
          error,
      });
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={isVisible}
      title="Adicionar produto"
      closable={false}
      maskClosable={false}
      width={"70%"}
      okText="Adicionar"
      okButtonProps={{
        htmlType: "submit",
        disabled: loading,
        loading: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch((info) => {
            console.log("Validate Failed: ", info);
          });
      }}
      onCancel={() => {
        onReset();
        onCancel && onCancel();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="control-hooks"
      >
        <Form.Item
          name="name"
          label="Nome"
          rules={[{ required: true, max: 512, min: 2 }]}
        >
          <Input placeholder="Digite o nome do produto" />
        </Form.Item>
        <Form.Item
          name="base_color"
          label="Cor base"
          rules={[{ required: true }]}
        >
          <Input type="color" />
        </Form.Item>

        <FieldsProductRegister />
      </Form>
    </Modal>
  );
};

export default ProductsNew;
