import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Descriptions,
  notification,
  Popconfirm,
  Space,
  Table,
  TableColumnType,
  Tag,
} from "antd";

import api from "../../../../../api";

import { ButtonsFlex } from "./styles";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../providers/Auth";
import {
  formatCEP,
  formatCpfCnpj,
  formatPhoneNumber,
  formatPrice,
  getStatusFranchiseeLeadText,
} from "../../../../../utils/functions";
import { FranchisseLeadStatus } from "../../../../../utils/enums";

export interface LeadsDetailsProps {
  details?: Partial<any>;

  franchiseId?: string;

  onCancel?: () => void;

  onDataLoading?: (isLoading: boolean) => void;
  onTreating?: () => void;
  onTreatingLoading?: (isLoading: boolean) => void;
}

const LeadsDetails: React.FC<LeadsDetailsProps> = ({
  details,

  franchiseId: staticFranchiseId,

  onCancel,
  onDataLoading,
  onTreating,
  onTreatingLoading,
}) => {
  const { franchisee_id } = useAuth();

  const { franchiseId: paramRouteFranchiseId } = useParams<{
    zoneId: string;
    franchiseId: string;
  }>();

  const [franchiseId] = useState(
    staticFranchiseId || paramRouteFranchiseId || franchisee_id
  );

  const [data, setData] = useState<any>(null);
  const [isTreatingLoading, setIsTreatingLoading] = useState(false);

  const loadInfo = async (id: any) => {
    onDataLoading && onDataLoading(true);
    try {
      const { data } = await api.get(`/franchisees/${franchiseId}/lead/${id}/`);
      console.log(data);
      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      onDataLoading && onDataLoading(false);
    }
  };

  const handleAccept = async (id: any) => {
    onTreatingLoading && onTreatingLoading(true);
    setIsTreatingLoading(true);
    try {
      await api.patch(`/franchisees/${franchiseId}/lead/${id}/`, {
        status: FranchisseLeadStatus.TREATED,
      });
      notification.success({
        message: "Status do lead atualizado com sucesso",
      });
      onTreating && onTreating();
      setData({
        ...data,
        status: FranchisseLeadStatus.TREATED,
      });
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar o status do lead. Tente novamente., " +
          error,
      });
    } finally {
      onTreatingLoading && onTreatingLoading(false);
      setIsTreatingLoading(false);
    }
  };

  const handleReject = async (id: any) => {
    onTreatingLoading && onTreatingLoading(true);
    setIsTreatingLoading(true);
    try {
      await api.patch(`/franchisees/${franchiseId}/lead/${id}/`, {
        status: FranchisseLeadStatus.REJECTED,
      });
      notification.success({
        message: "Status do lead atualizado com sucesso",
      });
      onTreating && onTreating();
      setData({
        ...data,
        status: FranchisseLeadStatus.REJECTED,
      });
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar o status do lead. Tente novamente., " +
          error,
      });
    } finally {
      onTreatingLoading && onTreatingLoading(false);
      setIsTreatingLoading(false);
    }
  };

  const tableCols: TableColumnType<any>[] = [
    {
      key: "id",
      title: "#",
      dataIndex: "id",
      width: "70px",
      sorter: false,
    },
    {
      key: "title",
      title: "Nome",
      dataIndex: "title",
      sorter: false,
    },
    {
      key: "price",
      title: "Valor",
      dataIndex: "price",
      width: "90px",
      render: (value) => {
        return value ? formatPrice(Number(value)) : value;
      },
      sorter: false,
    },
    {
      key: "is_global",
      title: "Oferta global?",
      dataIndex: "is_global",
      sorter: false,
      width: "150px",
      render: (_, row) => (row.is_global ? "Sim" : "Não"),
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sorter: false,
      align: "center",
      width: "100px",
      render: (value) => {
        return (
          <Tag color={value === true ? "green" : "volcano"}>
            {value === true ? "Ativada" : "Desativada"}
          </Tag>
        );
      },
    },
    data && data.from_combo
      ? {
          key: "status_in_combo",
          title: "Status em combo",
          dataIndex: "status_in_combo",
          sorter: false,
          align: "center",
          width: "100px",
          render: (value: any) => {
            return (
              <Tag color={value === true ? "green" : "volcano"}>
                {value === true ? "Ativada" : "Desativada"}
              </Tag>
            );
          },
        }
      : {},
  ];

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      loadInfo(details?.id)
        .then((response) => {
          !didCancel && setData(response);
        })
        .catch(() =>
          notification.error({ message: "Erro ao carregar dados!" })
        );
    }

    return () => {
      didCancel = true;
    };
  }, []); // No 'details' dep. is needed

  return (
    <Drawer
      width="48%"
      title="Detalhes do lead"
      visible={data !== null}
      onClose={() => {
        setData(null);
        onCancel && onCancel();
      }}
      maskClosable={!isTreatingLoading}
      closable={!isTreatingLoading}
    >
      {data !== null && (
        <>
          <Descriptions title="Informações Gerais" column={{ md: 2 }}>
            <Descriptions.Item label="Nome">{data.name}</Descriptions.Item>
            <Descriptions.Item label="E-Mail">{data.email}</Descriptions.Item>
            <Descriptions.Item label="CPF/CNPJ">
              {formatCpfCnpj(data.document)}
            </Descriptions.Item>
            <Descriptions.Item label="Telefone">
              {formatPhoneNumber(data.phone_number)}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {getStatusFranchiseeLeadText(data.status)}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title="Endereço de instalação" column={{ md: 2 }}>
            <Descriptions.Item label="CEP">
              {formatCEP(data.address_cep)}
            </Descriptions.Item>
            <Descriptions.Item label="Estado">{data.city.uf}</Descriptions.Item>
            <Descriptions.Item label="Cidade">
              {data.city.name}
            </Descriptions.Item>
            <Descriptions.Item label="Logradouro">
              {data.address_street}
            </Descriptions.Item>
            <Descriptions.Item label="Número">
              {data.address_number}
            </Descriptions.Item>
            <Descriptions.Item label="Bairro">
              {data.address_district}
            </Descriptions.Item>
            <Descriptions.Item label="Complemento">
              {data.address_complement}
            </Descriptions.Item>
            <Descriptions.Item label="Dia da instalação">
              {data.attendance_day ?? "---"}
            </Descriptions.Item>
            <Descriptions.Item label="Horário da instalação">
              {data.attendance_hour ?? "---"}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions title="Ofertas" column={{ md: 1 }}>
            <Descriptions.Item>
              <Table
                style={{ width: "100%" }}
                size="small"
                rowKey={(record: any) => record.id}
                dataSource={data?.offers || []}
                columns={tableCols}
                loading={isTreatingLoading}
                pagination={false}
              />
            </Descriptions.Item>
          </Descriptions>

          <ButtonsFlex>
            <Space>
              <Popconfirm
                title="Atualize o status do seu lead. O lead foi tratado ou rejeitado?"
                okType="default"
                onConfirm={async () => await handleAccept(data.id)}
                onCancel={async () => await handleReject(data.id)}
                okText="Lead tratado"
                cancelText="Lead rejeitado"
                disabled={
                  isTreatingLoading ||
                  data.status !== FranchisseLeadStatus.NOT_ANALYZED
                }
              >
                <Button
                  key="bt-update-status"
                  color="primary"
                  type="primary"
                  disabled={
                    isTreatingLoading ||
                    data.status !== FranchisseLeadStatus.NOT_ANALYZED
                  }
                  loading={isTreatingLoading}
                >
                  Finalizar
                </Button>
              </Popconfirm>
            </Space>
          </ButtonsFlex>
        </>
      )}
    </Drawer>
  );
};

export default LeadsDetails;
