import styled from "styled-components";

export const Container = styled.div`
`;

export const ProductsAndOffersList = styled.div`
  padding: 0;
  margin: 0;
`;

export const ProductsAndOffersListPItem = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
`;

export const ProductsAndOffersListOItem = styled.div`
  padding-left: 20px;
  margin: 0;
  margin-top: 7px;
`;
