import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, InputNumber, Popconfirm, Space, Typography } from "antd";
import React, { useState } from "react";
import { ProductTypesId } from "../../../../../../../../../utils/enums";

import {
  deepCopyFunction,
  formatPrice,
  hexToRGB,
} from "../../../../../../../../../utils/functions";
import AddInfo from "./AddInfo";
import SelectOffer from "./SelectOffer";
import {
  CardOffer,
  Container,
  ContainerInfo,
  KanbanColumn,
  KanbanColumnContent,
  KanbanColumnHeader,
  KanbanContainer,
} from "./styles";

interface CombosNewOffersProps {
  products: any[];
  zoneId: string;
  franchiseId: string;
  additional_info_offers: {
    id_offer: string;
    info: string[];
  }[];
  internetPlus: {
    id_offer: string;
    plus_download: number;
  }[];
  offers: { [x: string]: any[] };
  onCancel: () => void;
  onNext: (values: any) => void;
  onRemoved: (prdt: any) => void;
  onPrevius: (values: any) => void;
  onInserted: (offers: any[]) => void;
  onChanged: (prdts: { [x: string]: any[] }) => void;
  onChangedInfoCombo: (
    additional_info_offers: {
      id_offer: string;
      info: string[];
    }[]
  ) => void;
  handlerInternetPlus: (
    values: {
      id_offer: string;
      plus_download: number;
    }[]
  ) => void;
}

const CombosNewOffers: React.FC<CombosNewOffersProps> = ({
  zoneId,
  onNext,
  onCancel,
  onPrevius,
  onRemoved,
  onChanged,
  onInserted,
  franchiseId,
  internetPlus,
  onChangedInfoCombo,
  handlerInternetPlus,
  offers: offersInitial,
  additional_info_offers,
  products: productsInitial,
}) => {
  const [currentOfferInfoID, setCurrentOfferInfoID] = useState<{
    id_offer: string;
    idx: number;
  } | null>(null);

  const [selecting, setSelecting] = useState(false);
  const [products] = useState<any[]>(productsInitial || []);
  const [currentProductSelecting, setCurrentProductSelecting] =
    useState<any>(null);

  const handleInsert = (newOffers: any[]) => {
    setSelecting(false);
    onInserted(newOffers);

    const clonedOffers = deepCopyFunction(offersInitial);
    const mappedOffers = (
      Array.isArray(newOffers) ? newOffers : [newOffers]
    ).map((item) => {
      if (item.price) {
        item.custom_offers_price = +item.price;
      }

      return item;
    });

    if (!(currentProductSelecting.id in clonedOffers)) {
      clonedOffers[currentProductSelecting.id] = [];
    }

    clonedOffers[currentProductSelecting.id] = [
      ...clonedOffers[currentProductSelecting.id],
      ...deepCopyFunction(mappedOffers),
    ];

    onChanged(clonedOffers);

    setSelecting(false);
    setCurrentProductSelecting(null);
  };

  const handleDelete = (prdtId: number, index: number) => {
    if (index in (offersInitial[prdtId] || [])) {
      const ofrs = deepCopyFunction(offersInitial);
      const removed = ofrs[prdtId].splice(index, 1);
      onChangedInfoCombo([]);
      onRemoved(removed);
      onChanged(ofrs);
    }
  };

  const onChangeCustomPrice = (prdtId: any, index: number, price: number) => {
    if (index in (offersInitial[prdtId] || [])) {
      const ofrs = deepCopyFunction(offersInitial);
      ofrs[prdtId][index].custom_offers_price = +price;
      onChanged(ofrs);
    }
  };

  const onChangeInfo = (infos: string[]) => {
    const offersCombo = deepCopyFunction(additional_info_offers);
    offersCombo[currentOfferInfoID?.idx!] = {
      id_offer: currentOfferInfoID?.id_offer,
      info: [
        ...(offersCombo[currentOfferInfoID?.idx!]
          ? offersCombo[currentOfferInfoID?.idx!].info
          : []),
        ...infos,
      ],
    };

    onChangedInfoCombo(offersCombo);
  };

  const handleDeleteInfo = (idxOffer: number, idxInfo: number) => {
    const offersCombo = deepCopyFunction(additional_info_offers);
    if (offersCombo[idxOffer].info.length === 1) {
      offersCombo.splice(idxOffer, 1);
    } else {
      offersCombo[idxOffer].info.splice(idxInfo, 1);
    }
    onChangedInfoCombo(offersCombo);
  };

  const onChangeCustomSpeed = (offerID: any, additional: number) => {
    handlerInternetPlus([
      {
        id_offer: offerID,
        plus_download: additional,
      },
    ]);
  };

  return (
    <Container>
      <SelectOffer
        isVisible={selecting}
        offers={offersInitial[currentProductSelecting?.id] || []}
        currentProduct={currentProductSelecting}
        zoneId={zoneId}
        franchiseId={franchiseId}
        onCancel={() => {
          setSelecting(false);
          setCurrentProductSelecting(null);
        }}
        onSelect={(offers) => {
          handleInsert(offers);
          onChangedInfoCombo([
            { id_offer: offers[0].id, info: offers[0].additional_info },
          ]);
        }}
      />

      <Space direction="vertical" style={{ width: `100%` }}>
        <KanbanContainer>
          <Space align="start">
            {products.map((prdt) => {
              return (
                <KanbanColumn
                  key={prdt.id}
                  baseColor={hexToRGB(prdt.base_color || `#FFF`, 0.05)}
                >
                  <KanbanColumnHeader>
                    <Typography.Title level={5} style={{ marginBottom: 0 }}>
                      #{prdt.id} - {prdt.name}
                    </Typography.Title>
                    {!prdt.allowFranchiseSelect && (
                      <>
                        <Typography.Text type={"secondary"}>
                          Ofertas selecionadas pela a fraqueadora!
                        </Typography.Text>{" "}
                        <br />
                      </>
                    )}
                    <Typography.Text type={"secondary"}>
                      {!prdt.rule.min ? "Opcional" : "Obrigatório"}
                    </Typography.Text>{" "}
                    <br />
                    <Typography.Text type={"secondary"}>
                      Ofertas mínimas: {prdt.rule.min || 0}
                    </Typography.Text>{" "}
                    <br />
                    <Typography.Text type={"secondary"}>
                      Ofertas máximas: {prdt.rule.max || 0}
                    </Typography.Text>{" "}
                    <br />
                  </KanbanColumnHeader>
                  <KanbanColumnContent>
                    <Space direction="vertical" style={{ width: `100%` }}>
                      {(offersInitial[prdt.id] || []).map(
                        (offer: any, idx: number) => {
                          return (
                            <CardOffer
                              key={offer.id}
                              editiable={
                                prdt.allowFranchiseSelect ? true : false
                              }
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: `flex`,
                                  alignItems: `center`,
                                  justifyContent: `space-between`,
                                }}
                              >
                                <div style={{ width: `100%` }}>
                                  <Space
                                    direction="vertical"
                                    style={{ width: `100%` }}
                                  >
                                    <div
                                      style={{
                                        width: `100%`,
                                        display: `flex`,
                                        justifyContent: `space-between`,
                                      }}
                                    >
                                      <Typography.Text>
                                        #{offer.id} - {offer.title}
                                      </Typography.Text>
                                      {prdt.allowFranchiseSelect && (
                                        <div
                                          style={{ alignSelf: `flex-start` }}
                                        >
                                          <Popconfirm
                                            title="Tem certeza que deseja remover esta oferta?!"
                                            onConfirm={() =>
                                              handleDelete(prdt.id, idx)
                                            }
                                            okText="Sim"
                                            cancelText="Não"
                                          >
                                            <Button
                                              shape="circle"
                                              icon={<DeleteOutlined />}
                                            />
                                          </Popconfirm>
                                        </div>
                                      )}
                                    </div>
                                    {offer.price && (
                                      <Typography.Text type="secondary">
                                        {!prdt.allowFranchiseSelect
                                          ? "Preço"
                                          : "Preço original"}
                                        : {formatPrice(offer.price)}
                                      </Typography.Text>
                                    )}

                                    {additional_info_offers.map(
                                      (val, idxInfoCombo) => (
                                        <React.Fragment key={idxInfoCombo}>
                                          {val.id_offer === offer.id &&
                                            val.info.map((val, idxInfo) => (
                                              <ContainerInfo key={val}>
                                                {val}
                                                <Button
                                                  size="small"
                                                  shape="circle"
                                                  icon={<DeleteOutlined />}
                                                  onClick={() =>
                                                    handleDeleteInfo(
                                                      idxInfoCombo,
                                                      idxInfo
                                                    )
                                                  }
                                                />
                                              </ContainerInfo>
                                            ))}
                                        </React.Fragment>
                                      )
                                    )}

                                    {prdt.allowFranchiseSelect && (
                                      <Button
                                        icon={<PlusOutlined />}
                                        style={{ width: "100%" }}
                                        onClick={() =>
                                          setCurrentOfferInfoID({
                                            id_offer: offer.id,
                                            idx: idx,
                                          })
                                        }
                                      >
                                        Adicionar informações
                                      </Button>
                                    )}

                                    {prdt.allowFranchiseSelect && (
                                      <div>
                                        <InputNumber
                                          value={
                                            +offer.custom_offers_price
                                              ? +offer.custom_offers_price
                                              : +offer.price
                                          }
                                          defaultValue={
                                            +offer.custom_offers_price
                                              ? +offer.custom_offers_price
                                              : +offer.price
                                          }
                                          onChange={(n) =>
                                            onChangeCustomPrice(prdt.id, idx, n)
                                          }
                                          min={0}
                                          style={{
                                            width: "100%",
                                          }}
                                          formatter={(value) =>
                                            `Novo preço: R$ ${value}`.replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )
                                          }
                                          parser={(value: any) =>
                                            value.replace(
                                              /Novo\s?preço:\s?R\$\s?|(,*)/g,
                                              ""
                                            )
                                          }
                                        />
                                      </div>
                                    )}

                                    {+prdt.id === ProductTypesId.Internet &&
                                      prdt.allowFranchiseSelect && (
                                        <div>
                                          <InputNumber
                                            defaultValue={
                                              +offer.metadata.download
                                            }
                                            onChange={(n) => {
                                              onChangeCustomSpeed(offer.id, n);
                                            }}
                                            style={{
                                              width: "100%",
                                            }}
                                            min={0}
                                            value={
                                              internetPlus &&
                                              +internetPlus[0].plus_download
                                            }
                                            prefix={
                                              <div>
                                                Velocidade do combo(
                                                {offer.metadata.download_suffix}
                                                ):
                                              </div>
                                            }
                                          />
                                        </div>
                                      )}
                                  </Space>
                                </div>
                              </div>
                            </CardOffer>
                          );
                        }
                      )}
                      {(offersInitial[prdt.id] || []).length < prdt.rule.max &&
                      prdt.allowFranchiseSelect ? (
                        <Card
                          onClick={() => {
                            setCurrentProductSelecting(prdt);
                            setTimeout(setSelecting, 500, true);
                          }}
                        >
                          <Typography.Link>
                            <PlusOutlined /> Adicionar oferta
                          </Typography.Link>
                        </Card>
                      ) : null}
                    </Space>
                  </KanbanColumnContent>
                </KanbanColumn>
              );
            })}
          </Space>
        </KanbanContainer>
        <AddInfo
          index={currentOfferInfoID?.idx!}
          isVisible={currentOfferInfoID !== null}
          onCancel={() => {
            setCurrentOfferInfoID(null);
          }}
          onSubmit={(idx, value) => {
            onChangeInfo(value);
          }}
        />

        <div style={{ display: `flex`, justifyContent: `flex-end` }}>
          <Space>
            <Button onClick={() => onCancel()}>Cancelar</Button>
            <Button onClick={() => onPrevius(products)}>Voltar</Button>
            <Button type="primary" onClick={() => onNext(products)}>
              Próximo
            </Button>
          </Space>
        </div>
      </Space>
    </Container>
  );
};

export default CombosNewOffers;
