import { Form, Input, Modal, ModalProps, notification } from "antd";
import React, { useState } from "react";

import api from "../../../../api";

const RegulationsNew: React.FC<{
  isVisible?: boolean;
  onAddRegulation?: () => void;
  onCancel?: () => void;
}> = ({ isVisible, onAddRegulation, onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await api.post(`/regulation`, values);

      notification.success({
        message: "Regulamento inserido com sucesso",
      });

      onReset();
      onAddRegulation && onAddRegulation();
      setLoading(false);
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao inserir o regulamento. Tente novamente., " +
          error,
      });
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const modalProps: ModalProps = {
    title: "Adicionar regulamento",
    visible: isVisible,
    closable: false,
    maskClosable: false,
    okText: "Adicionar",
    okButtonProps: {
      htmlType: "submit",
      disabled: loading,
      loading: loading,
    },
    cancelButtonProps: { disabled: loading },
    onOk: () => {
      form
        .validateFields()
        .then(() => {
          form.submit();
        })
        .catch((info) => {
          console.log("Validate Failed: ", info);
        });
    },
    onCancel: () => {
      onReset();
      onCancel && onCancel();
    },
  };

  return (
    <Modal {...modalProps}>
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label="Nome"
          rules={[{ required: true, max: 512, min: 2 }]}
        >
          <Input placeholder="Digite o nome do regulamento" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Descrição"
          rules={[{ required: true, max: 512, min: 2 }]}
        >
          <Input.TextArea
            placeholder="Digite a descrição do regulamento"
            rows={4}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RegulationsNew;
