import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Table,
  TableColumnType,
  Tag,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../../../../api";
import { useAuth } from "../../../../../../providers/Auth";
import { UserType } from "../../../../../../utils/enums";
import { buildFilterAttrs } from "../../../../../../utils/filters";
import { formatPrice } from "../../../../../../utils/functions";
import { Container } from "./styles";

const defaultPageSize = 10;

const Offers: React.FC<{ zoneId?: string; franchiseId?: string }> = ({
  zoneId: staticZoneId,
  franchiseId: staticFranchiseId,
}) => {
  const history = useHistory();

  const { franchisee_id, userTypeIs } = useAuth();

  const { zoneId: paramRouteZoneId, franchiseId: paramRouteFranchiseId } =
    useParams<{ zoneId: string; franchiseId: string }>();

  const [zoneId] = useState(staticZoneId || paramRouteZoneId);
  const [franchiseId] = useState(
    staticFranchiseId || paramRouteFranchiseId || franchisee_id
  );

  const [userTypeIsFranchisee] = useState(userTypeIs(UserType.Franchisee));

  const [shouldReloadTable, setShouldReloadTable] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: defaultPageSize,
    showSizeChanger: true,
  });

  const loadData = useCallback(
    async (params: any) => {
      setTableLoading(true);

      const { current, pageSize, sortField, sortOrder, filters } = params;

      try {
        const { data } = await api.get(
          `/franchisees/${franchiseId}/zone/${zoneId}/offer`,
          {
            params: {
              page: current,
              pageSize: pageSize,
              offset: (current - 1) * pageSize,
              ...(filters ? { filters } : {}),
              ...(sortField ? { order_by: sortField } : {}),
              ...(sortOrder ? { sort_by: sortOrder } : {}),
            },
          }
        );

        return data;
      } catch (error) {
        throw new Error("Erro ao carregar dados! " + error);
      } finally {
        setTableLoading(false);
      }
    },
    [zoneId, franchiseId]
  );

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        await api.delete(
          `/franchisees/${franchiseId}/zone/${zoneId}/offer/${id}`
        );
        notification.success({
          message: "Oferta deletada com sucesso",
        });

        setShouldReloadTable((oldState) => !oldState);
      } catch (error) {
        notification.error({
          message:
            "Ocorreu algum erro ao deletar a oferta. Tente novamente., " +
            error,
        });
      }
    },
    [franchiseId, zoneId]
  );

  const onHandleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (!pagination) return;
    // console.log(filters);

    let newFilters: any = {};
    for (const key in filters) {
      if (filters[key] === null) continue;
      const value = filters[key];

      if (value.length > 1) {
        newFilters[key] = value;
        continue;
      }

      newFilters[key] = value[0];
    }

    loadData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...pagination,
      filters: newFilters,
    })
      .then((response) => {
        setTablePagination((old) => ({
          ...old,
          ...pagination,
          total: response.total,
        }));
        setData(response.data);
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));
  };

  const onHandleReloadData = () => setShouldReloadTable(!shouldReloadTable);

  const tableCols: TableColumnType<any>[] = [
    {
      key: "id",
      title: "#",
      dataIndex: "id",
      width: "70px",
      sorter: true,
      ...buildFilterAttrs({
        searchLabel: "Código",
      }),
    },
    {
      key: "title",
      title: "Nome",
      dataIndex: "title",
      sorter: true,
      ...buildFilterAttrs({
        searchLabel: "Nome",
      }),
    },
    {
      key: "price",
      title: "Valor",
      dataIndex: "price",
      width: "150px",
      render: (value) => {
        return value ? formatPrice(Number(value)) : value;
      },
      sorter: true,
    },
    {
      key: "product_name",
      title: "Produto",
      dataIndex: "product_name",
      sorter: true,
      width: "150px",
      render: (_, row) => row.product.name,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sorter: true,
      align: "center",
      width: "150px",
      render: (value) => {
        return (
          <Tag color={value === true ? "green" : "volcano"}>
            {value === true ? "Ativada" : "Desativada"}
          </Tag>
        );
      },
      filters: [
        {
          text: "Ativada",
          value: true,
        },
        {
          text: "Desativada",
          value: false,
        },
      ],
    },
    {
      key: "view",
      title: "Visualizar",
      width: "100px",
      align: "center",
      render: (_, record) => {
        if (record.is_global) return null;
        return (
          <Button
            key="bt-view"
            size="small"
            onClick={() => history.push(`${!userTypeIsFranchisee ? `/franchise/${franchiseId}/` : '/franchisee-'}zone/${zoneId}/offer/${record.id}`)}
          >
            Detalhes
          </Button>
        );
      },
    },
    {
      key: "actions",
      title: "Ações",
      width: "100px",
      align: "center",
      render: (_, record) => {
        if (record.is_global) return null;
        return (
          <Popconfirm
            title="Tem certeza, que deseja deletar a oferta?!"
            okType="default"
            onConfirm={async () => await handleDelete(record.id)}
            okText="Deletar"
            cancelText="Cancelar"
          >
            <Button
              key="bt-prod-delete"
              size="small"
              color="danger"
              danger
              type="primary"
            >
              Deletar
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  useEffect(() => {
    let didCancel = false;

    loadData({
      current: 1,
      pageSize: defaultPageSize,
    })
      .then((response) => {
        !didCancel && setData(response.data);
        setTablePagination((old) => ({ ...old, total: response.total }));
      })
      .catch(() => notification.error({ message: "Erro ao carregar dados!" }));

    return () => {
      didCancel = true;
    };
  }, [loadData, shouldReloadTable]);

  return (
    <Container>
      <PageHeader
        title={staticFranchiseId ? null : "Ofertas"}
        subTitle=""
        extra={[
          <Button
            key="bt-ds-reload"
            icon={<ReloadOutlined />}
            onClick={onHandleReloadData}
          >
            Recarregar dados
          </Button>,
          <Button
            key="bt-ds-new"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => history.push(`${!userTypeIsFranchisee ? `/franchise/${franchiseId}/` : '/franchisee-'}zone/${zoneId}/offer/new`)}
          >
            Novo
          </Button>,
        ]}
      >
        <Row style={{ marginTop: 12 }}>
          <Col md={24}>
            <Table
              size="middle"
              rowKey={(record: any) => record.id}
              dataSource={data}
              columns={tableCols}
              loading={tableLoading}
              pagination={tablePagination}
              onChange={onHandleTableChange}
            />
          </Col>
        </Row>
      </PageHeader>
    </Container>
  );
};

export default Offers;
