import React, { useState } from "react";
import {
  notification,
  Form,
  Modal,
  Input,
  Checkbox,
} from "antd";

import api from "../../../../api";
import { UserType } from "../../../../utils/enums";

const DepartmentNew: React.FC<{ isVisible?: boolean; onCreate?: () => void; onCancel?: () => void; }> = ({
  isVisible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {

      values.user_type = UserType.Admin;
      
      await api.post(`/return-schedule/department`, values);

      notification.success({
        message: "Setor inserido com sucesso",
      });

      onReset();
      onCreate && onCreate();

      setLoading(false);
    } catch (error) {

      notification.error({
        message:
          "Ocorreu algum erro ao inserir o Setor. Tente novamente., " +
          error,
      });
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={isVisible}
      title="Adicionar Setor"
      closable={false}
      maskClosable={false}

      okText="Adicionar"
      okButtonProps={{
        htmlType: 'submit',
        disabled: loading,
        loading: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch(info => {
            console.log('Validate Failed: ', info);
          });
      }}
      onCancel={() => { onReset(); onCancel && onCancel(); }}
    >
      <Form autoComplete="off" layout="vertical" form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item name="name" label="Nome" rules={[{ required: true, max: 128, min: 2 }]}>
          <Input placeholder='Digite o nome do Setor' />
        </Form.Item>
        <Form.Item name="status" valuePropName="checked">
              <Checkbox>Ativa?</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default DepartmentNew;
