import { Form, Modal } from "antd";
import React from "react";

import DebounceSelect from "../../../../../../../../../../components/DebounceSelect";
import { deepCopyFunction } from "../../../../../../../../../../utils/functions";
import api from "./../../../../../../../../../../api";

const SelectOffer: React.FC<{
  zoneId: string;
  franchiseId: string;
  currentProduct: any;
  isVisible?: boolean;
  offers: any[];
  onSelect?: (offer: any) => void;
  onCancel?: () => void;
}> = ({
  zoneId,
  franchiseId,
  currentProduct,
  isVisible,
  onSelect,
  onCancel,
  offers,
}) => {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    if (values.offers) {
      const copy = deepCopyFunction(values);
      if (copy.offers) {
        const values: any = [];
        for (const offer of copy.offers) {
          values.push(JSON.parse(offer.value));
        }
        if (values && values.length) {
          onSelect && onSelect(values);
          onReset();
        }
      }
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  async function fetchOfferssList(productname: string): Promise<any[]> {
    if (!currentProduct) {
      return [];
    }
    try {
      const { data } = await api.get(
        `/franchisees/${franchiseId}/zone/${zoneId}/offer`,
        {
          params: {
            offset: 0,
            pageSize: 999,
            product_id: currentProduct.id,
            filters: {
              status_in_combo: true,
              ...(!productname && { name: productname }),
            },
          },
        }
      );

      return data.data.slice(0).filter((item: any) => {
        return !(offers || []).some((offer: any) => offer.id === item.id);
      });
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    }
  }

  const filterOptions = (options?: any[]) => {
    if (options) {
      const { max = 9999 } = currentProduct.rule;
      const alreadySelectLength = offers?.length || 0;
      const valueFromInput = deepCopyFunction(
        form.getFieldValue("offers") || []
      ).length;
      const lengthOfValues = alreadySelectLength + valueFromInput;
      if (lengthOfValues < max) {
        return options;
      } else {
        return [];
      }
    }
    return [];
  };

  return (
    <Modal
      visible={isVisible}
      title="Selecionar oferta"
      closable={false}
      maskClosable={false}
      okText="Selecionar"
      okButtonProps={{
        htmlType: "submit",
      }}
      cancelButtonProps={{}}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch((info) => {
            console.log("Validate Failed: ", info);
          });
      }}
      onCancel={() => {
        onReset();
        onCancel && onCancel();
      }}
    >
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Form.Item name="offers" label="Ofertas" rules={[{ required: true }]}>
          <DebounceSelect
            labelInValue
            mode="multiple"
            placeholder="Selecione as ofertas"
            showSearch
            fetchOptions={fetchOfferssList}
            style={{ width: "100%" }}
            labelProp="title"
            valueProp={false}
            filterOptions={filterOptions}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SelectOffer;
