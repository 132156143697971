import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Table,
  TableColumnType,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import api from "../../../../../api";
import RegulationsNew from "./RegulationsNew";
import { Container } from "./styles";

export interface RegulationsProps {
  productId?: string;
  regulations?: any[];
  onRemoved?: (id: string) => void;
  onAdded?: (data: any) => void;
}

const Regulations: React.FC<RegulationsProps> = ({
  productId: productIdStatic,
  regulations,
  onRemoved,
  onAdded,
}) => {
  const { productId: productIdDynamic } = useParams<{ productId: string }>();

  const [productId] = useState(productIdStatic || productIdDynamic);

  const [inserting, setInserting] = useState(false);
  const [data, setData] = useState<any[]>([]);

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        await api.delete(`/product/${productId}/detach/regulation/${id}`);
        onRemoved && onRemoved(id);
        notification.success({
          message: "Regulamento deletado com sucesso",
        });
      } catch (error) {
        notification.error({
          message:
            "Ocorreu algum erro ao deletar o regulamento. Tente novamente., " +
            error,
        });
      }
    },
    [onRemoved, productId]
  );

  const tableCols: TableColumnType<any>[] = [
    {
      key: "id",
      title: "#",
      dataIndex: "id",
      width: "70px",
      sorter: false,
    },
    {
      key: "name",
      title: "Nome",
      dataIndex: "name",
      sorter: false,
    },
    {
      key: "file_link",
      title: "Link",
      dataIndex: "file_link",
      sorter: false,
      render: (link) =>
        link ? (
          <Button type="ghost" href={link} target="_blank" size="small">
            Abrir documento
          </Button>
        ) : (
          "---"
        ),
    },
    {
      key: "actions",
      title: "Ações",
      width: "100px",
      align: "center",
      render: (_, record) => {
        return (
          <Popconfirm
            title="Tem certeza, que deseja deletar o regulamento?!"
            okType="default"
            onConfirm={async () => await handleDelete(record.id)}
            okText="Deletar"
            cancelText="Cancelar"
          >
            <Button
              key="bt-prod-delete"
              size="small"
              color="danger"
              danger
              type="primary"
            >
              Deletar
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      setData(regulations || []);
    }

    return () => {
      didCancel = true;
    };
  }, [regulations]);

  return (
    <Container>
      <RegulationsNew
        isVisible={inserting}
        onCancel={() => {
          setInserting(false);
        }}
        onCreate={(value) => {
          setInserting(false);
          onAdded && onAdded(value);
        }}
      />

      <PageHeader
        title={productIdStatic ? null : "Regulamentos"}
        subTitle=""
        extra={[
          <Button
            key="bt-ds-new"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setInserting(true)}
          >
            Novo
          </Button>,
        ]}
      >
        <Row style={{ marginTop: 12 }}>
          <Col md={24}>
            <Table
              size="middle"
              rowKey={(record: any) => record.id}
              dataSource={data}
              columns={tableCols}
              pagination={false}
            />
          </Col>
        </Row>
      </PageHeader>
    </Container>
  );
};

export default Regulations;
