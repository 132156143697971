import React, { useState } from "react";
import {
  notification,
  Form,
  Modal,
  Input,
} from "antd";

import api from "../../../../api";
import { invalidContainNumbers } from "../../../../utils/validators";
import { transformToUpperCaseFromInput } from "../../../../utils/functions";

const CitiesNew: React.FC<{ isVisible?: boolean; onCreate?: () => void; onCancel?: () => void; }> = ({
  isVisible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await api.post(`/cities`, values);

      notification.success({
        message: "Cidade inserida com sucesso",
      });

      onReset();
      onCreate && onCreate();

      setLoading(false);
    } catch (error) {

      notification.error({
        message:
          "Ocorreu algum erro ao inserir a cidade. Tente novamente., " +
          error,
      });
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={isVisible}
      title="Adicionar cidade"
      closable={false}
      maskClosable={false}

      okText="Adicionar"
      okButtonProps={{
        htmlType: 'submit',
        disabled: loading,
        loading: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch(info => {
            console.log('Validate Failed: ', info);
          });
      }}
      onCancel={() => { onReset(); onCancel && onCancel(); }}
    >
      <Form layout="vertical" form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item name="name" label="Nome" rules={[{ required: true, max: 512, min: 2 }]}>
          <Input placeholder='Digite o nome da cidade' />
        </Form.Item>
        <Form.Item name="uf" label="UF" rules={[{ required: true, max: 2, min: 2 }, { validator: invalidContainNumbers }]}>
          <Input placeholder='Digite o UF da cidade' maxLength={2} minLength={2} onInput={transformToUpperCaseFromInput} />
        </Form.Item>
      </Form>
    </Modal>
  );
}


export default CitiesNew;
