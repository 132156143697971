import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Descriptions,
  notification,
  Popconfirm,
  Space,
} from "antd";

import api from "../../../../../api";

import { ButtonsFlex } from "./styles";
import { useAuth } from "../../../../../providers/Auth";
import { useParams } from "react-router-dom";
import {
  formatCEP,
  formatCpfCnpj,
  formatPhoneNumber,
  getStatusFranchiseeLeadText,
} from "../../../../../utils/functions";
import { ReturnScheduleStatus } from "../../../../../utils/enums";

export interface ReturnScheduleDetailsProps {
  details?: Partial<any>;

  franchiseId?: string;

  onCancel?: () => void;

  onDataLoading?: (isLoading: boolean) => void;
  onTreating?: () => void;
  onTreatingLoading?: (isLoading: boolean) => void;
}

const ReturnScheduleDetails: React.FC<ReturnScheduleDetailsProps> = ({
  details,

  franchiseId: staticFranchiseId,

  onCancel,
  onDataLoading,
  onTreating,
  onTreatingLoading,
}) => {
  const { franchisee_id } = useAuth();

  const { franchiseId: paramRouteFranchiseId } =
    useParams<{ zoneId: string; franchiseId: string }>();

  const [franchiseId] = useState(
    staticFranchiseId || paramRouteFranchiseId || franchisee_id
  );

  const [data, setData] = useState<any>(null);
  const [isTreatingLoading, setIsTreatingLoading] = useState(false);

  const loadInfo = async (id: any) => {
    onDataLoading && onDataLoading(true);
    try {
      const { data } = await api.get(
        `/franchisees/${franchiseId}/return-schedule/lead/${id}/`
      );
      return data;
    } catch (error) {
      throw new Error("Erro ao carregar dados! " + error);
    } finally {
      onDataLoading && onDataLoading(false);
    }
  };

  const handleAccept = async (id: any) => {
    onTreatingLoading && onTreatingLoading(true);
    setIsTreatingLoading(true);
    try {
      await api.patch(
        `/franchisees/${franchiseId}/return-schedule/lead/${id}/`,
        {
          status: ReturnScheduleStatus.TREATED,
        }
      );
      notification.success({
        message: "Status do Retorno atualizado com sucesso",
      });
      onTreating && onTreating();
      setData({
        ...data,
        status: ReturnScheduleStatus.TREATED,
      });
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar o status do Retorno. Tente novamente., " +
          error,
      });
    } finally {
      onTreatingLoading && onTreatingLoading(false);
      setIsTreatingLoading(false);
    }
  };

  const handleReject = async (id: any) => {
    onTreatingLoading && onTreatingLoading(true);
    setIsTreatingLoading(true);
    try {
      await api.patch(
        `/franchisees/${franchiseId}/return-schedule/lead/${id}/`,
        {
          status: ReturnScheduleStatus.REJECTED,
        }
      );
      notification.success({
        message: "Status do Retorno atualizado com sucesso",
      });
      onTreating && onTreating();
      setData({
        ...data,
        status: ReturnScheduleStatus.REJECTED,
      });
    } catch (error) {
      notification.error({
        message:
          "Ocorreu algum erro ao atualizar o status do Retorno. Tente novamente., " +
          error,
      });
    } finally {
      onTreatingLoading && onTreatingLoading(false);
      setIsTreatingLoading(false);
    }
  };

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      loadInfo(details?.id)
        .then((response) => {
          !didCancel && setData(response);
          console.log(response);
        })
        .catch(() =>
          notification.error({ message: "Erro ao carregar dados!" })
        );
    }

    return () => {
      didCancel = true;
    };
  }, []); // No 'details' dep. is needed

  return (
    <Drawer
      width="40%"
      title="Detalhes do Retorno"
      visible={data !== null}
      onClose={() => {
        setData(null);
        onCancel && onCancel();
      }}
      maskClosable={!isTreatingLoading}
      closable={!isTreatingLoading}
    >
      {data !== null && (
        <>
          <Descriptions title="Informações Gerais" column={{ md: 2 }}>
            <Descriptions.Item label="Nome">{data.name}</Descriptions.Item>
            <Descriptions.Item label="CPF/CNPJ">
              {formatCpfCnpj(data.document)}
            </Descriptions.Item>
            <Descriptions.Item label="Telefone">
              {formatPhoneNumber(data.phone_number)}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {getStatusFranchiseeLeadText(data.status)}
            </Descriptions.Item>
            <Descriptions.Item label="Cidade">
              {`${data.city.name} - ${data.city.uf}`}
            </Descriptions.Item>
            <Descriptions.Item label="Cep">
              {formatCEP(data.cep)}
            </Descriptions.Item>
            <Descriptions.Item label="Departamento">
              {data.dept.name}
            </Descriptions.Item>
            <Descriptions.Item label="Mensagem">
              {data.message}
            </Descriptions.Item>
          </Descriptions>

          <ButtonsFlex>
            <Space>
              <Popconfirm
                title="Atualize o status do seu Retorno. O Retorno foi tratado ou rejeitado?"
                okType="default"
                onConfirm={async () => await handleAccept(data.id)}
                onCancel={async () => await handleReject(data.id)}
                okText="Retorno tratado"
                cancelText="Retorno rejeitado"
                disabled={
                  isTreatingLoading ||
                  data.status !== ReturnScheduleStatus.NOT_ANALYZED
                }
              >
                <Button
                  key="bt-update-status"
                  color="primary"
                  type="primary"
                  disabled={
                    isTreatingLoading ||
                    data.status !== ReturnScheduleStatus.NOT_ANALYZED
                  }
                  loading={isTreatingLoading}
                >
                  Finalizar
                </Button>
              </Popconfirm>
            </Space>
          </ButtonsFlex>
        </>
      )}
    </Drawer>
  );
};

export default ReturnScheduleDetails;
