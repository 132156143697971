import styled from "styled-components";

export const Container = styled.div`
  background-color: white;

  .ant-form-item-labe--upload {
    margin-bottom: 10px;
  }

  .upload-list-container .ant-upload-list-picture-card-container{
    width: 150px;
    height: 150px;
  }

  .upload-list-container .ant-upload-list-item-actions{
    transform: translate(calc(-50% - 13px), -50%);
  }
`;

export const ContainerLoading = styled.div`
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: white;
  border-radius: 4px;
`;
