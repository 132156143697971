import {
  ApartmentOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  FileOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MobileOutlined,
  ProfileOutlined,
  PushpinOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  StockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Col, Layout, Menu, Modal, notification, Row } from "antd";
import { useEffect, useState } from "react";
import { Switch, useHistory, useLocation } from "react-router-dom";

import logoImage from "../../../assets/logo.svg";
import ChangeUserPassword from "../../../components/ChangeUserPassword";
import { useAuth } from "../../../providers/Auth";
import { CheckPermArg } from "../../../providers/Auth";
import Router from "../../../routes/route";
import { UserType } from "../../../utils/enums";
import Cities from "../Cities";
import CombosTemplate from "../CombosTemplate";
import CombosTemplateAbout from "../CombosTemplate/About";
import CombosTemplateNew from "../CombosTemplate/CombosTemplateNew";
import Department from "../Department";
import DigitalService from "../DigitalService";
import Franchises from "../Franchises";
import FranchiseAbout from "../Franchises/About";
import Clerk from "../Franchises/Clerk";
import Leads from "../Franchises/Leads";
import FranchiseeReportLeads from "../Franchises/ReportLeads";
// import ReportReturnSchedule from "../Franchises/ReportReturnSchedule";
// import ReturnSchedule from "../Franchises/ReturnSchedule";
import FranchiseZone from "../Franchises/Zone";
import FranchiseZoneAbout from "../Franchises/Zone/About";
import FranchiseZoneAboutCombosAbout from "../Franchises/Zone/About/Combos/About";
import FranchiseZoneAboutCombosNew from "../Franchises/Zone/About/Combos/CombosNew";
import FranchiseZoneAboutOffersAbout from "../Franchises/Zone/About/Offers/About";
import FranchiseZoneAboutOffersNew from "../Franchises/Zone/About/Offers/OffersNew";
import Home from "../Home";
import FranchisorLeads from "../Leads";
import FranchisorLeadsAbout from "../Leads/About";
import Pages from "../Pages";
import PagesAbout from "../Pages/About";
import Products from "../Products";
import ProductsAbout from "../Products/About";
import ProductsOffersAbout from "../Products/About/Offers/About";
import ProductsOffersNew from "../Products/About/Offers/OffersNew";
import Regulations from "../Regulations";
import ReportLeads from "../ReportLeads";
import ReportFranchisee from "../ReportFranchisee";
import Users from "../Users";
import { Container } from "./styles";

const { Header, Content, Sider } = Layout;

const _Layout = () => {
  const { pathname } = useLocation();
  const {
    logout,
    checkPermission,
    hasZones,
    userTypeIs,
    // zones,
    // selectZone,
    // zone_id,
    // name:franchisseeName
  } = useAuth();
  const history = useHistory();
  const [expanded, setExpanded] = useState(true);

  const handleToggleMenu = () => {
    setExpanded(!expanded);
  };

  const buildMenu = (userType: CheckPermArg, children: any) =>
    checkPermission(userType) ? children : null;

  const handleOnClickMenuItem = (e: any) => {
    history.push((e.key || "").startsWith("/") ? e.key : `/${e.key}`);
  };

  const showConfirmLogout = () => {
    Modal.confirm({
      title: "Logout",
      icon: <ExclamationCircleOutlined />,
      content: "Realmente quer fazer logout do sistema?",
      okText: `Sim`,
      onOk() {
        logout();
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    let didCancel = false;

    if (
      !didCancel &&
      userTypeIs([UserType.Clerk, UserType.Franchisee]) &&
      !hasZones()
    ) {
      notification.warning({
        message: "Esta franquia não possue zonas!",
      });
    }

    return () => {
      didCancel = true;
    };
  }, [hasZones, userTypeIs]);

  return (
    <Container>
      <ChangeUserPassword />

      <Container>
        <Sider
          trigger={null}
          collapsible
          collapsed={
            userTypeIs([UserType.Clerk, UserType.Franchisee]) && !hasZones()
              ? true
              : !expanded
          }
          theme="light"
          breakpoint="lg"
          collapsedWidth="0"
          style={{
            overflow: "auto",
            height: "100vh",
          }}
        >
          <div className="logo">
            <img src={logoImage} alt="Agility Telecom" />
          </div>
          <div className="system-name">CMS</div>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[pathname.split("/")[1]]}
            onClick={handleOnClickMenuItem}
          >
            {buildMenu(
              [UserType.Admin, UserType.Clerk, UserType.Franchisee],
              <Menu.Item key="/" icon={<DashboardOutlined />}>
                Dashboard
              </Menu.Item>
            )}

            {buildMenu(
              UserType.Admin,
              <Menu.Item key="franchises" icon={<ShoppingCartOutlined />}>
                Franquias
              </Menu.Item>
            )}

            {buildMenu(
              UserType.Admin,
              <Menu.Item key="products" icon={<ProfileOutlined />}>
                Produtos
              </Menu.Item>
            )}

            {buildMenu(
              UserType.Admin,
              <Menu.Item key="pages" icon={<FileOutlined />}>
                Páginas
              </Menu.Item>
            )}

            {buildMenu(
              UserType.Admin,
              <Menu.Item key="combos-template" icon={<ApartmentOutlined />}>
                Template de combo
              </Menu.Item>
            )}

            {buildMenu(
              UserType.Admin,
              <Menu.Item key="cities" icon={<PushpinOutlined />}>
                Cidades
              </Menu.Item>
            )}
            {buildMenu(
              UserType.Admin,
              <Menu.Item key="departments" icon={<FileOutlined />}>
                Setores
              </Menu.Item>
            )}

            {buildMenu(
              UserType.Admin,
              <Menu.Item key="users" icon={<UserOutlined />}>
                Usuários
              </Menu.Item>
            )}

            {buildMenu(
              UserType.Admin,
              <Menu.Item key="digital-services" icon={<MobileOutlined />}>
                Serviços digitais
              </Menu.Item>
            )}

            {buildMenu(
              UserType.Admin,
              <Menu.Item key="regulations" icon={<FileDoneOutlined />}>
                Regulamentos
              </Menu.Item>
            )}

            {buildMenu(
              UserType.Admin,
              <Menu.Item key="franchisor-lead" icon={<ShoppingOutlined />}>
                Leads
              </Menu.Item>
            )}

            {buildMenu(
              UserType.Franchisee,
              <Menu.Item key="my-franchisee" icon={<ShoppingCartOutlined />}>
                Minha Franquia
              </Menu.Item>
            )}

            {buildMenu(
              [UserType.Franchisee, UserType.Clerk],
              <Menu.Item key="franchisee-lead" icon={<ShoppingOutlined />}>
                Leads
              </Menu.Item>
            )}

            {buildMenu(
              [UserType.Franchisee],
              <Menu.Item key="report-franchisee-lead" icon={<StockOutlined />}>
                Relatório de leads
              </Menu.Item>
            )}

            {/* {buildMenu(
              [UserType.Franchisee, UserType.Clerk],
              <Menu.Item key="return-schedule" icon={<ShoppingOutlined />}>
                Agendamentos de Retorno
              </Menu.Item>
            )}

            {buildMenu(
              UserType.Franchisee,
              <Menu.Item key="report-return-schedule" icon={<StockOutlined />}>
                Relatório dos agendamentos de retorno
              </Menu.Item>
            )} */}

            {buildMenu(
              UserType.Franchisee,
              <Menu.Item key="franchisee-clerks" icon={<UserOutlined />}>
                Atendentes
              </Menu.Item>
            )}

            {buildMenu(
              UserType.Franchisee,
              <Menu.Item key="franchisee-zones" icon={<EnvironmentOutlined />}>
                Zonas
              </Menu.Item>
            )}

            {buildMenu(
              UserType.Admin,
              <Menu.Item key="report-be-franchisee" icon={<StockOutlined />}>
                Relatório de leads
              </Menu.Item>
            )}

            {buildMenu(
              UserType.Admin,
              <Menu.Item key="report-franchisee" icon={<StockOutlined />}>
                Relatório de franquias
              </Menu.Item>
            )}
          </Menu>
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              background: "#fff",
            }}
          >
            <Row style={{ width: "100%" }}>
              <Col
                md={1}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  {buildMenu(
                    [UserType.Clerk, UserType.Franchisee, UserType.Admin],
                    <>
                      {!expanded ? (
                        <MenuUnfoldOutlined
                          className="trigger"
                          onClick={handleToggleMenu}
                        />
                      ) : (
                        <MenuFoldOutlined
                          className="trigger"
                          onClick={handleToggleMenu}
                        />
                      )}
                    </>
                  )}
                </div>
              </Col>
              <Col
                md={21}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div>
                  {/* {buildMenu([UserType.Clerk, UserType.Franchisee], <>
                    <Breadcrumb>
                      <Breadcrumb.Item overlay={(
                        <Menu>
                          {zones?.map(zone => (
                            <Menu.Item key={`${zone.id}-${zone.name}`} onClick={() => selectZone(zone.id)}>
                              {zone.name}
                            </Menu.Item>
                          ))}
                        </Menu>
                      )}>
                        {zones?.find(zone => zone.id === zone_id)?.name}
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </>)} */}
                </div>
              </Col>
              <Col
                md={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <Button onClick={() => showConfirmLogout()}>
                    <LogoutOutlined /> Sair
                  </Button>
                </div>
              </Col>
            </Row>
          </Header>
          <Content
            style={{
              padding: 16,
              minHeight: 280,
              position: "relative",
              overflowY: "auto",
            }}
          >
            <Switch>
              <Router isPrivate path="/" exact component={Home} />

              {/* franchises */}
              <Router
                isPrivate
                userType={UserType.Admin}
                path="/franchise/:franchiseId/zone/:zoneId/combo/new"
                component={FranchiseZoneAboutCombosNew}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/franchise/:franchiseId/zone/:zoneId/combo/:comboId"
                component={FranchiseZoneAboutCombosAbout}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/franchise/:franchiseId/zone/:zoneId/offer/new"
                component={FranchiseZoneAboutOffersNew}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/franchise/:franchiseId/zone/:zoneId/offer/:offerId"
                component={FranchiseZoneAboutOffersAbout}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/franchise/:franchiseId/zone/:zoneId"
                component={FranchiseZoneAbout}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/franchise/:franchiseId"
                component={FranchiseAbout}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/franchises"
                component={Franchises}
              />

              {/* products */}
              <Router
                isPrivate
                userType={UserType.Admin}
                path="/product/:productId/offer/new"
                component={ProductsOffersNew}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/product/:productId/offer/:offerId"
                component={ProductsOffersAbout}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/product/:productId"
                component={ProductsAbout}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/products"
                component={Products}
              />

              {/* pages */}
              <Router
                isPrivate
                userType={UserType.Admin}
                path="/page/:pageId"
                component={PagesAbout}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/pages"
                component={Pages}
              />

              {/* combos-template */}
              <Router
                isPrivate
                userType={UserType.Admin}
                path="/combo-template/:pageId"
                component={CombosTemplateAbout}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/combos-template/new"
                component={CombosTemplateNew}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/combos-template"
                component={CombosTemplate}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/departments"
                component={Department}
              />

              {/* cities */}
              <Router
                isPrivate
                userType={UserType.Admin}
                path="/cities"
                component={Cities}
              />

              {/* users */}
              <Router
                isPrivate
                userType={UserType.Admin}
                path="/users"
                component={Users}
              />

              {/* digital-services */}
              <Router
                isPrivate
                userType={UserType.Admin}
                path="/digital-services"
                component={DigitalService}
              />

              {/* regulations */}
              <Router
                isPrivate
                userType={UserType.Admin}
                path="/regulations"
                component={Regulations}
              />

              {/* franchisor-lead */}
              <Router
                isPrivate
                userType={UserType.Admin}
                path="/franchisor-lead/:franchisorLeadId"
                component={FranchisorLeadsAbout}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/franchisor-lead"
                component={FranchisorLeads}
              />

              {/* my-franchisee */}
              <Router
                isPrivate
                userType={UserType.Franchisee}
                path="/my-franchisee"
                component={FranchiseAbout}
              />

              {/* franchisee-lead */}
              <Router
                isPrivate
                userType={[UserType.Franchisee, UserType.Clerk]}
                path="/franchisee-lead"
                component={Leads}
              />

              {/* report-franchisee-lead */}
              <Router
                isPrivate
                userType={[UserType.Franchisee]}
                path="/report-franchisee-lead"
                component={FranchiseeReportLeads}
              />

              {/* return-schedule */}
              {/* <Router
                isPrivate
                userType={[UserType.Franchisee, UserType.Clerk]}
                path="/return-schedule"
                component={ReturnSchedule}
              /> */}

              {/* report-return-schedule */}
              {/* <Router
                isPrivate
                userType={[UserType.Franchisee]}
                path="/report-return-schedule"
                component={ReportReturnSchedule}
              /> */}

              {/* franchisee-clerks */}
              <Router
                isPrivate
                userType={UserType.Franchisee}
                path="/franchisee-clerks"
                component={Clerk}
              />

              {/* franchisee-zones */}
              <Router
                isPrivate
                userType={UserType.Franchisee}
                path="/franchisee-zone/:zoneId/combo/new"
                component={FranchiseZoneAboutCombosNew}
              />

              <Router
                isPrivate
                userType={UserType.Franchisee}
                path="/franchisee-zone/:zoneId/combo/:comboId"
                component={FranchiseZoneAboutCombosAbout}
              />

              <Router
                isPrivate
                userType={UserType.Franchisee}
                path="/franchisee-zone/:zoneId/offer/new"
                component={FranchiseZoneAboutOffersNew}
              />

              <Router
                isPrivate
                userType={UserType.Franchisee}
                path="/franchisee-zone/:zoneId/offer/:offerId"
                component={FranchiseZoneAboutOffersAbout}
              />

              <Router
                isPrivate
                userType={UserType.Franchisee}
                path="/franchisee-zone/:zoneId"
                component={FranchiseZoneAbout}
              />

              <Router
                isPrivate
                userType={UserType.Franchisee}
                path="/franchisee-zones"
                component={FranchiseZone}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/report-be-franchisee"
                component={ReportLeads}
              />

              <Router
                isPrivate
                userType={UserType.Admin}
                path="/report-franchisee"
                component={ReportFranchisee}
              />
            </Switch>
          </Content>
        </Layout>
      </Container>
    </Container>
  );
};

export default _Layout;
